const clientConstants = {};

clientConstants.IS_IOS = (typeof navigator !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.platform);
clientConstants.IS_IE = (typeof navigator !== 'undefined') && /Trident/.test(navigator.userAgent);
clientConstants.IS_EDGE = (typeof navigator !== 'undefined') && /Edge/.test(navigator.userAgent);
clientConstants.IS_IE_OR_EDGE = clientConstants.IS_IE || clientConstants.IS_EDGE;
clientConstants.IS_FIREFOX = (typeof navigator !== 'undefined') && (/firefox/.test(navigator.userAgent.toLowerCase()));
clientConstants.IS_SAFARI = (typeof navigator !== 'undefined') && (/Safari/.test(navigator.userAgent)) && !(/Chrome/.test(navigator.userAgent));

module.exports = clientConstants;
