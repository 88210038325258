const _ = require('lodash');
const constants = require('wp-constants').spacerFree;

const questionUnits = [
  // "YOUR IDENTITY" QUESTIONS
  {
    id: '1',
    text: 'question1Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.IDENTITY,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.RANK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: [
      ['q1AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q1AnswerText2', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q1AnswerText3', constants.questionUnit.INPUT_PLACEHOLDER]
    ],
    emptyAnswerPlaceholder: 'pickOnePlaceholder',
    response: 'q1ResponseText',
    responseIllustrationName: 'quiz-two-people-table-spacer-by-cbre.svg',
    points: [2.3, 2, 1.7],
    options: {
      1: {
        text: 'q1Option1Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'campus', 'engCreative']
      },
      2: {
        text: 'q1Option2Text',
        profileIds: ['openOffice', 'workplace360', 'campus']
      },
      3: {
        text: 'q1Option3Text',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'openOffice', 'workplace360', 'campus', 'engCreative']
      },
      4: {
        text: 'q1Option4Text',
        profileIds: ['whiteCollar', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      5: {
        text: 'q1Option5Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      6: {
        text: 'q1Option6Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'startup', 'workplace360']
      },
      7: {
        text: 'q1Option7Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'engCreative', 'startup']
      },
      8: {
        text: 'q1Option8Text',
        profileIds: ['workplace360', 'campus', 'startup']
      },
      9: {
        text: 'q1Option9Text',
        profileIds: ['efficient', 'openOffice', 'startup']
      }
    }
  },
  {
    id: '2',
    text: 'question2Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.IDENTITY,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.RANK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: [
      ['q2AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q2AnswerText2', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q2AnswerText3', constants.questionUnit.INPUT_PLACEHOLDER]
    ],
    emptyAnswerPlaceholder: 'pickOnePlaceholder',
    response: 'q2ResponseText',
    responseIllustrationName: 'quiz-two-people-ping-pong-spacer-by-cbre.svg',
    points: [1.3, 1, 0.7],
    options: {
      1: {
        text: 'q2Option1Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      2: {
        text: 'q2Option2Text',
        profileIds: ['efficient', 'whiteGlove', 'openOffice', 'campus', 'engCreative']
      },
      3: {
        text: 'q2Option3Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'engCreative']
      },
      4: {
        text: 'q2Option4Text',
        profileIds: ['efficient', 'openOffice', 'campus', 'startup']
      },
      5: {
        text: 'q2Option5Text',
        profileIds: ['openOffice', 'workplace360', 'startup', 'campus']
      },
      6: {
        text: 'q2Option6Text',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      7: {
        text: 'q2Option7Text',
        profileIds: ['whiteGlove', 'workplace360', 'campus', 'engCreative']
      },
      8: {
        text: 'q2Option8Text',
        profileIds: ['whiteCollar', 'openOffice', 'startup', 'engCreative']
      }
    }
  },
  {
    id: '3',
    text: 'question3Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.IDENTITY,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.RANK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: [
      ['q3AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q3AnswerText2', constants.questionUnit.INPUT_PLACEHOLDER],
      ['q3AnswerText3', constants.questionUnit.INPUT_PLACEHOLDER]
    ],
    emptyAnswerPlaceholder: 'pickOnePlaceholder',
    response: 'q3ResponseText',
    responseIllustrationName: 'quiz-two-people-desk-meeting-spacer-by-cbre.svg',
    points: [1.3, 1, 0.7],
    options: {
      1: {
        text: 'q3Option1Text',
        profileIds: ['efficient', 'whiteCollar', 'openOffice', 'campus', 'startup', 'engCreative']
      },
      2: {
        text: 'q3Option2Text',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'workplace360', 'startup', 'engCreative']
      },
      3: {
        text: 'q3Option3Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'openOffice', 'workplace360', 'campus', 'startup']
      },
      4: {
        text: 'q3Option4Text',
        profileIds: ['efficient', 'whiteGlove', 'workplace360', 'engCreative']
      },
      5: {
        text: 'q3Option5Text',
        profileIds: ['whiteCollar', 'whiteGlove', 'workplace360']
      },
      6: {
        text: 'q3Option6Text',
        profileIds: ['whiteGlove', 'openOffice', 'workplace360', 'campus', 'startup', 'engCreative']
      }
    }
  },


  // "YOUR ORGANIZATION" QUESTIONS
  {
    id: '4',
    text: 'question4Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.ORGANIZATION,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.DROPDOWN,
    answerText: ['q4AnswerText', constants.questionUnit.INPUT_PLACEHOLDER],
    emptyAnswerPlaceholder: 'selectFromListPlaceholder',
    response: 'q4ResponseText',
    responseIllustrationName: 'quiz-people-calendar-growth-spacer-by-cbre.svg',
    points: 1,
    options: {
      1: {
        text: 'q4Option1Text',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'openOffice', 'workplace360', 'campus', 'engCreative']
      },
      2: {
        text: 'q4Option2Text',
        profileIds: ['whiteGlove', 'openOffice', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      3: {
        text: 'q4Option3Text',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'openOffice', 'workplace360', 'campus', 'engCreative']
      },
      4: {
        text: 'q4Option4Text',
        profileIds: ['openOffice']
      },
      5: {
        text: 'q4Option5Text',
        profileIds: ['campus', 'startup', 'engCreative']
      }
    },
    optionOrder: [1, 2, 3, 4, 5]
  },
  {
    id: '5',
    text: 'question5Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.ORGANIZATION,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.DROPDOWN,
    answerText: ['q5AnswerText', constants.questionUnit.INPUT_PLACEHOLDER],
    emptyAnswerPlaceholder: 'selectFromListPlaceholder',
    points: 1,
    options: {
      1: {
        text: 'q5Option1Text',
        response: 'q5Response1Text',
        responseIllustrationName: 'quiz-girl-desk-working-spacer-by-cbre.svg',
        profileIds: ['efficient', 'whiteCollar', 'openOffice', 'engCreative']
      },
      2: {
        text: 'q5Option2Text',
        response: 'q5Response2Text',
        responseIllustrationName: 'quiz-girl-desk-working-late-spacer-by-cbre.svg',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'workplace360', 'campus', 'startup', 'engCreative']
      },
      3: {
        text: 'q5Option3Text',
        response: 'q5Response3Text',
        responseIllustrationName: 'quiz-desk-working-spacer-by-cbre.svg',
        profileIds: ['whiteCollar', 'whiteGlove', 'openOffice', 'workplace360', 'campus']
      },
      4: {
        text: 'q5Option4Text',
        response: 'q5Response4Text',
        responseIllustrationName: 'quiz-girl-desk-working-spacer-by-cbre.svg',
        profileIds: ['whiteGlove', 'workplace360', 'startup', 'engCreative']
      }
    },
    optionOrder: [1, 2, 3, 4]
  },
  {
    id: '6',
    text: 'question6Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.ORGANIZATION,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.DROPDOWN,
    answerText: ['q6AnswerText', constants.questionUnit.INPUT_PLACEHOLDER],
    emptyAnswerPlaceholder: 'selectFromListPlaceholder',
    points: 1,
    options: {
      1: {
        text: 'q6Option1Text',
        response: 'q6Response1Text',
        responseIllustrationName: 'quiz-girl-individual-desk-spacer-by-cbre.svg',
        profileIds: ['efficient', 'whiteCollar', 'whiteGlove', 'openOffice', 'engCreative', 'campus']
      },
      2: {
        text: 'q6Option2Text',
        response: 'q6Response2Text',
        responseIllustrationName: 'quiz-two-people-shared-desk-space-by-cbre.svg',
        profileIds: ['openOffice', 'workplace360', 'campus', 'startup']
      }
    },
    optionOrder: [1, 2]
  },

  // "YOUR OFFICE" QUESTIONS
  {
    id: '7',
    text: 'question7Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SPECTRUM,
    points: 1,
    options: {
      1: {
        text: 'q7Option1Text',
        profileIds: ['whiteGlove', 'efficient']
      },
      2: {
        text: 'q7Option2Text',
        profileIds: ['whiteCollar', 'openOffice']
      },
      3: {
        text: 'q7Option3Text',
        profileIds: []
      },
      4: {
        text: 'q7Option4Text',
        profileIds: ['startup']
      },
      5: {
        text: 'q7Option5Text',
        profileIds: ['workplace360', 'campus', 'engCreative']
      }
    }
  },
  {
    id: '8',
    text: 'question8Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SPECTRUM,
    points: 1,
    options: {
      1: {
        text: 'q8Option1Text',
        profileIds: ['whiteGlove']
      },
      2: {
        text: 'q8Option2Text',
        profileIds: ['whiteCollar', 'workplace360']
      },
      3: {
        text: 'q8Option3Text',
        profileIds: ['efficient', 'openOffice']
      },
      4: {
        text: 'q8Option4Text',
        profileIds: ['engCreative']
      },
      5: {
        text: 'q8Option5Text',
        profileIds: ['campus', 'startup']
      }
    }
  },
  {
    id: '9',
    text: 'question9Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SPECTRUM,
    points: 1,
    options: {
      1: {
        text: 'q9Option1Text',
        profileIds: ['campus', 'startup']
      },
      2: {
        text: 'q9Option2Text',
        profileIds: ['efficient']
      },
      3: {
        text: 'q9Option3Text',
        profileIds: ['whiteGlove']
      },
      4: {
        text: 'q9Option4Text',
        profileIds: ['whiteCollar', 'startup']
      },
      5: {
        text: 'q9Option5Text',
        profileIds: ['openOffice', 'workplace360']
      }
    }
  },
  {
    id: '10',
    text: 'question10Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SPECTRUM,
    points: 1,
    options: {
      1: {
        text: 'q10Option1Text',
        profileIds: []
      },
      2: {
        text: 'q10Option2Text',
        profileIds: ['whiteGlove']
      },
      3: {
        text: 'q10Option3Text',
        profileIds: ['efficient', 'whiteCollar']
      },
      4: {
        text: 'q10Option4Text',
        profileIds: ['workplace360']
      },
      5: {
        text: 'q10Option5Text',
        profileIds: ['openOffice', 'campus', 'engCreative', 'startup']
      }
    }
  },
  {
    id: '11',
    text: 'question11Text',
    type: constants.questionUnit.QUESTION_TYPE.PROFILE,
    category: constants.questionUnit.QUESTION_CATEGORY.OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SPECTRUM,
    points: 1,
    options: {
      1: {
        value: 1,
        text: 'q11Option1Text',
        profileIds: ['efficient', 'openOffice']
      },
      2: {
        text: 'q11Option2Text',
        profileIds: ['whiteCollar']
      },
      3: {
        text: 'q11Option3Text',
        profileIds: []
      },
      4: {
        text: 'q11Option4Text',
        profileIds: ['workplace360', 'startup', 'engCreative']
      },
      5: {
        text: 'q11Option5Text',
        profileIds: ['whiteGlove', 'campus']
      }
    }
  },
  // "YOUR DETAILS" QUESTIONS
  {
    id: '12',
    text: 'question12Text',
    type: constants.questionUnit.QUESTION_TYPE.USER_DATA,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.DROPDOWN,
    answerText: ['q12AnswerText', constants.questionUnit.INPUT_PLACEHOLDER],
    userDataProperty: 'role',
    options: {
      lead: {
        text: 'q12OptionLeadText',
        response: 'q12ResponseLeadText',
        responseIllustrationName: 'quiz-people-conversing-spacer-by-cbre.svg'
      },
      cbreClient: {
        text: 'q12OptionClientText',
        response: 'q12ResponseClientText',
        responseIllustrationName: 'quiz-people-conversing-spacer-by-cbre.svg'
      },
      cbreProfessional: {
        text: 'q12OptionProfessionalText',
        response: 'q12ResponseProfessionalText',
        responseIllustrationName: 'quiz-people-conversing-spacer-by-cbre.svg'
      }
    },
    optionOrder: ['cbreProfessional', 'cbreClient', 'lead']
  },
  {
    id: '13',
    type: constants.questionUnit.QUESTION_TYPE.ASSUMPTION,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    assumptionType: constants.questionUnit.ASSUMPTION_TYPE.HEADCOUNT,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: ['q13AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q13AnswerText2'],
    answerHelperText: 'q13AnswerHelperText'
  },
  {
    id: '14',
    type: constants.questionUnit.QUESTION_TYPE.ASSUMPTION,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    assumptionType: constants.questionUnit.ASSUMPTION_TYPE.COLLABORATION,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: ['q14AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q14AnswerText2']
  },
  {
    id: '15',
    type: constants.questionUnit.QUESTION_TYPE.ASSUMPTION,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    assumptionType: constants.questionUnit.ASSUMPTION_TYPE.DAYS_IN_OFFICE,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: ['q15AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q15AnswerText2']
  },
  {
    id: '16',
    type: constants.questionUnit.QUESTION_TYPE.USER_DATA,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.LOCATION_AUTOCOMPLETE,
    answerText: ['q16AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q16AnswerText2'],
    userDataProperty: 'officeLocation'
  },
  {
    id: '17',
    type: constants.questionUnit.QUESTION_TYPE.USER_DATA,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.INDUSTRY_AUTOCOMPLETE,
    answerText: ['q17AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q17AnswerText2'],
    userDataProperty: 'industryCode'
  },
  {
    id: '18',
    type: constants.questionUnit.QUESTION_TYPE.USER_DATA,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerInputType: constants.questionUnit.ANSWER_INPUT_TYPE.LOCATION_AUTOCOMPLETE,
    answerText: ['q18AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q18AnswerText2'],
    userDataProperty: 'currentPersonalLocation'
  },
  {
    id: '19',
    type: constants.questionUnit.QUESTION_TYPE.ASSUMPTION,
    category: constants.questionUnit.QUESTION_CATEGORY.DETAILS,
    assumptionType: constants.questionUnit.ASSUMPTION_TYPE.DENSITY,
    answerType: constants.questionUnit.PROFILE_ANSWER_TYPE.PICK,
    answerFormat: constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION,
    answerText: ['q19AnswerText1', constants.questionUnit.INPUT_PLACEHOLDER, 'q19AnswerText2']
  }

];

const questionUnitsById = _.keyBy(questionUnits, 'id');

module.exports = {
  getQuestionUnitById(id) {
    return questionUnitsById[id];
  }
};