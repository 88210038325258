const constants       = require('wp-constants').spacerFree;
const sharedConstants = require('wp-constants').shared;
const _               = require('lodash');

function isUSA(country) {
  // the majority of US locations will have a country of "USA"
  // however, there are edge cases like "Arlington, VA, USA (Arlington County)" which we parse as having a country of "USA (Arlington County)"
  return country.indexOf('USA') === 0;
}

function isCanada(country) {
  return country === 'CAN';
}

function isUK(country) {
  return country === 'GBR';
}

function isGermany(country) {
  return country === 'DEU';
}

function isSpain(country) {
  return country === 'ESP';
}

function isLondon(location) {
  return location === 'London, England, GBR';
}

function isJapan(country) {
  return country === 'JPN';
}

function isFrance(country) {
  return country === 'FRA';
}

function isSlovakia(country) {
  return country === 'SVK';
}

function isPoland(country) {
  return country === 'POL';
}

function isHungary(country) {
  return country === 'HUN';
}
function isNetherland(country) {
  return country === 'NLD';
}
function isColombia(country) {
  return country === 'COL';
}


function isCalifornia(state, country) {
  return state === 'CA' && isUSA(country);
}

function isEuropeanEconomicArea(country) {
  // https://www.gov.uk/eu-eea
  return ['AUT', 'BEL', 'BGR', 'CYP', 'CZE', 'DEU', 'DNK', 'EST', 'ESP', 'FIN', 'FRA', 'GBR', 'GRC', 'HRV', 'HUN', 'NLD', 'IRL', 'ISL', 'ITA', 'LIE', 'LVA', 'LTU', 'LUX', 'MLT', 'NOR', 'NLD', 'POL', 'PRT', 'ROU', 'SVK', 'SVN', 'SWE'].indexOf(country) >= 0;
}

function isAfrica(country) {
  return ['DZA', 'AGO', 'BEN', 'BWA', 'BFA', 'BDI', 'CMR', 'CPV', 'CAF', 'COM', 'COD', 'DJI', 'EGY', 'GNQ', 'ERI', 'ETH', 'GAB', 'GMB', 'GHA', 'GIN', 'GNB', 'CIV', 'KEN', 'LSO', 'LBR', 'LBY', 'MDG', 'MWI', 'MRT', 'MUS', 'MAR', 'MOZ', 'NAM', 'NER', 'NGA', 'COG', 'REU', 'RWA', 'SHN', 'STP', 'SEN', 'SYC', 'SLE', 'SOM', 'ZAF', 'SSD', 'SDN', 'SWZ', 'TZA', 'TGO', 'TUN', 'UGA', 'ESH', 'ZMB', 'ZWE'].indexOf(country) >= 0;
}

function isMiddleEast(country) {
  return ['ARM', 'AZE', 'BHR', 'GEO', 'IRN', 'IRQ', 'ISR', 'JOR', 'KWT', 'LBN', 'NTZ', 'OMN', 'QAT', 'SAU', 'SYR', 'TUR', 'TKM', 'ARE', 'YEM'].indexOf(country) >= 0;
}

function isEMEA(country) {
  return isEuropeanEconomicArea(country) || isAfrica(country) || isMiddleEast(country);
}

function isAPAC(country) {
  // https://www.imf.org/external/oap/about.htm#asiaregion
  return ['AUS', 'BGD', 'BTN', 'BRN', 'KHM', 'CHN', 'HKG', 'MAC', 'FJI', 'IND', 'IDN', 'JPN', 'KIR', 'KOR', 'LAO', 'MYS', 'MDV', 'MHL', 'FSM', 'MNG', 'MMR', 'NPL', 'NZL', 'PLW', 'PNG', 'PHL', 'WSM', 'SGP', 'SLB', 'LKA', 'THA', 'TLS', 'TON', 'TUV', 'VUT', 'VNM'].indexOf(country) >= 0;
}

function getCityStateCountry(location) {
  let locationParts = location.split(',');
  locationParts = locationParts.map(loc => _.trim(loc));
  const country = locationParts[locationParts.length - 1];
  const [city, state] = locationParts;

  // Example bad format: Butt Butt, Butt Butt County, BT, USA (for USA we expect CITY, STATE, COUNTRY)
  const nonStandardUSAFormat = isUSA(country) && locationParts.length !== 3;

  return { city, state, country, nonStandardUSAFormat };
}

function getMeasurementSystemForLocation(location) {
  const { country } = getCityStateCountry(location);

  if (isUSA(country) || isCanada(country) || isLondon(location)) return sharedConstants.calculator.MEASUREMENT_SYSTEM.IMPERIAL;
  if (isJapan(country)) return sharedConstants.calculator.MEASUREMENT_SYSTEM.TSUBO;

  return sharedConstants.calculator.MEASUREMENT_SYSTEM.METRIC;
}

function getSFTypeForLocation(location) {
  const { country } = getCityStateCountry(location);

  if (isUK(country) || isAPAC(country)) return constants.calculator.SF_TYPE.NIA;
  return constants.calculator.SF_TYPE.RSF;
}

// to restrict the plans public links to 2D version  , Add countries here in future to enable for other countries
function is2DExampleLinkLocation(location) {
  const { country } = getCityStateCountry(location);

  return isNetherland(country);
}

module.exports = {
  // countries
  isUSA,
  isUK,
  isJapan,
  isGermany,
  isSpain,
  isFrance,
  isSlovakia,
  isPoland,
  isHungary,
  isNetherland,
  isColombia,
  
  // US states
  isCalifornia,

  // regions
  isEuropeanEconomicArea,
  isAfrica,
  isMiddleEast,
  isEMEA,
  isAPAC,

  // other util functions
  getCityStateCountry,
  getMeasurementSystemForLocation,
  getSFTypeForLocation,
  is2DExampleLinkLocation
};
