const spacerFreeRedirectPaths = [
  { from: '/support', to: '/' },
  { from: '/pricing', to: '/' },
  { from: '/squareFootage', to: '/' },
  { from: '/quiz', to: '/quiz-spacer-by-cbre' },
  { from: '/results', to: '/program-your-results-spacer-by-cbre' },
  { from: '/about', to: '/about-spacer-by-cbre' },
  { from: '/topProfiles', to: '/profile-comparison-spacer-by-cbre' },
  { from: '/termsOfService', to: '/terms-spacer-by-cbre' },
  { from: '/home-spacer-by-cbre', to: '/' },
  { from: '/insights-spacer-by-cbre/what-is-rentable-vs-usable-square-feet', to: '/insights-spacer-by-cbre/difference-between-usable-rentable-square-feet' }
];

module.exports = {
  spacerFreeRedirectPaths
};
