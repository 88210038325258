const _ = require('lodash');

const sharedNames = {
  SOCIAL: 'social',
  EFFICIENT: 'efficient',
  FLEXIBLE: 'flexible',
  ENABLED: 'enabled',
  PRODUCTIVE: 'productive',
  INCLUSIVE: 'inclusive',
  CLIENT_FOCUSED: 'clientFocused',
  STANDARDIZED: 'standardized',
};

function getIconObject(iconName) {
  return {
    light: `/svg/icons/${iconName}-white-icon-spacer-by-cbre.svg`,
    dark: `/svg/icons/${iconName}-forest-green-icon-spacer-by-cbre.svg`
  };
}

const sharedIcons = {
  SOCIAL: getIconObject('thumbs-up'),
  EFFICIENT: getIconObject('recycle'),
  FLEXIBLE: getIconObject('floorplan'),
  ENABLED: getIconObject('supplies'),
  PRODUCTIVE: getIconObject('presentation-chart'),
  INCLUSIVE: getIconObject('chain-link'),
  CLIENT_FOCUSED: getIconObject('plus-person'),
  STANDARDIZED: getIconObject('puzzle-piece')
};

const profileTraits = [
  // SOCIAL
  {
    id: 'social1',
    name: sharedNames.SOCIAL,
    icon: sharedIcons.SOCIAL,
    description: 'social1Desc'
  },
  {
    id: 'social2',
    name: sharedNames.SOCIAL,
    icon: sharedIcons.SOCIAL,
    description: 'social2Desc'
  },
  {
    id: 'social3',
    name: sharedNames.SOCIAL,
    icon: sharedIcons.SOCIAL,
    description: 'social3Desc'
  },
  {
    id: 'social4',
    name: sharedNames.SOCIAL,
    icon: sharedIcons.SOCIAL,
    description: 'social4Desc'
  },

  // EFFICIENT
  {
    id: 'efficient1',
    name: sharedNames.EFFICIENT,
    icon: sharedIcons.EFFICIENT,
    description: 'efficient1Desc'
  },
  {
    id: 'efficient2',
    name: sharedNames.EFFICIENT,
    icon: sharedIcons.EFFICIENT,
    description: 'efficient2Desc'
  },

  // FLEXIBLE
  {
    id: 'flexible1',
    name: sharedNames.FLEXIBLE,
    icon: sharedIcons.FLEXIBLE,
    description: 'flexible1Desc'
  },
  {
    id: 'flexible2',
    name: sharedNames.FLEXIBLE,
    icon: sharedIcons.FLEXIBLE,
    description: 'flexible2Desc'
  },
  {
    id: 'flexible3',
    name: sharedNames.FLEXIBLE,
    icon: sharedIcons.FLEXIBLE,
    description: 'flexible3Desc'
  },

  // ENABLED
  {
    id: 'enabled1',
    name: sharedNames.ENABLED,
    icon: sharedIcons.ENABLED,
    description: 'enabled1Desc'
  },
  {
    id: 'enabled2',
    name: sharedNames.ENABLED,
    icon: sharedIcons.ENABLED,
    description: 'enabled2Desc'
  },
  {
    id: 'enabled3',
    name: sharedNames.ENABLED,
    icon: sharedIcons.ENABLED,
    description: 'enabled3Desc'
  },
  {
    id: 'enabled4',
    name: sharedNames.ENABLED,
    icon: sharedIcons.ENABLED,
    description: 'enabled4Desc'
  },
  {
    id: 'enabled5',
    name: sharedNames.ENABLED,
    icon: sharedIcons.ENABLED,
    description: 'enabled5Desc'
  },

  // PRODUCTIVE
  {
    id: 'productive1',
    name: sharedNames.PRODUCTIVE,
    icon: sharedIcons.PRODUCTIVE,
    description: 'productive1Desc'
  },
  {
    id: 'productive2',
    name: sharedNames.PRODUCTIVE,
    icon: sharedIcons.PRODUCTIVE,
    description: 'productive2Desc'
  },
  {
    id: 'productive3',
    name: sharedNames.PRODUCTIVE,
    icon: sharedIcons.PRODUCTIVE,
    description: 'productive3Desc'
  },

  // INCLUSIVE
  {
    id: 'inclusive1',
    name: sharedNames.INCLUSIVE,
    icon: sharedIcons.INCLUSIVE,
    description: 'inclusive1Desc'
  },
  {
    id: 'inclusive2',
    name: sharedNames.INCLUSIVE,
    icon: sharedIcons.INCLUSIVE,
    description: 'inclusive2Desc'
  },
  {
    id: 'inclusive3',
    name: sharedNames.INCLUSIVE,
    icon: sharedIcons.INCLUSIVE,
    description: 'inclusive3Desc'
  },

  // CLIENT-FOCUSED
  {
    id: 'clientFocused1',
    name: sharedNames.CLIENT_FOCUSED,
    icon: sharedIcons.CLIENT_FOCUSED,
    description: 'clientFocused1Desc'
  },
  {
    id: 'clientFocused2',
    name: sharedNames.CLIENT_FOCUSED,
    icon: sharedIcons.CLIENT_FOCUSED,
    description: 'clientFocused2Desc'
  },
  {
    id: 'clientFocused3',
    name: sharedNames.CLIENT_FOCUSED,
    icon: sharedIcons.CLIENT_FOCUSED,
    description: 'clientFocused3Desc'
  },

  // STANDARDIZED
  {
    id: 'standardized1',
    name: sharedNames.STANDARDIZED,
    icon: sharedIcons.STANDARDIZED,
    description: 'standardized1Desc'
  },
  {
    id: 'standardized2',
    name: sharedNames.STANDARDIZED,
    icon: sharedIcons.STANDARDIZED,
    description: 'standardized2Desc'
  },
  {
    id: 'standardized3',
    name: sharedNames.STANDARDIZED,
    icon: sharedIcons.STANDARDIZED,
    description: 'standardized3Desc'
  },
  {
    id: 'standardized4',
    name: sharedNames.STANDARDIZED,
    icon: sharedIcons.STANDARDIZED,
    description: 'standardized4Desc'
  }
];

const profileTraitsById = _.keyBy(profileTraits, 'id');

module.exports = {
  getProfileTraitById(id) {
    return profileTraitsById[id];
  }
};
