const React = require('react');

function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="loader" />
    </div>
  );
}

module.exports = LoadingPage;
