import ReactGA from "react-ga4";

export const GAInitialize = () => {
  if (window.SERVER_DATA.featureFlags.isGoogleAnalyticsEnabled == "true") {
    ReactGA.initialize([
      {
        trackingId: window.SERVER_DATA.featureFlags.googleId,
      }
    ]);
    ReactGA.ga("set", "anonymizeIp", true);
  }
};

export const GATrackRoute = (path, title) => {
  if (window.SERVER_DATA.featureFlags.isGoogleAnalyticsEnabled == "true") {
    ReactGA.send({
      hitType: "pageview",
      page: path,
      title: title
    });
  }
};

export const GAEvent = (category, action, label, value = 0) => {
  if (window.SERVER_DATA.featureFlags.isGoogleAnalyticsEnabled == "true") {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value,
    });
  }
};
