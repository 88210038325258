const _                   = require('lodash');
const PROFILE_TYPE        = require('wp-constants').spacerFree.calculator.PROFILE_TYPE;
const FLOORPLAN_TYPE      = require('wp-constants').spacerFree.profileInfo.FLOORPLAN_TYPE;
const profileNames        = require('./profileNames.js');
const kitOfPartsData      = require('./kitOfParts.js');
const collaborationLevels = require('./collaborationLevels.js');

// *************************************

const profileNameTokens = profileNames.profileNameTokens;
const profileNameValues = profileNames.profileNameValues;

const profiles = [
  {
    // 1
    id: 'efficient',
    name: 'Efficient',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-optimizer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-optimizer-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-optimizer-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.EFFICIENT,
    displayNameToken: profileNameTokens.EFFICIENT,
    descriptionToken: 'efficientDesc',
    keyTraitIds: ['efficient1', 'productive1', 'standardized1', 'enabled1'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.EFFICIENT.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.EFFICIENT.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.EFFICIENT.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.EFFICIENT.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.EFFICIENT.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.EFFICIENT.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.EFFICIENT.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.EFFICIENT.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.EFFICIENT.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.EFFICIENT.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.EFFICIENT.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.EFFICIENT.COLOMBIA.REGULAR
    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.EFFICIENT.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.EFFICIENT.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.EFFICIENT.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.EFFICIENT.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.EFFICIENT.EUROPE
    }
  },
  {
    // 2
    id: 'whiteCollar',
    name: 'White Collar',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-accommodator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-accommodator-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-accommodator-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.WHITE_COLLAR,
    displayNameToken: profileNameTokens.WHITE_COLLAR,
    descriptionToken: 'whiteCollarDesc',
    keyTraitIds: ['productive1', 'efficient2', 'standardized1', 'enabled1'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.WHITE_COLLAR.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.WHITE_COLLAR.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.WHITE_COLLAR.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.WHITE_COLLAR.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.WHITE_COLLAR.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.WHITE_COLLAR.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.WHITE_COLLAR.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.WHITE_COLLAR.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.WHITE_COLLAR.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.WHITE_COLLAR.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.WHITE_COLLAR.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.WHITE_COLLAR.COLOMBIA.REGULAR

    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.WHITE_COLLAR.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.WHITE_COLLAR.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.WHITE_COLLAR.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.WHITE_COLLAR.EUROPE
    }
  },
  {
    // 3
    id: 'whiteGlove',
    name: 'White Glove',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-enhancer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-enhancer-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-enhancer-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.WHITE_GLOVE,
    displayNameToken: profileNameTokens.WHITE_GLOVE,
    descriptionToken: 'whiteGloveDesc',
    keyTraitIds: ['clientFocused1', 'standardized2', 'enabled2', 'social1'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.WHITE_GLOVE.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.WHITE_GLOVE.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.WHITE_GLOVE.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.WHITE_GLOVE.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.WHITE_GLOVE.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.WHITE_GLOVE.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.WHITE_GLOVE.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.WHITE_GLOVE.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.WHITE_GLOVE.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.WHITE_GLOVE.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.WHITE_GLOVE.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.WHITE_GLOVE.COLOMBIA.REGULAR
    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.WHITE_GLOVE.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.WHITE_GLOVE.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.WHITE_GLOVE.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.WHITE_GLOVE.EUROPE
    }
  },
  {
    // 4
    id: 'openOffice',
    name: 'Open Office',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-producer-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-producer-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-producer-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.OPEN_OFFICE,
    displayNameToken: profileNameTokens.OPEN_OFFICE,
    descriptionToken: 'openOfficeDesc',
    keyTraitIds: ['productive2', 'standardized3', 'enabled3', 'inclusive1'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.OPEN_OFFICE.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.OPEN_OFFICE.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.OPEN_OFFICE.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.OPEN_OFFICE.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.OPEN_OFFICE.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.OPEN_OFFICE.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.OPEN_OFFICE.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.OPEN_OFFICE.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.OPEN_OFFICE.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.OPEN_OFFICE.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.OPEN_OFFICE.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.WHITE_GLOVE.COLOMBIA.REGULAR
    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.OPEN_OFFICE.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.OPEN_OFFICE.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.OPEN_OFFICE.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.OPEN_OFFICE.EUROPE

    }
  },
  {
    // 5
    id: 'workplace360',
    name: 'Workplace 360',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-connector-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-connector-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-connector-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.WORKPLACE_360,
    displayNameToken: profileNameTokens.WORKPLACE_360,
    descriptionToken: 'workplace360Desc',
    keyTraitIds: ['productive1', 'inclusive1', 'flexible1', 'clientFocused2'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.WORKPLACE_360.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.WORKPLACE_360.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.WORKPLACE_360.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.WORKPLACE_360.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.WORKPLACE_360.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.WORKPLACE_360.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.WORKPLACE_360.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.WORKPLACE_360.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.WORKPLACE_360.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.WORKPLACE_360.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.WORKPLACE_360.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.WORKPLACE_360.COLOMBIA.REGULAR
    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.WORKPLACE_360.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.WORKPLACE_360.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.WORKPLACE_360.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.WORKPLACE_360.EUROPE
    }
  },
  {
    // 6
    id: 'campus',
    name: 'Campus',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-differentiator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-differentiator-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-differentiator-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.CAMPUS,
    displayNameToken: profileNameTokens.CAMPUS,
    descriptionToken: 'campusDesc',
    keyTraitIds: ['inclusive2', 'social2', 'clientFocused3', 'flexible2'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.CAMPUS.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.CAMPUS.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.CAMPUS.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.CAMPUS.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.CAMPUS.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.CAMPUS.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.CAMPUS.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.CAMPUS.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.CAMPUS.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.CAMPUS.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.CAMPUS.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.CAMPUS.COLOMBIA.REGULAR

    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.CAMPUS.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.CAMPUS.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.CAMPUS.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.CAMPUS.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.CAMPUS.EUROPE
    }
    },
  
  {
    // 7
    id: 'startup',
    name: 'Startup',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-incubator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-incubator-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-incubator-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.STARTUP,
    displayNameToken: profileNameTokens.STARTUP,
    descriptionToken: 'startupDesc',
    keyTraitIds: ['social3', 'inclusive3', 'flexible3', 'enabled4'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.STARTUP.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.STARTUP.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.STARTUP.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.STARTUP.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.STARTUP.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.STARTUP.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.STARTUP.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.STARTUP.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.STARTUP.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.STARTUP.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.STARTUP.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.STARTUP.COLOMBIA.REGULAR

    },
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.STARTUP.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.STARTUP.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.STARTUP.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.STARTUP.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.STARTUP.EUROPE
    }
  },
  {
    // 8
    id: 'engCreative',
    name: 'Engineering/Creative',
    examplePreviewImageNames: {
      [FLOORPLAN_TYPE.PARTIAL_5K]: '/png/example-previews/5000-partial-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_5K]: '/png/example-previews/5000-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_10K]: '/png/example-previews/10000-partial-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_10K]: '/png/example-previews/10000-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_15K]: '/png/example-previews/15000-partial-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_15K]: '/png/example-previews/15000-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.PARTIAL_20K]: '/png/example-previews/20000-partial-floor-plan-creator-profile-spacer-by-cbre.png',
      [FLOORPLAN_TYPE.FULL_20K]: '/png/example-previews/20000-floor-plan-creator-profile-spacer-by-cbre.png'
    },
    profileHeroImage: '/svg/illustrations/results/profiles/3D-creator-office-layout-spacer-by-cbre.svg',
    displayName: profileNameValues.ENG_CREATIVE,
    displayNameToken: profileNameTokens.ENG_CREATIVE,
    descriptionToken: 'engCreativeDesc',
    keyTraitIds: ['enabled5', 'standardized4', 'productive3', 'social4'],
    kitOfPartsWithMultipliers: {
      [PROFILE_TYPE.US_REGULAR]: kitOfPartsData.ENG_CREATIVE.US.REGULAR,
      [PROFILE_TYPE.US_SMALL]: kitOfPartsData.ENG_CREATIVE.US.SMALL,
      [PROFILE_TYPE.UK_REGULAR]: kitOfPartsData.ENG_CREATIVE.UK.REGULAR,
      [PROFILE_TYPE.GERMANY_REGULAR]: kitOfPartsData.ENG_CREATIVE.GERMANY.REGULAR,
      [PROFILE_TYPE.SPAIN_REGULAR]: kitOfPartsData.ENG_CREATIVE.SPAIN.REGULAR,
      [PROFILE_TYPE.FRANCE_REGULAR]: kitOfPartsData.ENG_CREATIVE.FRANCE.REGULAR,
      [PROFILE_TYPE.APAC_REGULAR]: kitOfPartsData.ENG_CREATIVE.APAC.REGULAR,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: kitOfPartsData.ENG_CREATIVE.SLOVAKIA.REGULAR,
      [PROFILE_TYPE.POLAND_REGULAR]: kitOfPartsData.ENG_CREATIVE.POLAND.REGULAR,
      [PROFILE_TYPE.HUNGARY_REGULAR]: kitOfPartsData.ENG_CREATIVE.HUNGARY.REGULAR,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: kitOfPartsData.ENG_CREATIVE.NETHERLAND.REGULAR,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: kitOfPartsData.ENG_CREATIVE.COLOMBIA.REGULAR

    },
     
    collaborationLevels: {
      [PROFILE_TYPE.US_REGULAR]: collaborationLevels.ENG_CREATIVE.US,
      [PROFILE_TYPE.US_SMALL]: collaborationLevels.ENG_CREATIVE.US,
      [PROFILE_TYPE.UK_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.GERMANY_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.SPAIN_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.FRANCE_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.APAC_REGULAR]: collaborationLevels.ENG_CREATIVE.APAC,
      [PROFILE_TYPE.SLOVAKIA_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.POLAND_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.HUNGARY_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.NETHERLAND_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE,
      [PROFILE_TYPE.COLOMBIA_REGULAR]: collaborationLevels.ENG_CREATIVE.EUROPE
    }
 }  

];

module.exports = _.keyBy(profiles, 'id');

