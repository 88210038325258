const quizOrder = ['1', '2', '3', '4', '5', '9', '6', '7', '8', '10', '11', '12', '13', '14', '19', '15', '16', '18', '17'];
const groupedQuestions = ['13', '14', '19', '15', '16', '18', '17']; // if we ever get more than one chunk of grouped questions, deal with that then

const quizProgressAtQuestion = [0, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 92, 95]; // total quiz progress in percentage shown at each question


module.exports = {
  quizOrder,
  groupedQuestions,
  quizProgressAtQuestion
};

