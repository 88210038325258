const _ = require('lodash');
const {
  CATEGORY,
  UNIT_LABEL,
  CIRCULATION_TYPE
}       = require('wp-constants').shared.spaceUnit;

// custom amenities can be added multiple times to the program (e.g. there can be two "Auditorium" instances on the program).
// therefore they are added with custom ids, and matched to their unit by name (e.g. the string "auditorium")

// from here: https://docs.google.com/spreadsheets/d/1SYkapQcA0-FbiezVzTQCk4iTToY333A0p_Sy71YyE_U
const suggestedCustomAmenities = [
  {
    dataKey: '1',
    displayName: 'audioVisualCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.5,
    minSF: 150
  },
  {
    dataKey: '2',
    displayName: 'dataCenterCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 3,
    minSF: 1000
  },
  {
    dataKey: '3',
    displayName: 'buildingSupportCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 2.1
  },
  {
    dataKey: '4',
    displayName: 'foodServiceCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 10.6
  },
  {
    dataKey: '5',
    displayName: 'kitchenCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 2.58
  },
  {
    dataKey: '6',
    displayName: 'serveryCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 3.19
  },
  {
    dataKey: '7',
    displayName: 'execDiningCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.6
  },
  {
    dataKey: '8',
    displayName: 'grabNGoCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.78
  },
  {
    dataKey: '9',
    displayName: 'juiceCoffeeBarCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 1
  },
  {
    dataKey: '10',
    displayName: 'childCareCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 5
  },
  {
    dataKey: '11',
    displayName: 'fitnessCenterCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 6.25
  },
  {
    dataKey: '12',
    displayName: 'healthCenterCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.8
  },
  {
    dataKey: '13',
    displayName: 'mailCenterCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 2.5,
    minSF: 200
  },
  {
    dataKey: '14',
    displayName: 'commercialPrintCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.8,
    minSF: 400
  },
  {
    dataKey: '15',
    displayName: 'centralCafeCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 4.5,
    minSF: 1200
  },
  {
    dataKey: '16',
    displayName: 'securityCtrlCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.8
  },
  {
    dataKey: '17',
    displayName: 'auditoriumCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 4.7,
    minSF: 1500,
    maxSF: 8000
  },
  {
    dataKey: '18',
    displayName: 'dryCleaningLaundryCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 0.8
  },
  {
    dataKey: '19',
    displayName: 'confCenterCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.AMENITY,
    multiplier: 6,
    minSF: 1500,
    maxSF: 10000
  },
  {
    dataKey: '20',
    displayName: 'multiPurpTrainingCustom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    sf: 1000
  },
  {
    dataKey: '21',
    displayName: 'informalCollabCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.OPEN,
    sf: 120
  },
  {
    dataKey: '22',
    displayName: 'touchdownTeamCustom',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.CUSTOM_AMENITY,
    circulationType: CIRCULATION_TYPE.OPEN,
    sf: 120
  }
];

module.exports = _.keyBy(suggestedCustomAmenities, unit => unit.displayName.toLowerCase());
