const _ = require('lodash');
const {
  CATEGORY,
  UNIT_LABEL,
  CIRCULATION_TYPE
} = require('wp-constants').shared.spaceUnit;

// spaceUnits 1-37: used for US_SMALL and US_REGULAR profiles
// spaceUnits 38-68: used for UK_REGULAR profiles
// spaceUnits 70-100: used for GERMANY_REGULAR profiles
// spaceUnits 101-147: used for APAC_REGULAR profiles
// spaceUnits 148-175: used for SPAIN_REGULAR profiles
// spaceUnits 176-205: used for FRANCE_REGULAR profiles
// spaceUnits 206-237: used for SLOVAKIA_REGULAR profiles
// spaceUnits 238-269: used for POLAND_REGULAR profiles
// spaceUnits 270-301: used for HUNGARY_REGULAR profiles
// spaceUnits 302-333: used for COLOMBIA_REGULAR profiles
// spaceUnits 334-363: used for NETHERLAND_REGULAR profiles

const spaceUnits = [
  // US_SMALL & US_REGULAR space units
  {
    id: '1',
    displayName: 'Office',
    displayNameToken: 'officeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 120,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '2',
    displayName: 'Office (large)',
    displayNameToken: 'lgOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 180,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy. This slightly larger office might be a partner office or might double as a huddle room.',
    descriptionToken: 'lgOfficeDesc'
  },
  {
    id: '3',
    displayName: 'Workstation (small)',
    displayNameToken: 'smWorkstationDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 30,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Workstations are open individual work seats. Smaller workstations can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'smWorkstationDesc'
  },
  {
    id: '4',
    displayName: 'Workstation (medium)',
    displayNameToken: 'mdWorkstationDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 36,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Workstations are open individual work seats. The benefits of a slightly larger space includes more desk storage and privacy. This likely means the larger a desk, the most likely it is to assigned instead of free address. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. You will likely find additional communal storage and filing located throughout the floor.',
    descriptionToken: 'mdWorkstationDesc'
  },
  {
    id: '5',
    displayName: 'Workstation (large)',
    displayNameToken: 'lgWorkstationDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 48,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Workstations are open individual work seats. The benefits of a slightly larger space includes more desk storage and privacy. Desks this size are most likely assigned instead of free address. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. You will likely find additional communal storage and filing located throughout the floor.',
    descriptionToken: 'lgWorkstationDesc'
  },
  {
    id: '6',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 60,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '7',
    displayName: 'Huddle Room',
    displayNameToken: 'huddleRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 100,
    seats: 3,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Huddle Rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'huddleRoomDesc'
  },
  {
    id: '8',
    displayName: 'Small Meeting Room',
    displayNameToken: 'smMeetingRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 120,
    seats: 5,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A small conference room is an enclosed meeting space intended to comfortably accommodate 2-6 people at the table. These rooms can be used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
    descriptionToken: 'smMeetingConferenceRoomDesc'
  },
  {
    id: '9',
    displayName: 'Medium Meeting Room',
    displayNameToken: 'mdMeetingRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 240,
    seats: 9,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A medium conference room is an enclosed meeting space intended to comfortably accommodate 6-10 people at the table. These rooms are used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
    descriptionToken: 'mdMeetingConferenceRoomDesc'
  },
  {
    id: '10',
    displayName: 'Large Meeting Room',
    displayNameToken: 'lgMeetingRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 480,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A large conference room is an enclosed meeting space intended to comfortably accommodate 12-14 people at the table, overflow seating along the perimeter of the room is desirable. These rooms can be used for both internal and client-facing meetings and are likely to be used for presentations.',
    descriptionToken: 'lgMeetingConferenceRoomDesc'
  },
  {
    id: '11',
    displayName: 'X-Large Meeting Room / Boardroom',
    displayNameToken: 'xlMeetingRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 600,
    seats: 18,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A boardroom conference room is intended to comfortably accommodate 14-18 people at the table, with overflow seating along the perimeter of the room for at least 10 more. These rooms will most likely be used for large internal formal meetings and large client-facing meetings. As such, they should be equipped with robust A/V technology and should be branded as client facing-space.',
    descriptionToken: 'xlMeetingRoomDesc'
  },
  {
    id: '12',
    displayName: 'Multi-Purpose / Training',
    displayNameToken: 'multiPurpTrainingDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 1000,
    seats: 40,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Multi-purpose rooms are meant to accommodate a large number of people (20+). This space is appropriately named since many different activities can happen here including training, company events, and large presentations. The fit and finish of this space is functional/durable with many people coming and going but client facing as well. This space must have the appropriate A/V support - typically much more than the average conference room.',
    descriptionToken: 'multiPurpTrainingDesc'
  },
  {
    id: '13',
    displayName: 'Informal Collaboration Area',
    displayNameToken: 'informalCollabDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 120,
    seats: 6,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'informalCollabDesc'
  },
  {
    id: '14',
    displayName: 'Touchdown / Team Area',
    displayNameToken: 'touchdownTeamDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 120,
    seats: 6,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Touchdowns are individual or small internal team work spaces that are spread throughout the office for employee and visitor use. Touchdowns can be a variety of heights, shapes and sizes but are meant to stand out from individual workstations as a unique place to work for a short period of time. These should be plug-and-play spaces: easy to find and use.',
    descriptionToken: 'touchdownTeamDesc'
  },
  {
    id: '15',
    displayName: 'Dispersed Copy / Print Area',
    displayNameToken: 'dispersedCopyPrintAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '16',
    displayName: 'Pantry / Vending / Coffee Area',
    displayNameToken: 'pantryVendingCoffeeAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 240,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Unlike the central cafe, the pantry/vending/coffee area is meant for employees to grab the quick food and drink essentials need. Buzz and conversation is naturally found in areas with food, but these spaces usually do not contain seating causing people to come and go quickly within spending an extending amount of time socializing.',
    descriptionToken: 'pantryVendingCoffeeDesc'
  },
  {
    id: '17',
    displayName: 'Workplace Storage Room',
    displayNameToken: 'workplaceStorageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '18',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 60,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '19',
    displayName: 'Employee Coat Area',
    displayNameToken: 'employeeCoatAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 40,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '20',
    displayName: 'Dispersed Mail Area',
    displayNameToken: 'dispersedMailAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 60,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '21',
    displayName: 'File Room',
    displayNameToken: 'fileRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'This is paper file storage for individuals and teams. As a result of most offices digitization efforts, the need for both individual and team file storage is diminishing greatly. Still, this is a central space for legally required document copies or long term project materials, especially with most open office desks containing little storage.',
    descriptionToken: 'fileRoomDesc'
  },
  {
    id: '22',
    displayName: '3 Drawer Lateral File Unit',
    displayNameToken: 'drawerLateralFileUnitDefined',
    displayUnit: UNIT_LABEL.UNITS,
    category: CATEGORY.SUPPORT,
    sf: 3,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Personal storage for individuals at desks or offices. Contents are usually personal or active project materials.',
    descriptionToken: 'drawerLateralFileDesc'
  },
  {
    id: '23',
    displayName: 'Locker Unit',
    displayNameToken: 'lockerUnitDefined',
    displayUnit: UNIT_LABEL.UNITS,
    category: CATEGORY.SUPPORT,
    sf: 2,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'
  },
  {
    id: '24',
    displayName: 'Server Room',
    displayNameToken: 'serverRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 300,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '25',
    displayName: 'Front Desk Area',
    displayNameToken: 'frontDeskAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    description: 'This is first stop when employees and visitors come to the office. The desk should be able to accommodate at least one person with person workspace and storage. There will likely be vistor storage nearby as well. The front desk is placed within the larger reception hub where there will likely be seating and other accommodations.',
    descriptionToken: 'frontDeskAreaDesc'
  },
  {
    id: '26',
    displayName: 'Central Reception Hub',
    displayNameToken: 'centralReceptionHubDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 800,
    maxSF: 3000,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '27',
    displayName: 'Central Café Area',
    displayNameToken: 'centralCafeAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 800,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '28',
    displayName: 'Mail Center Area',
    displayNameToken: 'mailCenterAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    description: 'For larger organizations, a mail center is required to centralize all mail services. This will include large sorting and organization space, along with space for charts and equipment. This space likely looks very different for each organization since it is often fit between other program items.',
    descriptionToken: 'mailCenterAreaDesc'
  },
  {
    id: '29',
    displayName: 'Commercial Print Room',
    displayNameToken: 'commercialPrintRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    description: 'Often combined with the mailroom, companies provide in house capabilities to print, bind, and compile presentations in the print room. There will be several printers, and might include large format printers. Spaces will include storage space for supplies, and will also include counter space for smaller equipment but also space to layout prints.',
    descriptionToken: 'commercialPrintRoomDesc'
  },
  {
    id: '30',
    displayName: 'Internal Stair',
    displayNameToken: 'internalStairDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 500,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Internal stairs can be activated stairwells in the core that provide access between floors, or it can be additional feature elements that connect floors of an office. Can often be a gather space within the office and can be centralized for cafe, reception, or meeting spaces.',
    descriptionToken: 'internalStairDesc'
  },
  {
    id: '31',
    displayName: 'Kitchen Area',
    displayNameToken: 'kitchenAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 150,
    description: 'For spaces that will have an in-office dining service, a kitchen is required to support this amenity. The requirements of a kitchen will vary from organization to organization, but you can expect to find cooking appliances and refrigerator storage. There will also be dry goods and equipment storage. The kitchen will be adjacent to the servery and likely the conferencing/client amenities center.',
    descriptionToken: 'kitchenAreaDesc'
  },
  {
    id: '32',
    displayName: 'Servery Area',
    displayNameToken: 'serveryAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 150,
    description: 'The servery is the area where people get their food. It is likely connected to the kitchen and a dining area with tables and seating. The planning of a servery is very precise to manage queues and traffic. This size and shape of this space will likely vary between organizations.',
    descriptionToken: 'serveryAreaDesc'
  },
  {
    id: '33',
    displayName: 'Juice Bar / Coffee Shop Area',
    displayNameToken: 'juiceBarCoffeeShopAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    description: 'While a company might not have a full kitchen and servery, it may provide a coffee shop or juice bar as part of the food/drink amenities in the space. An organization might also have this kind of space in addition to its main dining services, especially if the office is large. This space will likely look like your local Starbucks with a working area for a staffer person separated by a serving counter.',
    descriptionToken: 'juiceBarCoffeeShopDesc'
  },
  {
    id: '34',
    displayName: 'Dining Support Space Area',
    displayNameToken: 'diningSupportAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'An organization that has robust dining services will likely have additional support spaces like storage in other parts of the building that need to be accounted for in the overall program. The space will vary between organizations.',
    descriptionToken: 'diningSupportAreaDesc'
  },
  {
    id: '35',
    displayName: 'Dining Area',
    displayNameToken: 'diningAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Dining near kitchen should provide a variety of choice for people to sit and eat lunch. Providing several different types of seating also allows the space to be utilized for meetings and collaboration outside of lunch time. Providing soft and hard seating along with bar and seated height settings gives users choice.',
    descriptionToken: 'diningAreaDesc'
  },
  {
    id: '36',
    displayName: 'Grab-N-Go Area',
    displayNameToken: 'grabNGoAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    description: 'A small convenience area with several fridges, shelves for chips and snacks, and a checkout machine. These Grab-N-Go spaces are typically monitored for security by camera. These spaces are often combined with a small pantry with fridges and freezers and prep area for food.',
    descriptionToken: 'grabNGoDesc'
  },
  {
    id: '37',
    displayName: 'Building Support Services Area',
    displayNameToken: 'buildingSupportServicesAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },

  // UK_REGULAR space units
  {
    id: '38',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 118.4,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '39',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 161.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '40',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 29.1,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '41',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 35.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '42',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 75.3,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '43',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 118.4,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '44',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 161.5,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '45',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 218,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '46',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 387.5,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '47',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 538.2,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '48',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 43.1,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '49',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 236.8,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '50',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.4587,
    maxSF: 290.6257
  },
  {
    id: '51',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '52',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '53',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '54',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 120,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '55',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 40,
    maxSF: 150,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '56',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '57',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 199.1324,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '58',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 86.11132,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '59',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 2.152783,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'

  },
  {
    id: '60',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '61',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '62',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '63',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '64',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '65',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '66',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 217.9693,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '67',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 269.0979,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '68',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 96.87524,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },
  {
    id: '69',
    displayName: 'Full Food Service',
    displayNameToken: 'fullFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    maxSF: 2000,
    description: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',
    descriptionToken: 'fullFoodServiceDesc'
  },

  // GERMANY_REGULAR space units
  {
    id: '70',
    displayName: 'Small office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 107.6, // 10 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'

  },
  {
    // NOTE: this is a duplicate of space unit 39
    id: '71',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 161.5, // 15 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '72',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 32.3, // 3 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '73',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 43.1, // 4 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '74',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 86.1, // 8 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '75',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 215.3, // 20 sqm
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '76',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 301.4, // 28 sqm
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '77',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 376.7, // 35 sqm
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '78',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 409, // 38 sqm
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '79',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 430.6, // 40 sqm
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    // NOTE: this is a duplicate of space unit 48
    // the German team might want to change the size
    // the initial size they gave (193.8 sf) seemed like a typo, so we stuck with the UK size
    id: '80',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 43.1,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '81',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 269.1, // 25 sqm
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '82',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 1291.668
  },
  {
    id: '83',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 1506.946
  },
  {
    id: '84',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 0
  },
  {
    id: '85',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 861.112
  },
  {
    id: '86',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 322.917
  },
  {
    id: '87',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 861.112
  },
  {
    id: '88',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 134.55,
    maxSF: 430.556
  },
  {
    id: '89',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 430.56,
    maxSF: 2152.78
  },
  {
    id: '90',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 215.28,
    maxSF: 4305.56
  },
  {
    id: '91',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 1076.39
  },
  {
    id: '92',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 0
  },
  {
    id: '93',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 1076.39
  },
  {
    id: '94',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.46, // 15 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '95',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 134.55, // 12.5 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '96',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.11, // 8 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '97',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 193.75, // 18 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '98',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.64, // 10 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '99',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.46, // 15 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '100',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.64, // 10 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },

  // APAC_REGULAR space units
  {
    id: '101',
    displayName: 'Office (Work/Meet)',
    displayNameToken: 'officeWorkMeetDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 118.4,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '102',
    displayName: 'Large Office (Work/Meet)',
    displayNameToken: 'lgOfficeWorkMeetDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 161.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '103',
    displayName: '1.6 Bench',
    displayNameToken: 'bench1pt6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 34.4,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '104',
    displayName: '1.8 Bench',
    displayNameToken: 'bench1pt8Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 40.9,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    // This space unit is not included in any KOPWMs
    id: '105',
    displayName: '1.8 ’L’ Workstation',
    displayNameToken: 'bench1pt8LDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 38,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    // NOTE: this is a duplicate of space unit 6
    // Deprecated in favor of space unit 131
    id: '106',
    displayName: 'Focus',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 60,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.'
  },
  {
    id: '107',
    displayName: 'Banquet - Work/Meet (2p)',
    displayNameToken: 'banquetWorkMeetDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 30,
    seats: 2,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '108',
    displayName: 'Café Table (4p)',
    displayNameToken: 'cafeTable4Defined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 60,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '109',
    displayName: 'Kitchen Table (8-10p)',
    displayNameToken: 'kitchenTable8to10Defined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 100,
    seats: 8,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '110',
    displayName: 'Standing Whiteboard',
    displayNameToken: 'standingWhiteboardDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.WE,
    sf: 35,
    seats: 3,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '111',
    displayName: 'Huddle Room (3p)',
    displayNameToken: 'huddleRoom3Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 80,
    seats: 3,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Huddle Rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'huddleRoomDesc'
  },
  {
    // NOTE: this is a duplicate of space unit 8
    id: '112',
    displayName: 'Sm Meeting Room (5p)',
    displayNameToken: 'smMeetingRoom5Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 120,
    seats: 5,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A small conference room is an enclosed meeting space intended to comfortably accommodate 2-6 people at the table. These rooms can be used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
    descriptionToken: 'smMeetingConferenceRoomDesc'
  },
  {
    id: '113',
    displayName: 'M Meeting Room (8p)',
    displayNameToken: 'mdMeetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 200,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A medium conference room is an enclosed meeting space intended to comfortably accommodate 6-10 people at the table. These rooms are used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
    descriptionToken: 'mdMeetingConferenceRoomDesc'
  },
  {
    id: '114',
    displayName: 'L Meeting Room (20p)',
    displayNameToken: 'lgMeetingRoom20Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 450,
    seats: 20,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A large conference room is an enclosed meeting space intended to comfortably accommodate 12-14 people at the table, overflow seating along the perimeter of the room is desirable. These rooms can be used for both internal and client-facing meetings and are likely to be used for presentations.',
    descriptionToken: 'lgMeetingConferenceRoomDesc'
  },
  {
    id: '115',
    displayName: 'X-Large / Boardroom',
    displayNameToken: 'xlBoardroom',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 750,
    seats: 30,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'A boardroom conference room is intended to comfortably accommodate 14-18 people at the table, with overflow seating along the perimeter of the room for at least 10 more. These rooms will most likely be used for large internal formal meetings and large client-facing meetings. As such, they should be equipped with robust A/V technology and should be branded as client facing-space.',
    descriptionToken: 'xlMeetingRoomDesc'
  },
  {
    // NOTE: this is a duplicate of space unit 25
    // Deprecated
    id: '116',
    displayName: 'Front Desk',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    description: 'This is first stop when employees and visitors come to the office. The desk should be able to accommodate at least one person with person workspace and storage. There will likely be vistor storage nearby as well. The front desk is placed within the larger reception hub where there will likely be seating and other accommodations.'
  },
  {
    // NOTE: this is a duplicate of space unit 26
    // Deprecated in favor of space unit 141
    id: '117',
    displayName: 'Central Reception Hub',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 800,
    maxSF: 3000,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and  other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.'
  },
  {
    // NOTE: this is a duplicate of space unit 33
    // Deprecated in favor of space unit 146
    id: '118',
    displayName: 'Juice Bar / Coffee Shop',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    description: 'While a company might not have a full kitchen and servery, it may provide a coffee shop or juice bar as part of the food/drink amenities in the space. An organization might also have this kind of space in addition to its main dining services, especially if the office is large. This space will likely look like your local Starbucks with a working area for a staffer person separated by a serving counter.'
  },
  {
    // NOTE: this is a duplicate of space unit 36
    // Deprecated
    id: '119',
    displayName: 'Grab-N-Go',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    description: 'A small convenience area with several fridges, shelves for chips and snacks, and a checkout machine.  These Grab-N-Go spaces are typically monitored for security by camera. These spaces are often combined with a small pantry with fridges and freezers and prep area for food.'
  },
  {
    // NOTE: this is a duplicate of space unit 15
    // Deprecated in favor of space unit 132
    id: '120',
    displayName: 'Dispersed Copy / Print Area',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.'
  },
  {
    // NOTE: this is a duplicate of space unit 16
    // Deprecated in favor of space unit 133
    id: '121',
    displayName: 'Pantry / Vending / Coffee',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 240,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Unlike the central cafe, the pantry/vending/coffee area is meant for employees to grab the quick food and drink essentials that they need. Buzz and conversation is naturally found in areas with food, but these spaces usually do not contain seating, causing people to come and go quickly without spending an extensive amount of time socializing.'
  },
  {
    // NOTE: this is a duplicate of space unit 17
    // Deprecated
    id: '122',
    displayName: 'Workplace Storage',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.'
  },
  {
    // NOTE: this is a duplicate of space unit 18
    // Deprecated in favor of space unit 135
    id: '123',
    displayName: 'Wellness Rooms',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 60,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.'
  },
  {
    // NOTE: this is a duplicate of space unit 19, with a different displayName
    id: '124',
    displayName: 'Employee Coats / Gym Lockers',
    displayNameToken: 'employeeCoatsGymLockersDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 40,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    // NOTE: this is a duplicate of space unit 20
    id: '125',
    displayName: 'Dispersed Mail Area',
    displayNameToken: 'dispersedMailAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 60,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    // NOTE: this is a duplicate of space unit 21
    id: '126',
    displayName: 'File Room',
    displayNameToken: 'fileRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 120,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'This is paper file storage for individuals and teams. As a result of most offices digitization efforts, the need for both individual and team file storage is diminishing greatly. Still, this is a central space for legally required document copies or long term project materials, especially with most open office desks containing little storage.',
    descriptionToken: 'fileRoomDesc'
  },
  {
    // NOTE: this is a duplicate of space unit 22
    // Deprecated in favor of space unit 136
    id: '127',
    displayName: '3 Drawer Lateral File',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 3,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Personal storage for individuals at desks or offices. Contents are usually personal or active project materials.'
  },
  {
    // NOTE: this is a duplicate of space unit 23
    id: '128',
    displayName: 'Lockers',
    displayNameToken: 'lockersDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 2,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'
  },
  {
    // Deprecated in favor of space unit 137
    id: '129',
    displayName: 'Server Room (central)',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 30,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.'
  },
  {
    // Deprecated in favor of space unit 137
    id: '130',
    displayName: 'Hub Room (per floor)',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 40,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    // NOTE: this is similar to space unit 6, but is a WE space, and doesn't include "Room" in the displayName
    id: '131',
    displayName: 'Focus',
    displayNameToken: 'focusDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 60,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '132',
    displayName: 'Dispersed Copy / Print / Scan Area',
    displayNameToken: 'dispersedCopyPrintScanAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 80,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    id: '133',
    displayName: 'Hydration Point (Water / Tea / Coffee)',
    displayNameToken: 'hydrationPointDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 50,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    id: '134',
    displayName: 'Central High Density Storage',
    displayNameToken: 'centralHighDensityStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 60,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    id: '135',
    displayName: 'Wellness / Mothers’ Room',
    displayNameToken: 'wellnessMothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 70,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    id: '136',
    displayName: '3 Drawer Lateral File',
    displayNameToken: 'drawerLateralFileDefined',
    displayUnit: UNIT_LABEL.UNITS,
    category: CATEGORY.SUPPORT,
    sf: 10,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Personal storage for individuals at desks or offices. Contents are usually personal or active project materials.',
    descriptionToken: 'drawerLateralFileDesc'
  },
  {
    id: '137',
    displayName: 'Server / Hub Rooms',
    displayNameToken: 'serverHubRoomsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 50,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED
  },
  {
    // NOTE: this is a duplicate of space unit 30
    id: '138',
    displayName: 'Internal Stair',
    displayNameToken: 'internalStairDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 500,
    seats: 0,
    circulationType: CIRCULATION_TYPE.BLENDED,
    description: 'Internal stairs can be activated stairwells in the core that provide access between floors, or it can be additional feature elements that connect floors of an office. Can often be a gather space within the office and can be centralized for cafe, reception, or meeting spaces.',
    descriptionToken: 'internalStairDesc'
  },
  {
    id: '139',
    displayName: 'IT Build / Store Room',
    displayNameToken: 'buildITStoreRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 250
  },
  {
    id: '140',
    displayName: 'Simple Reception + Wait',
    displayNameToken: 'simpleReceptionWaitDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    maxSF: 500
  },
  {
    id: '141',
    displayName: 'Central Reception Hub',
    displayNameToken: 'centralReceptionHubDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    maxSF: 1200,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '142',
    displayName: 'Central Café Social Hub',
    displayNameToken: 'centralCafeSocialHubDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    maxSF: 1200,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '143',
    displayName: 'Mail Centre',
    displayNameToken: 'mailCentreDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 250
  },
  {
    id: '144',
    displayName: 'Utility Store Room',
    displayNameToken: 'utilityStoreRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 30,
    maxSF: 60
  },
  {
    id: '144',
    displayName: 'Utility Store Room',
    displayNameToken: 'utilityStoreRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 30,
    maxSF: 60
  },
  {
    id: '145',
    displayName: 'Commercial Print Room',
    displayNameToken: 'commercialPrintRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    maxSF: 500,
    description: 'Often combined with the mailroom, companies provide in house capabilities to print, bind, and compile presentations in the print room. There will be several printers, and might include large format printers. Spaces will include storage space for supplies, and will also include counter space for smaller equipment but also space to layout prints.',
    descriptionToken: 'commercialPrintRoomDesc'
  },
  {
    id: '146',
    displayName: 'Juice Bar / Coffee Shop',
    displayNameToken: 'juiceBarCoffeeShopDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    maxSF: 300,
    description: 'While a company might not have a full kitchen and servery, it may provide a coffee shop or juice bar as part of the food/drink amenities in the space. An organization might also have this kind of space in addition to its main dining services, especially if the office is large. This space will likely look like your local Starbucks with a working area for a staffer person separated by a serving counter.',
    descriptionToken: 'juiceBarCoffeeShopDesc'
  },
  {
    id: '147',
    displayName: 'Building Support Services',
    displayNameToken: 'buildingSupportServicesDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 100,
    maxSF: 200,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },

  // SPAIN_REGULAR space units
  {
    id: '148',
    displayName: 'Small office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 129.17, // 12 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'

  },
  {
    // NOTE: this is a duplicate of space unit 39
    id: '149',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 172.22, // 16 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '150',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 24.11, // 2.24 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '151',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 27.56, // 2.56 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '152',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 64.58, // 6 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '153',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 107.64, // 10 sqm
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '154',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 172.22, // 16 sqm
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '155',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 215.28, // 20 sqm
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '156',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 387.5, // 36 sqm
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '157',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 538.2, // 50 sqm
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '158',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 129.17, // 12 sqm
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '159',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 107.64, // 10 sqm
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '160',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '161',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '162',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 0
  },
  {
    id: '163',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 430.56
  },
  {
    id: '164',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '165',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.81,
    maxSF: 505.9
  },
  {
    id: '166',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.81,
    maxSF: 505.9
  },
  {
    id: '167',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000
  },
  {
    id: '168',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 322.92
  },
  {
    id: '169',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 43.06,
    maxSF: 322.92
  },
  {
    id: '170',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 53.82,
    maxSF: 2317.23
  },
  {
    id: '171',
    displayName: 'Mothers’/Medical Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 134.5, // 12.5 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '172',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86, // 8 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '173',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.6, // 10 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '174',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4, // 15 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '175',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86, // 8 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  // FRANCE_REGULAR space units
  {
    id: '176',
    displayName: 'Small office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 129.17, // 12 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'

  },
  {
    // NOTE: this is a duplicate of space unit 39
    id: '177',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 172.22, // 16 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '178',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 24.11, // 2.24 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '179',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 27.56, // 2.56 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '180',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 64.58, // 6 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '181',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 86.1, // 8 sqm
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '182',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 129.2, // 12 sqm
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '183',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 172.2, // 16 sqm
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '184',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 258.3, // 24 sqm
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '185',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 322.9, // 30 sqm
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '186',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 107.6, // 10 sqm
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '187',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 161.5, // 15 sqm
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '188',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 172.22, // 16 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '189',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 538.2, // 50 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '190',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 269.1, // 25 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '191',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 161.46, // 15 sqm
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '192',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 215.28, // 20 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 290.63
  },
  {
    id: '193',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 376.74, // 35 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.81,
    maxSF: 505.9
  },
  {
    id: '194',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 430.56, // 40 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.81,
    maxSF: 505.9
  },
  {
    id: '195',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 645.83, // 60 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000
  },
  {
    id: '196',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 129.17, // 12 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 120,
    maxSF: 500
  },
  {
    id: '197',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 172.22, // 16 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 40,
    maxSF: 150
  },
  {
    id: '198',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.2, // 12 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '199',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 172.2, // 16 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '200',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1, // 8 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '201',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.2, // 12 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '202',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 172.2, // 16 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '203',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.5, // 15 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '204',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1, // 8 sqm
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '205',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomSharedFocus',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SHARED_FOCUS,
    sf: 60,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED
  },

  // SLOVAKIA_REGULAR space units
  {
    id: '206',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 107.6,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '207',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 150.7,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '208',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 32.3,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '209',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 32.3,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '210',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 64.6,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '211',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 107.6,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '212',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 150.7,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '213',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 215.3,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '214',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 323,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '215',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 430.6,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '216',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 64.6,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '217',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 150.7,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '218',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.639,
    maxSF: 161.459
  },
  {
    id: '219',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 215.278,
    maxSF: 322.917,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '220',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 269.098,
    maxSF: 538.196,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '221',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.459,
    maxSF: 645.835,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '222',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 86.1113,
    maxSF: 215.278,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '223',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 32.2917,
    maxSF: 107.639,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '224',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 86.1113,
    maxSF: 322.917,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '225',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '226',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '227',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 2.152783,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'

  },
  {
    id: '228',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 129.167,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '229',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 129.167,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '230',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.167,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '231',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 150.695,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '232',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 107.639,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '233',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.639,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '234',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.167,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '235',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 215.278,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '236',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },
  {
    id: '237',
    displayName: 'Full Food Service',
    displayNameToken: 'fullFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 269.098,
    maxSF: 1291.67,
    description: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',
    descriptionToken: 'fullFoodServiceDesc'
  },
  // POLAND_REGULAR space units
  {
    id: '238',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 1,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    minSF: 86.1113,
    maxSF: 118.403,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '239',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 161.459,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '240',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 53.8196,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '241',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 53.8196,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '242',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 1,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    minSF: 53.8196,
    maxSF: 75.3474,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '243',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 86.1113,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '244',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 129.167,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '245',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 172.223,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '246',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 258.334,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '247',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 322.917,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '248',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 43.0556,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '249',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 236.806,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '250',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.4587,
    maxSF: 290.6257
  },
  {
    id: '251',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '252',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '253',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '254',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 120,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '255',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 40,
    maxSF: 150,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '256',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '257',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 199.1324,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '258',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 86.11132,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '259',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 2.152783,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'

  },
  {
    id: '260',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '261',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '262',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '263',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '264',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '265',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '266',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 269.098,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '267',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 269.0979,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '268',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },
  {
    id: '269',
    displayName: 'Full Food Service',
    displayNameToken: 'fullFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    maxSF: 2000,
    description: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',
    descriptionToken: 'fullFoodServiceDesc'
  },
  // HUNGARY_REGULAR space units
  {
    id: '270',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 107.639,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '271',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 150.695,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '272',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 29.1,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '273',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 35.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '274',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 64.6,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '275',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 118.4,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '276',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 172.223,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '277',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 218,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '278',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 387.5,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '279',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 538.2,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '280',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 64.6,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '281',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 193.8,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '282',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.4587,
    maxSF: 290.6257
  },
  {
    id: '283',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '284',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '285',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '286',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 120,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '287',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 40,
    maxSF: 150,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '288',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '289',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 199.1324,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '290',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf: 86.11132,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '291',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 1.7,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'

  },
  {
    id: '292',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '293',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '294',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 86.1113,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '295',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.167,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '296',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '297',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '298',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 217.9693,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '299',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 215.278,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '300',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 96.87524,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },
  {
    id: '301',
    displayName: 'Full Food Service',
    displayNameToken: 'fullFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    maxSF: 2000,
    description: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',
    descriptionToken: 'fullFoodServiceDesc'
  },
  
  //COLOMBIA_REGULAR_PROFILES
  {
    id: '302',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 96.9,
    seats: 2,
    sf: 118.4,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '303',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 161.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '304',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 29.1,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '305',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf:35.5,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '306',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 75.3,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '307',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 118.4,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '308',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 161.5,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '309',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 218,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '310',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf:387.5,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '311',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 538.2,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '312',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf:43.1,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '313',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf:236.8,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '314',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.4587,
    maxSF: 290.6257
  },
  {
    id: '315',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '316',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 236.8061,
    maxSF: 505.904,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '317',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 200,
    maxSF: 1000,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '318',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 120,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '319',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 40,
    maxSF: 150,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '320',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 80,
    maxSF: 500,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '321',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf:199.1324,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '322',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.SUPPORT,
    sf:86.11132,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '323',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf:2.152783,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'

  },
  {
    id: '324',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '325',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '326',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '327',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '328',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '329',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:161.4587,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '330',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:217.9693,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '331',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:269.0979,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '332',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf:96.87524,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },
  {
    id: '333',
    displayName: 'Full Food Service',
    displayNameToken: 'fullFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 300,
    maxSF: 2000,
    description: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',
    descriptionToken: 'fullFoodServiceDesc'
  },

  // Netherlands space units
  {
    id: '334',
    displayName: 'Small Office',
    displayNameToken: 'smOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 96.9,
    seats: 2,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '335',
    displayName: 'Regular Office',
    displayNameToken: 'regOfficeDefined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 193.8,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
    descriptionToken: 'officeDesc'
  },
  {
    id: '336',
    displayName: '5’ Bench Desk',
    displayNameToken: 'benchDesk5Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 59.2,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '337',
    displayName: '6’ Bench Desk',
    displayNameToken: 'benchDesk6Defined',
    displayUnit: UNIT_LABEL.SEATS,
    category: CATEGORY.ME,
    sf: 64.6,
    seats: 1,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
    descriptionToken: 'benchDeskDesc'
  },
  {
    id: '338',
    displayName: 'Focus Room',
    displayNameToken: 'focusRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.ME,
    sf: 64.6,
    seats: 1,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
    descriptionToken: 'focusRoomDesc'
  },
  {
    id: '339',
    displayName: 'Meeting Room (4 people)',
    displayNameToken: 'meetingRoom4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 107.6,
    seats: 4,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc'
  },
  {
    id: '340',
    displayName: 'Meeting Room (6 people)',
    displayNameToken: 'meetingRoom6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 161.5,
    seats: 6,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 6 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc6'
  },
  {
    id: '341',
    displayName: 'Meeting Room (8 people)',
    displayNameToken: 'meetingRoom8Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 193.8,
    seats: 8,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 8 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc8'
  },
  {
    id: '342',
    displayName: 'Meeting Room (12 people)',
    displayNameToken: 'meetingRoom12Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 258.3,
    seats: 12,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 12 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc12'
  },
  {
    id: '343',
    displayName: 'Meeting Room (15 people)',
    displayNameToken: 'meetingRoom15Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 322.9,
    seats: 15,
    circulationType: CIRCULATION_TYPE.ENCLOSED,
    description: 'Meeting rooms are enclosed spaces intended to support small group meetings of 15 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
    descriptionToken: 'meetingRoomDesc15'
  },
  {
    id: '344',
    displayName: 'Semi-Enclosed Meeting Booth (4 people)',
    displayNameToken: 'semiEnclosedMeetingBooth4Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 86.1,
    seats: 4,
    circulationType: CIRCULATION_TYPE.OPEN
  },
  {
    id: '345',
    displayName: 'Open Collaboration Space (4-6 people)',
    displayNameToken: 'openCollabSpace4to6Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.WE,
    sf: 129.2,
    seats: 5,
    circulationType: CIRCULATION_TYPE.OPEN,
    description: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients.',
    descriptionToken: 'openCollabSpace4to6Desc'
  },
  {
    id: '346',
    displayName: 'Contemplation Space',
    displayNameToken: 'contemplationSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 861.112
  },
  {
    id: '347',
    displayName: 'Main IT Server / Equipment Room',
    displayNameToken: 'mainITEquipRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 134.55,
    maxSF: 430.556,
    description: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'mainITandServerRoomDesc'
  },
  {
    id: '348',
    displayName: 'Reception Area',
    displayNameToken: 'receptionAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 430.86,
    maxSF: 2152.78,
    description: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
    descriptionToken: 'centralReceptionHubDesc'
  },
  {
    id: '349',
    displayName: 'Café / Food Service',
    displayNameToken: 'cafeFoodServiceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf:1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minsf: 215.28,
    description: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
    descriptionToken: 'cafeFoodServiceDesc'
  },
  {
    id: '350',
    displayName: 'Team Storage',
    displayNameToken: 'teamStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 1076.39,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '351',
    displayName: 'Coat Closets',
    displayNameToken: 'coatClosetsDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'coatClosetsDesc'
  },
  {
    id: '352',
    displayName: 'General Storage (stores and goods in/out)',
    displayNameToken: 'genStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF:1076.39,
    description: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
    descriptionToken: 'storageDesc'
  },
  {
    id: '353',
    displayName: 'Centralised Copy, Print & Mail',
    displayNameToken: 'centralisedCopyPrintMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 645.5,
    description: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
    descriptionToken: 'copyPrintDesc'
  },
  {
    id: '354',
    displayName: 'Special Resource Area',
    displayNameToken: 'specialResourceAreaDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 161.46,
    maxSF: 1506.946,
    description: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organizations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
    descriptionToken: 'specialResourceBuildingSupportAreaDesc'
  },
  {
    id: '355',
    displayName: 'Personal Storage (lockers)',
    displayNameToken: 'personalStorageDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
    descriptionToken: 'lockerDesc'
  
  },
  {
    id: '356',
    displayName: 'IT Storage Space',
    displayNameToken: 'storageITSpaceDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 861.112
  },
  {
    id: '357',
    displayName: 'Secondary Server / Telecom Room',
    displayNameToken: 'secondaryServerRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.SUPPORT,
    sf: 1,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    minSF: 107.64,
    maxSF: 322.917,
    description: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
    descriptionToken: 'secondaryServerRoomDesc'
  },
  {
    id: '358',
    displayName: 'Mothers’ Room',
    displayNameToken: 'mothersRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 129.2,
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'mothersRoomDesc'
  },
  {
    id: '359',
    displayName: 'Wellness Room',
    displayNameToken: 'wellnessRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 134.55,
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount.',
    descriptionToken: 'wellnessRoomDesc'
  },
  {
    id: '360',
    displayName: 'IT Build Space',
    displayNameToken: 'buildITSpaceDefined',
    displayUnit: UNIT_LABEL.AREAS,
    category: CATEGORY.AMENITY,
    sf: 86.11,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '361',
    displayName: 'Worker Council Office (2 people)',
    displayNameToken: 'workerCouncilOffice2Defined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 193.75,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '362',
    displayName: 'Security Control Room',
    displayNameToken: 'securityCtrlRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.64,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY
  },
  {
    id: '363',
    displayName: 'Centralised Mail',
    displayNameToken: 'centralisedMailDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 161.46,
    seats: 0,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
    descriptionToken: 'centralisedDispersedMailAreaDesc'
  },
  {
    id: '364',
    displayName: 'Visitor Coat / Luggage Room',
    displayNameToken: 'visitorCoatLuggageRoomDefined',
    displayUnit: UNIT_LABEL.ROOMS,
    category: CATEGORY.AMENITY,
    sf: 107.64,
    seats: 1,
    circulationType: CIRCULATION_TYPE.AMENITY,
    description: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
    descriptionToken: 'visitorCoatLuggageRoomDesc'
  },

    
];

module.exports = _.keyBy(spaceUnits, 'id');
