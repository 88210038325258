const React = ('react');
const { useLocation, useNavigate, useParams }  = require('react-router-dom');

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();


        return <Component {...props} location={location} history={navigate} params={params} />;
    }

    return ComponentWithRouterProp;
}