const _                  = require('lodash');
const MEASUREMENT_SYSTEM = require('wp-constants').shared.calculator.MEASUREMENT_SYSTEM;

const units = {};

const IMPERIAL_TO_METRIC_COPY = {
  SF: 'SM',
  USF: 'USM',
  RSF: 'RSM',
  NSF: 'NSM',
  NIA: 'NIA',
  feet: 'meters',
  Feet: 'Meters',
  footage: 'meters',
  Footage: 'Meters',
  'sqft.': 'sqm.',
  foot: 'meter'
};

const FEET_TO_METERS = 0.3048;
const SQ_FEET_TO_SQ_METERS = FEET_TO_METERS * FEET_TO_METERS;
const TSUBO_TO_SQ_METERS = 3.305785;
const TSUBO_TO_SQ_FEET = 35.58;

units.isImperial = function(system) {
  return system === MEASUREMENT_SYSTEM.IMPERIAL;
};

units.isMetric = function(system) {
  return system === MEASUREMENT_SYSTEM.METRIC;
};

units.isTsubo = function(system) {
  return system === MEASUREMENT_SYSTEM.TSUBO;
};

units.getCopyForMeasurementSystem = function(imperialCopy, system) {
  if (units.isImperial(system)) return imperialCopy;
  if (units.isTsubo(system) && imperialCopy !== 'NIA') return 'Tsubo';
  return IMPERIAL_TO_METRIC_COPY[imperialCopy];
};

/* Exact same function as getCopyForMeasurementSystem, but renamed for clarity to indicate that the
   returned value serves as a token for Spacer Free to use, whereas in SpacerPro,
   getCopyForMeasurementSystem is rendered directly into the components. */
units.getTokenKeyForMeasurementSystem = function(imperialCopy, system) {
  return units.getCopyForMeasurementSystem(imperialCopy, system);
};

units.getNumberForMeasurementSystem = function(squareFootage, system, opts = { precisionForMeter: 0, precisionForFeet: 0, precisionForTsubo: 0 }) {
  switch (system) {
    case MEASUREMENT_SYSTEM.METRIC: {
      return _.round(squareFootage * SQ_FEET_TO_SQ_METERS, opts.precisionForMeter);
    }

    case MEASUREMENT_SYSTEM.TSUBO: {
      return _.round(squareFootage / TSUBO_TO_SQ_FEET, opts.precisionForTsubo);
    }

    case MEASUREMENT_SYSTEM.IMPERIAL:
    default: {
      return _.round(squareFootage, opts.precisionForFeet);
    }
  }
};

units.getSqFeetForMeasurementSystem = function(value, system) {
  switch (system) {
    case MEASUREMENT_SYSTEM.METRIC: {
      return units.convertSqMetersToSqFeet(value);
    }

    case MEASUREMENT_SYSTEM.TSUBO: {
      return units.convertTsuboToSqFeet(value);
    }

    case MEASUREMENT_SYSTEM.IMPERIAL:
    default: {
      return value;
    }
  }
};

units.convertSqMetersToSqFeet = function(squareMeters) {
  return Math.round(squareMeters / SQ_FEET_TO_SQ_METERS);
};

units.convertSqMetersToTsubo = function(squareMeters) {
  return Math.round(squareMeters / TSUBO_TO_SQ_METERS);
};

units.convertSqFeetToTsubo = function(squareFeet) {
  return Math.round(squareFeet / TSUBO_TO_SQ_FEET);
};

units.convertTsuboToSqFeet = function(tsubo) {
  return Math.round(tsubo * TSUBO_TO_SQ_FEET);
};

module.exports = units;

