const _                             = require('lodash');
const spaceUnitMap                  = require('./spaceUnitMap');
const originalProfileMap            = require('./profileMap');
const { profileNameIds }            = require('./profileNames');
const {
  CIRCULATION_TYPE,
  CATEGORY
}                                   = require('wp-constants').shared.spaceUnit;
const { US_REGULAR }                = require('wp-constants').spacerFree.calculator.PROFILE_TYPE; // TODO: will move this to shared
const { MEDIUM }                    = require('wp-constants').spacerFree.calculator.COLLABORATION_LEVEL;
const { POPULATION_BLANK_TEMPLATE } = require('wp-constants').spacerPro.blankTemplatePopulationLocation;

const profileMap = _.cloneDeep(originalProfileMap);
// updates to KOP in spacer pro
_.forEach(profileMap, (profile) => {
  const usRegularKop = profile.kitOfPartsWithMultipliers[US_REGULAR];
  _.forEach(usRegularKop, (category) => {
    _.forEach(category, (unit) => {
      // update multiplier of file rooms
      if (unit.spaceUnitId === '21' && unit.multiplier === 80) unit.multiplier = 1.2;
      // exculde focus room from ME section
      if (unit.spaceUnitId === '6') category.splice(category.indexOf(unit), 1);
    });
  });
});

function getSpaceUnit(spaceUnitWithMultiplier) {
  const id = spaceUnitWithMultiplier.spaceUnitId;
  return spaceUnitMap[id];
}

function getMeKitOfPartsWithMultiplier(meKitOfParts, program) {
  _.forEach(meKitOfParts, (spaceUnitWithMultiplier) => {
    const spaceUnit = getSpaceUnit(spaceUnitWithMultiplier);
    const meUnit = {
      name: spaceUnit.displayName,
      category: spaceUnit.category,
      sf: spaceUnit.sf,
      circulationType: spaceUnit.circulationType,
      multiplier: spaceUnitWithMultiplier.multiplier
    };
    program.me.push(meUnit);
  });
}

function getSharedFocusKitOfPartsWithMultiplier(sharedFocusKitOfParts, program) {
  _.forEach(sharedFocusKitOfParts, (spaceUnitWithMultiplier) => {
    const spaceUnit = getSpaceUnit(spaceUnitWithMultiplier);
    const sharedFocusUnit = {
      name: spaceUnit.displayName,
      category: spaceUnit.category,
      sf: spaceUnit.sf,
      circulationType: spaceUnit.circulationType,
      ratio: spaceUnitWithMultiplier.multiplier,
      seats: spaceUnit.seats
    };
    program.sharedFocus.push(sharedFocusUnit);
  });
}

function getSupportKitOfPartsWithMultiplier(supportKitOfParts, program) {
  _.forEach(supportKitOfParts, (spaceUnitWithMultiplier) => {
    const spaceUnit = getSpaceUnit(spaceUnitWithMultiplier);
    const supportUnit = {
      name: spaceUnit.displayName,
      category: spaceUnit.category,
      sf: spaceUnit.sf,
      multiplier: spaceUnitWithMultiplier.multiplier,
      circulationType: CIRCULATION_TYPE.AMENITY // in Spacer Pro, all support parts have amenity circulation type
    };
    program.support.push(supportUnit);
  });
}

function getAmenityKitOfPartsWithMultiplier(amenityKitOfParts, program) {
  _.forEach(amenityKitOfParts, (spaceUnitWithMultiplier) => {
    const spaceUnit = getSpaceUnit(spaceUnitWithMultiplier);
    const amenityUnit = {
      name: spaceUnit.displayName,
      category: spaceUnit.category,
      sf: spaceUnit.sf,
      circulationType: spaceUnit.circulationType,
      multiplier: spaceUnitWithMultiplier.multiplier,
      minSF: spaceUnit.minSF || 0,
      maxSF: spaceUnit.maxSF || 0
    };
    program.amenity.push(amenityUnit);
  });
}

function getProfileAssumptions(collaborationRatio, program) {
  program = {
    ...program,
    assumptions: {
      sharingPopulation: 1,
      collaborationRatio,
      assignedSeating: true
    }
  };
  return program;
}

function getKitOfPartsWithMultiplierAndAssumptions(profileKitOfParts, collaborationRatio) {
  let program = {
    me: [],
    sharedFocus: [],
    support: [],
    amenity: [],
    assumptions: {}
  };

  getMeKitOfPartsWithMultiplier(profileKitOfParts[CATEGORY.ME], program);
  getSharedFocusKitOfPartsWithMultiplier(profileKitOfParts[CATEGORY.SHARED_FOCUS], program);
  getSupportKitOfPartsWithMultiplier(profileKitOfParts[CATEGORY.SUPPORT], program);
  getAmenityKitOfPartsWithMultiplier(profileKitOfParts[CATEGORY.AMENITY], program);
  program = getProfileAssumptions(collaborationRatio, program);

  return program;
}

function getTemplateWithoutOrder(profileMapId) {
  const profileInfo = profileMap[profileMapId];
  return {
    // the substring(4) is to chop of `The ` from the beginning of the name, ex: `The Optimizer` -> `Optimizer`
    name: profileInfo.displayName.substring(4),
    id: profileInfo.id,
    templateType: 'population',
    program: getKitOfPartsWithMultiplierAndAssumptions(
      profileInfo.kitOfPartsWithMultipliers[US_REGULAR],
      profileInfo.collaborationLevels[US_REGULAR][MEDIUM]
    )
  };
}

// All profiles based off Spacer Free US_Regular profiles, blank template only has basic support rows
module.exports = [

  POPULATION_BLANK_TEMPLATE,

  getTemplateWithoutOrder(profileNameIds.EFFICIENT),

  getTemplateWithoutOrder(profileNameIds.WHITE_COLLAR),

  getTemplateWithoutOrder(profileNameIds.WHITE_GLOVE),

  getTemplateWithoutOrder(profileNameIds.OPEN_OFFICE),

  getTemplateWithoutOrder(profileNameIds.WORKPLACE_360),

  getTemplateWithoutOrder(profileNameIds.CAMPUS),

  getTemplateWithoutOrder(profileNameIds.STARTUP),

  getTemplateWithoutOrder(profileNameIds.ENG_CREATIVE)
];

