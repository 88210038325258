const {
  CIRCULATION_TYPE,
  CATEGORY
}                  = require('wp-constants').shared.spaceUnit;

module.exports = {
  POPULATION_SUPPORT_AUTOFILL_OPTIONS: [
    {
      name: 'File Room',
      category: CATEGORY.SUPPORT,
      multiplier: 2,
      sf: 120,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: '3H Drawer Lateral Files',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 3,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Lockers',
      category: CATEGORY.SUPPORT,
      multiplier: 2,
      sf: 2,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Wellness Room',
      category: CATEGORY.SUPPORT,
      multiplier: 0.5,
      sf: 100,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Mothers\'s Room',
      category: CATEGORY.SUPPORT,
      multiplier: 0.5,
      sf: 100,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'IT Help Desk/Storage',
      category: CATEGORY.SUPPORT,
      multiplier: 0.25,
      sf: 200,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Conference Center',
      category: CATEGORY.AMENITY,
      multiplier: 6,
      minSf: 1500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Breakout Space',
      category: CATEGORY.AMENITY,
      multiplier: 2,
      minSf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Breakout Room (8-10 seats)',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 275,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Multi-Purpose (Auditorium Style Seats)',
      category: CATEGORY.AMENITY,
      multiplier: 4.7,
      minSf: 1250,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Training (Class Seating w/ Tables)',
      category: CATEGORY.SUPPORT,
      multiplier: 4.7,
      sf: 1250,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Training (60 seats at tables)',
      category: CATEGORY.SUPPORT,
      multiplier: 2.2,
      sf: 2400,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Auditorium',
      category: CATEGORY.AMENITY,
      multiplier: 2.2,
      minSf: 1500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Audio/Visual',
      category: CATEGORY.AMENITY,
      multiplier: 0.25,
      minSf: 150,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Furniture Storage',
      category: CATEGORY.AMENITY,
      multiplier: 0.25,
      minSf: 150,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Coats',
      category: CATEGORY.SUPPORT,
      multiplier: 0.1,
      sf: 40,
      circulationType: CIRCULATION_TYPE.AMENITY
    }
  ],

  LOCATION_AUTOFILL_OPTIONS: [
    {
      name: 'Copy/Print/Supplies',
      category: CATEGORY.SUPPORT,
      multiplier: 2,
      sf: 120,
      circulationType: CIRCULATION_TYPE.AMENITY,
      isFloorDependent: true
    },
    {
      name: 'Pantry/Break Room',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY,
      isFloorDependent: true
    },
    {
      name: 'General Storage',
      category: CATEGORY.SUPPORT,
      multiplier: 1.5,
      sf: 120,
      circulationType: CIRCULATION_TYPE.AMENITY,
      isFloorDependent: true
    },
    {
      name: 'File Room',
      category: CATEGORY.SUPPORT,
      multiplier: 2,
      sf: 120,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: '3H Drawer Lateral Files',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 3,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Lockers',
      category: CATEGORY.SUPPORT,
      multiplier: 2,
      sf: 2,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Wellness Room',
      category: CATEGORY.SUPPORT,
      multiplier: 0.5,
      sf: 100,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Mothers\'s Room',
      category: CATEGORY.SUPPORT,
      multiplier: 0.5,
      sf: 100,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'IT Work/Storage',
      category: CATEGORY.SUPPORT,
      multiplier: 0.25,
      sf: 200,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Floor Elevator Lobby',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 300,
      circulationType: CIRCULATION_TYPE.AMENITY,
      isFloorDependent: true
    },
    {
      name: 'Café',
      category: CATEGORY.SUPPORT,
      multiplier: 4.5,
      sf: 1200,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Grab-N-Go/Micro Market',
      category: CATEGORY.SUPPORT,
      multiplier: 0.78,
      sf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Juice/Coffee Bar',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 750,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Full Food Service',
      category: CATEGORY.AMENITY,
      multiplier: 10.6,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Cafeteria Kitchen',
      category: CATEGORY.AMENITY,
      multiplier: 2.58,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Food Service Support',
      category: CATEGORY.AMENITY,
      multiplier: 1,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Cafeteria Dining',
      category: CATEGORY.AMENITY,
      multiplier: 5,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Servery',
      category: CATEGORY.AMENITY,
      multiplier: 3.19,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Executive Dining',
      category: CATEGORY.AMENITY,
      multiplier: 0.6,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'IT Help Desk',
      category: CATEGORY.AMENITY,
      multiplier: 2.5,
      minSf: 500,
      maxSf: 1500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Boardroom (18 Seats)',
      category: CATEGORY.AMENITY,
      multiplier: 2.5,
      minSf: 525,
      maxSf: 750,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Fitness Center',
      category: CATEGORY.AMENITY,
      multiplier: 6.25,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Health Center',
      category: CATEGORY.AMENITY,
      multiplier: 1,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Child Care',
      category: CATEGORY.AMENITY,
      multiplier: 5,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Dry Cleaning/Laundry',
      category: CATEGORY.AMENITY,
      multiplier: 0.8,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Conference Center',
      category: CATEGORY.AMENITY,
      multiplier: 6,
      minSf: 1500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Breakout Space',
      category: CATEGORY.AMENITY,
      multiplier: 2,
      minSf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Breakout Room (8-10 seats)',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 275,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Multi-Purpose (Auditorium Style Seats)',
      category: CATEGORY.AMENITY,
      multiplier: 4.7,
      minSf: 1250,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Training (Class Seating w/ Tables)',
      category: CATEGORY.SUPPORT,
      multiplier: 4.7,
      sf: 1250,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Training (60 seats at tables)',
      category: CATEGORY.SUPPORT,
      multiplier: 2.2,
      sf: 2400,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Auditorium',
      category: CATEGORY.AMENITY,
      multiplier: 2.2,
      minSf: 1500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Audio/Visual',
      category: CATEGORY.AMENITY,
      multiplier: 0.25,
      minSf: 150,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Furniture Storage',
      category: CATEGORY.AMENITY,
      multiplier: 0.25,
      minSf: 150,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Coats',
      category: CATEGORY.SUPPORT,
      multiplier: 0.1,
      sf: 40,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Reception',
      category: CATEGORY.AMENITY,
      multiplier: 2.5,
      minSf: 800,
      maxSf: 2500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Receptionist',
      category: CATEGORY.AMENITY,
      multiplier: 0.5,
      minSf: 150,
      maxSf: 300,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Mail Center',
      category: CATEGORY.AMENITY,
      multiplier: 0.5,
      minSf: 200,
      maxSf: 1200,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Reproduction Room',
      category: CATEGORY.AMENITY,
      multiplier: 0.8,
      minSf: 400,
      maxSf: 1200,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Security Control Room',
      category: CATEGORY.AMENITY,
      multiplier: 0.5,
      minSf: 200,
      maxSf: 1000,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Data Center',
      category: CATEGORY.AMENITY,
      multiplier: 1.5,
      minSf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Building Support Services',
      category: CATEGORY.AMENITY,
      multiplier: 2.1,
      minSf: 0,
      circulationType: CIRCULATION_TYPE.AMENITY
    },
    {
      name: 'Internal Stair',
      category: CATEGORY.SUPPORT,
      multiplier: 1,
      sf: 500,
      circulationType: CIRCULATION_TYPE.AMENITY,
      isFloorDependent: true
    }
  ]
};
