const _            = require('lodash');
const constants    = require('wp-constants').spacerFree;
const locationUtil = require('../util/location');
const unitsUtil    = require('../util/units');

const REGIONS   = constants.calculator.RSF_REGIONS;
const regionToCityList = {
  [REGIONS.NYC]: constants.geography.CITIES_OF_NY,
  [REGIONS.USA_METRO]: constants.geography.MOST_POPULATED_US_CITIES
};

const nycUSFFactor = 1.15;

const regionToDefaultLossFactor = {
  [REGIONS.NYC]: 0.27,
  [REGIONS.USA_METRO]: 0.175,
  [REGIONS.USA_SMALL]: 0.15,
  [REGIONS.DEFAULT_INTL]: 0.175,
  [REGIONS.UK]: 0.13,
  [REGIONS.DEFAULT_USA]: 0.15,
  [REGIONS.GE]: 0.23,
  [REGIONS.APAC]: 0.13,
  [REGIONS.FR]: 0.2912
};

function getRegion(location) {
  // TODO: reevaluate this when we go international.
  const { city, state, country, nonStandardUSAFormat } = locationUtil.getCityStateCountry(location);

  if (locationUtil.isUK(country)) {
    return REGIONS.UK;
  }

  // If the location is outside the USA or not an expected format for USA, use appropriate default regions
  if (locationUtil.isAPAC(country)) {
    return REGIONS.APAC;
  }

  if (locationUtil.isGermany(country)) {
    return REGIONS.GE;
  }

  if (locationUtil.isFrance(country)) {
    return REGIONS.FR;
  }

  if (!locationUtil.isUSA(country)) {
    return REGIONS.DEFAULT_INTL;
  }

  if (nonStandardUSAFormat) {
    return REGIONS.DEFAULT_USA;
  }

  if ((state === 'NY' || state === 'New York') && regionToCityList[REGIONS.NYC].indexOf(city) !== -1) {
    return REGIONS.NYC;
  }

  const metroLocation = _.find(regionToCityList[REGIONS.USA_METRO], { city });
  if (metroLocation && (state === metroLocation.state || state === constants.geography.STATE_ABBREVIATION_TO_FULL[metroLocation.state])) {
    return REGIONS.USA_METRO;
  }

  return REGIONS.USA_SMALL;
}

function getDefaultLossFactorForSpecficLocation(location) {
  const { state, nonStandardUSAFormat } = locationUtil.getCityStateCountry(location);

  if (nonStandardUSAFormat) return null;

  // New Jersey - 18%
  if (state === 'NJ' || state === 'New Jersey') return 0.18;

  return null;
}

function getDefaultLossFactor(location) {
  // First, check if there's a specific default loss factor for the location
  const lossFactor = getDefaultLossFactorForSpecficLocation(location);

  // If not, return the default loss factor for the region
  if (lossFactor) return lossFactor;

  return regionToDefaultLossFactor[getRegion(location)];
}

function getRSFForLocation(location, usf, lossFactor) {
  lossFactor = _.isUndefined(lossFactor) ? getDefaultLossFactor(location) : lossFactor; // so 0 could be a legit lossFactor input
  const region = getRegion(location);

  switch (region) {
    case REGIONS.NYC:
      return usf / (1 - lossFactor);
    case REGIONS.USA_METRO:
    case REGIONS.USA_SMALL:
    case REGIONS.DEFAULT_USA:
    case REGIONS.UK:
    case REGIONS.GE:
    case REGIONS.FR:
    case REGIONS.APAC:
    case REGIONS.DEFAULT_INTL:
      // These cases may get different formulas as workplace finds its life
      return usf * (1 + lossFactor);
    default:
      throw new Error('Girl your region is trash what are you doing');
  }
}

function getUSFForLocation(location, standardUSF) {
  return (getRegion(location) === REGIONS.NYC) ? standardUSF * nycUSFFactor : standardUSF;
}

function getNIAForLocation(location, usf, fitFactor) {
  const smallBOMArsf = usf * 1.15;
  return smallBOMArsf * (1 - fitFactor);
}

function getNIAInTsubo(usf) {
  return Math.round(unitsUtil.convertSqFeetToTsubo(usf) * (1 - 0.13));
}

function getRSFRange(rsf) {
  return {
    min: 0.95 * rsf,
    max: 1.15 * rsf
  };
}

// returns usf or rsf for given sf based on location and loss factor
function getAreaForContext(sf, sfType, location, lossFactor) {
  const usf = getUSFForLocation(location, sf);
  switch (sfType) {
    case (constants.calculator.SF_TYPE.USF):
      return usf;
    case (constants.calculator.SF_TYPE.RSF):
      return getRSFForLocation(location, usf, lossFactor);
    case (constants.calculator.SF_TYPE.NIA):
      return getNIAForLocation(location, sf, lossFactor);
    default:
      console.log('That SF Type does not exist, sorry');
      return null;
  }
}


module.exports = {
  nycUSFFactor,
  getRegion,
  getDefaultLossFactor,
  getRSFForLocation,
  getUSFForLocation,
  getRSFRange,
  getAreaForContext,
  getNIAInTsubo
};
