// Example plans all live in the Spacer Org
// https://plans.cbre.com/#/buildings/ce0f8339-3edd-4ea8-98a7-56ece59f643f

const EXAMPLE_PLAN_MAP = {
  link3D:{
    // Optimizer
    efficient: {
      partial_5000: '3ea29d01-3b80-40a9-b823-31a3ce6eb8b3',
      full_5000: '9158be14-1151-4123-af01-63bfc2684558',
      partial_10000: '075fb71e-3332-4572-867c-7902478f8445',
      full_10000: '29bb2619-4640-4b6b-8a26-650c9c78050f',
      partial_15000: '2d63e23b-6c06-4cc4-be9a-e1350d27dbe2',
      full_15000: '557c7a05-fc17-46c5-bfdc-351e9eed19af',
      partial_20000: '8a6ea685-4c26-4e15-87b3-49d7f9a75d7d',
      full_20000: '8a464537-4ddf-47b7-9f0e-d609eed27ff0'
    },

    // Accomodator
    whiteCollar: {
      partial_5000: '0c5aeae7-46cd-4ca9-81c4-f561b49ceb43',
      full_5000: 'd93201af-f42e-4979-839e-431177e831de',
      partial_10000: '748523db-2283-43fa-ba34-937bc355f28f',
      full_10000: '0e0a86c4-2307-4e2d-87a3-5506e5d12903',
      partial_15000: 'cd4409eb-d1e6-42b0-8862-0509fc6ba46e',
      full_15000: '308f1555-fce9-426a-a109-04fe87d2ea5a',
      partial_20000: '1739c9f5-4690-41d2-aaac-ccca5cdd9a25',
      full_20000: 'ef3cba37-2ce0-470f-abd4-f7d957ce7436'
    },

    // Enhancer
    whiteGlove: {
      partial_5000: 'b5b49e23-87f1-4189-8e66-7ace3d5e5a3a',
      full_5000: 'd8940f49-edfa-42a5-ab8f-75592f76e09b',
      partial_10000: '7053fca4-3d8e-42e9-96d6-bf73427f3e36',
      full_10000: '042be30d-5a26-4428-ae73-aa54803350e8',
      partial_15000: 'ecde8012-9155-4b25-b926-91f6a6edaaa2',
      full_15000: '05cfb734-befb-4dde-b595-566f3fc52598',
      partial_20000: '7e53541a-b937-4f82-ba19-f06cf9660293',
      full_20000: '8000d8fc-e4bb-47f4-8c50-d173a94d0b2d'
    },

    // Producer
    openOffice: {
      partial_5000: 'f87173d6-eb46-41b4-ad86-ed74f6b46d3d',
      full_5000: 'dd6f0a13-0942-4af9-8b64-a8ca83acf548',
      partial_10000: '2be799c2-e763-4591-a66c-0fe6f1f36d1c',
      full_10000: '18d241fb-98b1-4dda-9bcd-55b90103901c',
      partial_15000: '0d369132-8180-436a-a663-d3a1536de157',
      full_15000: '8e30c763-3810-4053-9c42-93cce2004130',
      partial_20000: '0c50f989-59db-4a7e-a834-21a8a4940267',
      full_20000: '1add934a-f4b6-4d65-8dc6-ba794941e945'
    },

    // Connector
    workplace360: {
      partial_5000: '17a60ace-196f-4f61-b2fe-c172edefd22f',
      full_5000: '4224309a-fb7c-42a5-a787-076bbfbf7014',
      partial_10000: 'fa10ea7e-cfa9-45c6-ba1f-f034d4e8c295',
      full_10000: 'e4bbdbac-11d8-4042-b698-8ec2c8964f7f',
      partial_15000: 'fbf2f530-f208-453a-9354-9e59174bdac5',
      full_15000: '5dba30b5-22c5-4b22-9264-3309969a55ef',
      partial_20000: 'c5526b33-2ed1-427b-8eda-e1db3dfa8922',
      full_20000: '83cffe4d-f5fb-432c-8b9c-9f6e24f3950e'
    },

    // Differentiator
    campus: {
      partial_5000: 'a81519ed-b8c9-4ff1-af48-ccbf91b11874',
      full_5000: '8b0bd036-d3f9-4a82-8d2e-207942c08fef',
      partial_10000: '64f67022-776d-4b82-8c60-f3b96cb1397c',
      full_10000: 'c9477a03-d1a2-41fc-b780-a2f867e20a67',
      partial_15000: 'a15a851c-3246-4bba-a21d-92fa0b6feac9',
      full_15000: '2ca2376e-6ac2-477c-b5b8-94bc281ad1a1',
      partial_20000: 'f21e1b71-4edc-4434-b24d-369308609913',
      full_20000: '0a94d188-0ba2-4703-85fa-a69375b74cd6'
    },

    // Incubator
    startup: {
      partial_5000: '3815376a-f3a7-45c4-a39e-fe20981f42d7',
      full_5000: 'fc53f387-71c2-4df5-b97f-dc9c90e01ad2',
      partial_10000: 'de9cdc62-14bc-44c2-b1bc-caf7b15e30ea',
      full_10000: 'bfb8b5e3-2585-467c-a4d4-d9611fa86a83',
      partial_15000: '34022700-7452-4ff8-9212-1c1ca5067700',
      full_15000: '34495fb9-0e96-4da1-8995-d55d5685efbb',
      partial_20000: 'a5d9e386-fa43-4e20-90fc-a09128a3c65b',
      full_20000: '24413a74-b82e-40f2-b7ce-d982e99ed6d1'
    },

    // Creator
    engCreative: {
      partial_5000: 'd3500a3c-aaf1-4a8f-91ec-d0798b05dd5e',
      full_5000: '90b495a5-ae68-411d-b935-5cec6e1f98bf',
      partial_10000: '5981faee-ab91-4a4c-9a38-8b45d6f4b7f8',
      full_10000: '033e3fa3-d275-4673-9d96-7d3d5b245bb9',
      partial_15000: '7d92c143-716c-48eb-bb5c-93db1f90e4cb',
      full_15000: 'cffb6ab7-9a12-42e1-b6cf-b1a6a4672e44',
      partial_20000: '4093f4a7-f6c5-4de6-b6e4-4a3191b828a9',
      full_20000: '451def70-0f19-4feb-aac7-4307dc927eb1'
    }
  },
  link2D:{
    // Optimizer
    efficient: {
      partial_5000: '3f7a6d47-d50a-4387-961c-c65d852d23ee',
      full_5000: '1e45b047-e453-4ec8-9a1b-2596e9a4ee2a',
      partial_10000: '6f42eede-840d-41af-9137-cb46074cf0ae',
      full_10000: 'fb6e562e-c6ce-402a-b068-1823004a7e24',
      partial_15000: 'c5792724-34fd-4561-8f21-7df78c87c883',
      full_15000: 'f07f5f89-bc87-4e7b-8a40-1dec7b379c9a',
      partial_20000: '3c1e6be9-1e17-458f-9fd3-c06a08fd6c68',
      full_20000: '759252f2-c734-469a-855e-76dbd5ee8f43'
    },

    // Accomodator
    whiteCollar: {
      partial_5000: '8a9ad240-9298-4aa6-a9db-37139bba6653',
      full_5000: '1d54f61f-826d-451f-89d6-f5f84ced0a09',
      partial_10000: 'dc7a33dc-caa7-45a0-aa36-cf2bcd1c4612',
      full_10000: '25971233-d708-445b-b1cf-74218652a24a',
      partial_15000: 'eeacc42e-8d3a-469b-a3c0-c05bfae59c4a',
      full_15000: 'c42862aa-4a99-4414-8bfa-18e4f5d98c2d',
      partial_20000: 'd0f183b4-7425-49cc-ae74-096ff44de493',
      full_20000: '89e01f33-cd9a-4ee4-a7f6-e556239a385f'
    },

    // Enhancer
    whiteGlove: {
      partial_5000: 'aae0878f-3868-470f-96df-92d563bbb0f1',
      full_5000: '6721c34c-52ca-4f9c-a4bb-26c1bf4a2d0a',
      partial_10000: '62b5a6c4-11b0-4683-b87b-e2cd87b2fbc0',
      full_10000: '0cd09b27-db9b-4ec7-9b41-14123b35a1f0',
      partial_15000: '6fb1e96a-9032-4648-b263-6613b209263b',
      full_15000: '30291fbc-71f1-440f-912b-5b6b6b4b721f',
      partial_20000: '76276dcb-97c8-44b2-b0d5-58992de5b747',
      full_20000: '648cd7f1-09e7-4d56-b406-0ffd0f901364'
    },

    // Producer
    openOffice: {
      partial_5000: '70e5e1fc-e515-4d55-9062-80f1f84e63a8',
      full_5000: 'd988a4ce-e296-42b2-a690-2c281eca19c0',
      partial_10000: '8812d1f1-5e35-4b3b-b06a-e98d5757804b',
      full_10000: 'c13c8c5f-a13e-463a-97e4-0ab324d950d0',
      partial_15000: 'ca611c44-2cb6-4d8e-bd33-07b08e5acccb',
      full_15000: '4fb48f93-6d9d-45bf-8426-325ad7bcc141',
      partial_20000: 'ba57d610-f0cb-4329-8dd1-73dfa410d51c',
      full_20000: '8e9a398c-94ef-4ed3-9aae-15ab7dd5594e'
    },

    // Connector
    workplace360: {
      partial_5000: 'c4048aa2-0f2e-458f-ac67-54c32c5a75e7',
      full_5000: '931ee6a4-ab44-46b3-9d3a-8425857a4daa',
      partial_10000: 'a9d70d02-faf4-48d1-80f2-fcc9264b8868',
      full_10000: '463d3070-d98c-4ae6-9e8c-842cdad5d39a',
      partial_15000: 'ca79f4dd-033e-4a04-a542-47ca2c79b19b',
      full_15000: 'd1847b5d-6255-4003-9344-dd7f1bf35a2e',
      partial_20000: 'f3e9e4d2-a3b6-4570-ba50-843451726cc9',
      full_20000: '86be8ad9-2bc9-401c-8c5b-160c16a59a07'
    },

    // Differentiator
    campus: {
      partial_5000: 'b64c7e7e-7f61-4556-bd3b-7d0612be1fb0',
      full_5000: '11e985f2-3bd0-492b-9f2c-f9324e3f567f',
      partial_10000: '40a10b84-f172-4a7e-aedc-dae2d9180f4c',
      full_10000: 'f43842ec-e3fd-4a1e-9689-b99aba76e9d0',
      partial_15000: 'f7075f35-f8e8-47a8-88e4-aaa14250ba21',
      full_15000: 'c77ea656-106c-40ff-a3c6-dcfcc9087648',
      partial_20000: 'ccccf4a4-31d3-4bd1-adcc-114abb214e1e',
      full_20000: '76cc6204-435c-45cb-8258-8c2865b9daca'
    },

    // Incubator
    startup: {
      partial_5000: '506c9a03-7d53-457d-8eaa-c2c29e7e4889',
      full_5000: '1ab93c17-644f-495c-a857-7254527562bc',
      partial_10000: '702200a9-4b62-407b-b283-99258717cafa',
      full_10000: '5804c1db-cea3-49f8-ae03-a9de919f382d',
      partial_15000: '9e611aac-75b4-4bc8-9d32-bae2f1a4b54e',
      full_15000: 'c81abd7b-90b0-464a-b5b3-ca7f8be3989e',
      partial_20000: 'f35c1c70-3d98-4cf0-8fa4-3aefa26e226a',
      full_20000: '855c9e5e-2dd2-48bb-aa94-03a5c25ea3fc'
    },

    // Creator
    engCreative: {
      partial_5000: 'c963d9d7-7e1f-4cf1-a760-d5d46c4734e3',
      full_5000: 'df4119fe-f484-4f0a-8e53-50e2a1c6166b',
      partial_10000: '273eddfc-c8ff-4734-a521-5d06732fa1ab',
      full_10000: '409ed9d3-aa66-49f2-9938-bff3618099c7',
      partial_15000: 'a3933f32-7a75-456b-94fb-8fa500703310',
      full_15000: '09853faa-0612-4819-a091-12642555c469',
      partial_20000: '1a57f542-829b-4bc7-8d89-78840f93e3e0',
      full_20000: 'acb6c89a-1bc7-49e4-9bad-3db81c6d744d'
    }
  }
};

module.exports = EXAMPLE_PLAN_MAP;
