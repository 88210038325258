const { CATEGORY } = require("wp-constants").shared.spaceUnit;

module.exports = {
  EFFICIENT: {
    // OPTIMIZER
    US: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "1", multiplier: 0.08 },
          { spaceUnitId: "3", multiplier: 0.88 },
          { spaceUnitId: "6", multiplier: 0.04 },
        ],
        [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 25 }],
        [CATEGORY.WE]: [
          { spaceUnitId: "7", multiplier: 0.2 },
          { spaceUnitId: "8", multiplier: 0.27 },
          { spaceUnitId: "9", multiplier: 0.26 },
          { spaceUnitId: "10", multiplier: 0.2 },
          { spaceUnitId: "11", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "15", multiplier: 3 },
          { spaceUnitId: "16", multiplier: 4 },
          { spaceUnitId: "17", multiplier: 1.2 },
          { spaceUnitId: "18", multiplier: 0.5 },
          { spaceUnitId: "19", multiplier: 0.63 },
          { spaceUnitId: "21", multiplier: 80 },
          { spaceUnitId: "22", multiplier: 1 },
          { spaceUnitId: "24", multiplier: 0.45 },
          { spaceUnitId: "30", multiplier: 1 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "25", multiplier: 0.5 },
          { spaceUnitId: "27", multiplier: 3.15 },
          { spaceUnitId: "28", multiplier: 2 },
          { spaceUnitId: "36", multiplier: 0.78 },
          { spaceUnitId: "37", multiplier: 1.4 },
        ],
      },
      SMALL: {
        [CATEGORY.ME]: [
          { spaceUnitId: "1", multiplier: 0.08 },
          { spaceUnitId: "3", multiplier: 0.88 },
          { spaceUnitId: "6", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "7", multiplier: 0.2 },
          { spaceUnitId: "8", multiplier: 0.27 },
          { spaceUnitId: "9", multiplier: 0.26 },
          { spaceUnitId: "10", multiplier: 0.2 },
          { spaceUnitId: "11", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "15", multiplier: 3 },
          { spaceUnitId: "16", multiplier: 4 },
          { spaceUnitId: "17", multiplier: 1.2 },
          { spaceUnitId: "18", multiplier: 0.5 },
          { spaceUnitId: "19", multiplier: 0.63 },
          { spaceUnitId: "21", multiplier: 80 },
          { spaceUnitId: "22", multiplier: 1 },
          { spaceUnitId: "24", multiplier: 0.45 },
        ],
        [CATEGORY.AMENITY]: [{ spaceUnitId: "28", multiplier: 2 }],
      },
    },
    UK: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "38", multiplier: 0.08 },
          { spaceUnitId: "40", multiplier: 0.88 },
          { spaceUnitId: "42", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "43", multiplier: 0.2 },
          { spaceUnitId: "44", multiplier: 0.27 },
          { spaceUnitId: "45", multiplier: 0.26 },
          { spaceUnitId: "46", multiplier: 0.2 },
          { spaceUnitId: "47", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "57", multiplier: 1.991324 },
          { spaceUnitId: "58", multiplier: 0.430557 },
          { spaceUnitId: "59", multiplier: 2.152783 },
          { spaceUnitId: "60", multiplier: 0.807294 },
          { spaceUnitId: "61", multiplier: 1.614587 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "50", multiplier: 0.968752 },
          { spaceUnitId: "51", multiplier: 1.614587 },
          { spaceUnitId: "52", multiplier: 5.381958 },
          { spaceUnitId: "53", multiplier: 3.229175 },
          { spaceUnitId: "54", multiplier: 4.921261 },
          { spaceUnitId: "55", multiplier: 0.656168 },
          { spaceUnitId: "56", multiplier: 1.614587 },
          { spaceUnitId: "62" },
          { spaceUnitId: "63" },
          { spaceUnitId: "64" },
          { spaceUnitId: "65" },
          { spaceUnitId: "66" },
          { spaceUnitId: "67" },
          { spaceUnitId: "68" },
        ],
      },
    },
    GERMANY: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "70", multiplier: 0.08 },
          { spaceUnitId: "72", multiplier: 0.88 },
          { spaceUnitId: "74", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "75", multiplier: 0.2 },
          { spaceUnitId: "76", multiplier: 0.27 },
          { spaceUnitId: "77", multiplier: 0.26 },
          { spaceUnitId: "78", multiplier: 0.2 },
          { spaceUnitId: "79", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "82", multiplier: 3.23 },
          { spaceUnitId: "83", multiplier: 3.23 },
          { spaceUnitId: "84", multiplier: 0.89 },
          { spaceUnitId: "85", multiplier: 2.15 },
          { spaceUnitId: "86", multiplier: 0.5 },
          { spaceUnitId: "87", multiplier: 2.15 },
          { spaceUnitId: "88", multiplier: 0.5 },
          { spaceUnitId: "89", multiplier: 4.31 },
          { spaceUnitId: "90", multiplier: 3.23 },
          { spaceUnitId: "91", multiplier: 2.15 },
          { spaceUnitId: "92", multiplier: 1.61 },
          { spaceUnitId: "93", multiplier: 2.15 },
          { spaceUnitId: "94" },
          { spaceUnitId: "95" },
          { spaceUnitId: "96" },
          { spaceUnitId: "97" },
          { spaceUnitId: "98" },
          { spaceUnitId: "99" },
          { spaceUnitId: "100" },
        ],
      },
    },
    SPAIN: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "148", multiplier: 0.04 },
          { spaceUnitId: "150", multiplier: 0.88 },
          { spaceUnitId: "152", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "153", multiplier: 0.2 },
          { spaceUnitId: "154", multiplier: 0.27 },
          { spaceUnitId: "155", multiplier: 0.26 },
          { spaceUnitId: "156", multiplier: 0.2 },
          { spaceUnitId: "157", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "160", multiplier: 0.98 },
          { spaceUnitId: "161", multiplier: 0.49 },
          { spaceUnitId: "162", multiplier: 0.49 },
          { spaceUnitId: "163", multiplier: 0.65 },
          { spaceUnitId: "164", multiplier: 0.33 },
          { spaceUnitId: "165", multiplier: 0.33 },
          { spaceUnitId: "166", multiplier: 0.98 },
          { spaceUnitId: "167", multiplier: 1.64 },
          { spaceUnitId: "168", multiplier: 0.5 },
          { spaceUnitId: "169", multiplier: 0.55 },
          { spaceUnitId: "170", multiplier: 0.65 },
          { spaceUnitId: "171" },
          { spaceUnitId: "172" },
          { spaceUnitId: "173" },
          { spaceUnitId: "174" },
          { spaceUnitId: "175" },
        ],
      },
    },
    FRANCE: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "176", multiplier: 0.08 },
          { spaceUnitId: "178", multiplier: 0.88 },
          { spaceUnitId: "180", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "181", multiplier: 0.2 },
          { spaceUnitId: "182", multiplier: 0.27 },
          { spaceUnitId: "183", multiplier: 0.26 },
          { spaceUnitId: "184", multiplier: 0.2 },
          { spaceUnitId: "185", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "188", multiplier: 0.98 },
          { spaceUnitId: "189", multiplier: 0.49 },
          { spaceUnitId: "190", multiplier: 0.66 },
          { spaceUnitId: "191", multiplier: 0.33 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "192", multiplier: 0.66 },
          { spaceUnitId: "193", multiplier: 0.33 },
          { spaceUnitId: "194", multiplier: 0.98 },
          { spaceUnitId: "195", multiplier: 1.64 },
          { spaceUnitId: "196", multiplier: 1.31 },
          { spaceUnitId: "197", multiplier: 0.57 },
          { spaceUnitId: "198" },
          { spaceUnitId: "199" },
          { spaceUnitId: "200" },
          { spaceUnitId: "201" },
          { spaceUnitId: "202" },
          { spaceUnitId: "203" },
          { spaceUnitId: "204" },
        ],
      },
    },
    APAC: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "101", multiplier: 0.01 },
          { spaceUnitId: "103", multiplier: 0.99 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "107", multiplier: 0.07 },
          { spaceUnitId: "108", multiplier: 0.1 },
          { spaceUnitId: "109", multiplier: 0.05 },
          { spaceUnitId: "111", multiplier: 0.18 },
          { spaceUnitId: "112", multiplier: 0.15 },
          { spaceUnitId: "113", multiplier: 0.2 },
          { spaceUnitId: "114", multiplier: 0.17 },
          { spaceUnitId: "115", multiplier: 0.05 },
          { spaceUnitId: "131", multiplier: 0.03 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "132", multiplier: 1.2 },
          { spaceUnitId: "133", multiplier: 0.15 },
          { spaceUnitId: "134", multiplier: 0.35 },
          { spaceUnitId: "135", multiplier: 0.4 },
          { spaceUnitId: "124", multiplier: 0.5 },
          { spaceUnitId: "125", multiplier: 0.4 },
          { spaceUnitId: "126", multiplier: 0.35 },
          { spaceUnitId: "136", multiplier: 2 },
          { spaceUnitId: "128", multiplier: 1 },
          { spaceUnitId: "137", multiplier: 0.35 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "139", multiplier: 0.1 },
          { spaceUnitId: "140", multiplier: 1.7 },
          { spaceUnitId: "142", multiplier: 3.5 },
          { spaceUnitId: "143", multiplier: 3 },
          { spaceUnitId: "144", multiplier: 0.1 },
          { spaceUnitId: "147", multiplier: 1.4 },
        ],
      },
    },
    SLOVAKIA: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "206", multiplier: 0.08 },
          { spaceUnitId: "208", multiplier: 0.88 },
          { spaceUnitId: "210", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "211", multiplier: 0.2 },
          { spaceUnitId: "212", multiplier: 0.27 },
          { spaceUnitId: "213", multiplier: 0.26 },
          { spaceUnitId: "214", multiplier: 0.2 },
          { spaceUnitId: "215", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "225", multiplier: 1.991324 },
          { spaceUnitId: "226", multiplier: 0.430557 },
          { spaceUnitId: "227", multiplier: 2.152783 },
          { spaceUnitId: "228", multiplier: 0.807294 },
          { spaceUnitId: "229", multiplier: 1.614587 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "218", multiplier: 0.968752 },
          { spaceUnitId: "219", multiplier: 1.614587 },
          { spaceUnitId: "220", multiplier: 5.381958 },
          { spaceUnitId: "221", multiplier: 3.229175 },
          { spaceUnitId: "222", multiplier: 4.921261 },
          { spaceUnitId: "223", multiplier: 0.656168 },
          { spaceUnitId: "224", multiplier: 1.614587 },
          { spaceUnitId: "230" },
          { spaceUnitId: "231" },
          { spaceUnitId: "232" },
          { spaceUnitId: "233" },
          { spaceUnitId: "234" },
          { spaceUnitId: "235" },
          { spaceUnitId: "236" },
        ],
      },
    },
    POLAND: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "238", multiplier: 0.08 },
          { spaceUnitId: "240", multiplier: 0.88 },
          { spaceUnitId: "242", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "243", multiplier: 0.2 },
          { spaceUnitId: "244", multiplier: 0.27 },
          { spaceUnitId: "245", multiplier: 0.26 },
          { spaceUnitId: "246", multiplier: 0.2 },
          { spaceUnitId: "247", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "257", multiplier: 1.991324 },
          { spaceUnitId: "258", multiplier: 0.430557 },
          { spaceUnitId: "259", multiplier: 2.152783 },
          { spaceUnitId: "260", multiplier: 0.807294 },
          { spaceUnitId: "261", multiplier: 1.614587 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "250", multiplier: 0.968752 },
          { spaceUnitId: "251", multiplier: 1.614587 },
          { spaceUnitId: "252", multiplier: 5.381958 },
          { spaceUnitId: "253", multiplier: 3.229175 },
          { spaceUnitId: "254", multiplier: 4.921261 },
          { spaceUnitId: "255", multiplier: 0.656168 },
          { spaceUnitId: "256", multiplier: 1.614587 },
          { spaceUnitId: "262" },
          { spaceUnitId: "263" },
          { spaceUnitId: "264" },
          { spaceUnitId: "265" },
          { spaceUnitId: "266" },
          { spaceUnitId: "267" },
          { spaceUnitId: "268" },
        ],
      },
    },
    HUNGARY: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "270", multiplier: 0.08 },
          { spaceUnitId: "272", multiplier: 0.88 },
          { spaceUnitId: "274", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "275", multiplier: 0.2 },
          { spaceUnitId: "276", multiplier: 0.27 },
          { spaceUnitId: "277", multiplier: 0.26 },
          { spaceUnitId: "278", multiplier: 0.2 },
          { spaceUnitId: "279", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "289", multiplier: 1.991324 },
          { spaceUnitId: "290", multiplier: 0.430557 },
          { spaceUnitId: "291", multiplier: 2.152783 },
          { spaceUnitId: "292", multiplier: 0.807294 },
          { spaceUnitId: "293", multiplier: 1.614587 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "282", multiplier: 0.968752 },
          { spaceUnitId: "283", multiplier: 1.614587 },
          { spaceUnitId: "284", multiplier: 5.381958 },
          { spaceUnitId: "285", multiplier: 3.229175 },
          { spaceUnitId: "286", multiplier: 4.921261 },
          { spaceUnitId: "287", multiplier: 0.656168 },
          { spaceUnitId: "288", multiplier: 1.614587 },
          { spaceUnitId: "294" },
          { spaceUnitId: "295" },
          { spaceUnitId: "296" },
          { spaceUnitId: "297" },
          { spaceUnitId: "298" },
          { spaceUnitId: "299" },
          { spaceUnitId: "300" },
        ],
      },
    },
    NETHERLAND: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "334", multiplier: 0.08 },
          { spaceUnitId: "336", multiplier: 0.88 },
          { spaceUnitId: "338", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "339", multiplier: 0.2 },
          { spaceUnitId: "340", multiplier: 0.27 },
          { spaceUnitId: "341", multiplier: 0.26 },
          { spaceUnitId: "342", multiplier: 0.2 },
          { spaceUnitId: "343", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "355", multiplier: 0.67 },
          { spaceUnitId: "356", multiplier: 2.15 },
          { spaceUnitId: "357", multiplier: 0.5 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "353", multiplier: 1.62 },
          { spaceUnitId: "354", multiplier: 3.23 },
          { spaceUnitId: "346", multiplier: 0.5 },
          { spaceUnitId: "347", multiplier: 0.5 },
          { spaceUnitId: "348", multiplier: 4.31 },
          { spaceUnitId: "349", multiplier: 14.267 },
          { spaceUnitId: "350", multiplier: 1.07 },
          { spaceUnitId: "351", multiplier: 0.67 },
          { spaceUnitId: "352", multiplier: 2.15 },
          { spaceUnitId: "358" },
          { spaceUnitId: "359" },
          { spaceUnitId: "360" },
          { spaceUnitId: "361" },
          { spaceUnitId: "362" },
          { spaceUnitId: "363" },
          { spaceUnitId: "364" },
        ],
      },
    },
    COLOMBIA: {
      REGULAR: {
        [CATEGORY.ME]: [
          { spaceUnitId: "302", multiplier: 0.08 },
          { spaceUnitId: "304", multiplier: 0.88 },
          { spaceUnitId: "306", multiplier: 0.04 },
        ],
        [CATEGORY.WE]: [
          { spaceUnitId: "307", multiplier: 0.2 },
          { spaceUnitId: "308", multiplier: 0.27 },
          { spaceUnitId: "309", multiplier: 0.26 },
          { spaceUnitId: "310", multiplier: 0.2 },
          { spaceUnitId: "311", multiplier: 0.07 },
        ],
        [CATEGORY.SUPPORT]: [
          { spaceUnitId: "321", multiplier: 1.991324 },
          { spaceUnitId: "322", multiplier: 0.430557 },
          { spaceUnitId: "323", multiplier: 2.152783 },
          { spaceUnitId: "324", multiplier: 0.807294 },
          { spaceUnitId: "325", multiplier: 1.614587 },
        ],
        [CATEGORY.AMENITY]: [
          { spaceUnitId: "314", multiplier: 0.968752 },
          { spaceUnitId: "315", multiplier: 1.614587 },
          { spaceUnitId: "316", multiplier: 5.381958 },
          { spaceUnitId: "317", multiplier: 3.229175 },
          { spaceUnitId: "318", multiplier: 4.921261 },
          { spaceUnitId: "319", multiplier: 0.656168 },
          { spaceUnitId: "320", multiplier: 1.614587 },
          { spaceUnitId: "326" },
          { spaceUnitId: "327" },
          { spaceUnitId: "328" },
          { spaceUnitId: "329" },
          { spaceUnitId: "330" },
          { spaceUnitId: "331" },
          { spaceUnitId: "332" },
          { spaceUnitId: "333", multiplier: 12.18 },
        ],
      },
    },
  },
    WHITE_COLLAR: {
      // ACCOMODATOR
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "2", multiplier: 0.05 },
            { spaceUnitId: "4", multiplier: 0.8 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 20 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.17 },
            { spaceUnitId: "8", multiplier: 0.2 },
            { spaceUnitId: "9", multiplier: 0.26 },
            { spaceUnitId: "10", multiplier: 0.26 },
            { spaceUnitId: "11", multiplier: 0.1 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "26", multiplier: 2.5 },
            { spaceUnitId: "27", multiplier: 3.15 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "33", multiplier: 1 },
            { spaceUnitId: "36", multiplier: 0.78 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "2", multiplier: 0.05 },
            { spaceUnitId: "4", multiplier: 0.8 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.17 },
            { spaceUnitId: "8", multiplier: 0.31 },
            { spaceUnitId: "9", multiplier: 0.36 },
            { spaceUnitId: "10", multiplier: 0.16 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [{ spaceUnitId: "28", multiplier: 2.5 }],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.1 },
            { spaceUnitId: "39", multiplier: 0.05 },
            { spaceUnitId: "41", multiplier: 0.8 },
            { spaceUnitId: "42", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.17 },
            { spaceUnitId: "44", multiplier: 0.2 },
            { spaceUnitId: "45", multiplier: 0.26 },
            { spaceUnitId: "46", multiplier: 0.26 },
            { spaceUnitId: "47", multiplier: 0.1 },
            { spaceUnitId: "48", multiplier: 0.04 },
            { spaceUnitId: "49", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.1 },
            { spaceUnitId: "71", multiplier: 0.05 },
            { spaceUnitId: "73", multiplier: 0.8 },
            { spaceUnitId: "74", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.17 },
            { spaceUnitId: "76", multiplier: 0.2 },
            { spaceUnitId: "77", multiplier: 0.26 },
            { spaceUnitId: "78", multiplier: 0.26 },
            { spaceUnitId: "79", multiplier: 0.1 },
            { spaceUnitId: "80", multiplier: 0.04 },
            { spaceUnitId: "81", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.06 },
            { spaceUnitId: "149", multiplier: 0.01 },
            { spaceUnitId: "151", multiplier: 0.8 },
            { spaceUnitId: "152", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.17 },
            { spaceUnitId: "154", multiplier: 0.2 },
            { spaceUnitId: "155", multiplier: 0.26 },
            { spaceUnitId: "156", multiplier: 0.26 },
            { spaceUnitId: "157", multiplier: 0.1 },
            { spaceUnitId: "158", multiplier: 0.04 },
            { spaceUnitId: "159", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.1 },
            { spaceUnitId: "177", multiplier: 0.05 },
            { spaceUnitId: "179", multiplier: 0.8 },
            { spaceUnitId: "180", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.17 },
            { spaceUnitId: "182", multiplier: 0.2 },
            { spaceUnitId: "183", multiplier: 0.26 },
            { spaceUnitId: "184", multiplier: 0.26 },
            { spaceUnitId: "185", multiplier: 0.1 },
            { spaceUnitId: "186", multiplier: 0.04 },
            { spaceUnitId: "187", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        }
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.01 },
            { spaceUnitId: "102", multiplier: 0.01 },
            { spaceUnitId: "104", multiplier: 0.98 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.1 },
            { spaceUnitId: "108", multiplier: 0.15 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "111", multiplier: 0.09 },
            { spaceUnitId: "112", multiplier: 0.12 },
            { spaceUnitId: "113", multiplier: 0.17 },
            { spaceUnitId: "114", multiplier: 0.16 },
            { spaceUnitId: "115", multiplier: 0.06 },
            { spaceUnitId: "131", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 1 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "134", multiplier: 0.5 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.63 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.75 },
            { spaceUnitId: "136", multiplier: 2.5 },
            { spaceUnitId: "128", multiplier: 1 },
            { spaceUnitId: "137", multiplier: 0.35 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "140", multiplier: 1.7 },
            { spaceUnitId: "142", multiplier: 4.5 },
            { spaceUnitId: "143", multiplier: 2.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        }
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.1 },
            { spaceUnitId: "207", multiplier: 0.05 },
            { spaceUnitId: "209", multiplier: 0.8 },
            { spaceUnitId: "210", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.17 },
            { spaceUnitId: "212", multiplier: 0.2 },
            { spaceUnitId: "213", multiplier: 0.26 },
            { spaceUnitId: "214", multiplier: 0.26 },
            { spaceUnitId: "215", multiplier: 0.1 },
            { spaceUnitId: "216", multiplier: 0.04 },
            { spaceUnitId: "217", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.1 },
            { spaceUnitId: "239", multiplier: 0.05 },
            { spaceUnitId: "241", multiplier: 0.8 },
            { spaceUnitId: "242", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.17 },
            { spaceUnitId: "244", multiplier: 0.2 },
            { spaceUnitId: "245", multiplier: 0.26 },
            { spaceUnitId: "246", multiplier: 0.26 },
            { spaceUnitId: "247", multiplier: 0.1 },
            { spaceUnitId: "248", multiplier: 0.04 },
            { spaceUnitId: "249", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.1 },
            { spaceUnitId: "271", multiplier: 0.05 },
            { spaceUnitId: "273", multiplier: 0.8 },
            { spaceUnitId: "274", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.17 },
            { spaceUnitId: "276", multiplier: 0.2 },
            { spaceUnitId: "277", multiplier: 0.26 },
            { spaceUnitId: "278", multiplier: 0.26 },
            { spaceUnitId: "279", multiplier: 0.1 },
            { spaceUnitId: "280", multiplier: 0.04 },
            { spaceUnitId: "281", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.1 },
            { spaceUnitId: "335", multiplier: 0.05 },
            { spaceUnitId: "337", multiplier: 0.8 },
            { spaceUnitId: "338", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.17 },
            { spaceUnitId: "340", multiplier: 0.2 },
            { spaceUnitId: "341", multiplier: 0.26 },
            { spaceUnitId: "342", multiplier: 0.26 },
            { spaceUnitId: "343", multiplier: 0.1 },
            { spaceUnitId: "344", multiplier: 0.04 },
            { spaceUnitId: "345", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        }
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.1 },
            { spaceUnitId: "303", multiplier: 0.05 },
            { spaceUnitId: "305", multiplier: 0.8 },
            { spaceUnitId: "306", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.17 },
            { spaceUnitId: "308", multiplier: 0.2 },
            { spaceUnitId: "309", multiplier: 0.26 },
            { spaceUnitId: "310", multiplier: 0.26 },
            { spaceUnitId: "311", multiplier: 0.1 },
            { spaceUnitId: "312", multiplier: 0.04 },
            { spaceUnitId: "313", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        }
      }
    },
    WHITE_GLOVE: {
      // ENHANCER
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.25 },
            { spaceUnitId: "2", multiplier: 0.15 },
            { spaceUnitId: "4", multiplier: 0.55 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 20 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.15 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.25 },
            { spaceUnitId: "10", multiplier: 0.2 },
            { spaceUnitId: "11", multiplier: 0.15 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "26", multiplier: 2.5 },
            { spaceUnitId: "27", multiplier: 4.5 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "29", multiplier: 0.8 },
            { spaceUnitId: "69", multiplier: 12.18 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.25 },
            { spaceUnitId: "2", multiplier: 0.15 },
            { spaceUnitId: "4", multiplier: 0.55 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.05 },
            { spaceUnitId: "8", multiplier: 0.2 },
            { spaceUnitId: "9", multiplier: 0.35 },
            { spaceUnitId: "10", multiplier: 0.4 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "29", multiplier: 0.8 },
            { spaceUnitId: "33", multiplier: 1 },
          ],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.25 },
            { spaceUnitId: "39", multiplier: 0.15 },
            { spaceUnitId: "41", multiplier: 0.55 },
            { spaceUnitId: "42", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.15 },
            { spaceUnitId: "44", multiplier: 0.25 },
            { spaceUnitId: "45", multiplier: 0.25 },
            { spaceUnitId: "46", multiplier: 0.2 },
            { spaceUnitId: "47", multiplier: 0.15 },
            { spaceUnitId: "48", multiplier: 0.04 },
            { spaceUnitId: "49", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.25 },
            { spaceUnitId: "71", multiplier: 0.15 },
            { spaceUnitId: "73", multiplier: 0.55 },
            { spaceUnitId: "74", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.15 },
            { spaceUnitId: "76", multiplier: 0.25 },
            { spaceUnitId: "77", multiplier: 0.25 },
            { spaceUnitId: "78", multiplier: 0.2 },
            { spaceUnitId: "79", multiplier: 0.15 },
            { spaceUnitId: "80", multiplier: 0.04 },
            { spaceUnitId: "81", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.06 },
            { spaceUnitId: "149", multiplier: 0.02 },
            { spaceUnitId: "151", multiplier: 0.55 },
            { spaceUnitId: "152", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.15 },
            { spaceUnitId: "154", multiplier: 0.25 },
            { spaceUnitId: "155", multiplier: 0.25 },
            { spaceUnitId: "156", multiplier: 0.2 },
            { spaceUnitId: "157", multiplier: 0.15 },
            { spaceUnitId: "158", multiplier: 0.04 },
            { spaceUnitId: "159", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.25 },
            { spaceUnitId: "177", multiplier: 0.15 },
            { spaceUnitId: "179", multiplier: 0.55 },
            { spaceUnitId: "180", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.15 },
            { spaceUnitId: "182", multiplier: 0.25 },
            { spaceUnitId: "183", multiplier: 0.25 },
            { spaceUnitId: "184", multiplier: 0.2 },
            { spaceUnitId: "185", multiplier: 0.15 },
            { spaceUnitId: "186", multiplier: 0.04 },
            { spaceUnitId: "187", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.15 },
            { spaceUnitId: "102", multiplier: 0.1 },
            { spaceUnitId: "104", multiplier: 0.75 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.08 },
            { spaceUnitId: "108", multiplier: 0.15 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "110", multiplier: 0.02 },
            { spaceUnitId: "111", multiplier: 0.11 },
            { spaceUnitId: "112", multiplier: 0.08 },
            { spaceUnitId: "113", multiplier: 0.16 },
            { spaceUnitId: "114", multiplier: 0.15 },
            { spaceUnitId: "115", multiplier: 0.08 },
            { spaceUnitId: "131", multiplier: 0.07 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 2 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "134", multiplier: 1.2 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.63 },
            { spaceUnitId: "125", multiplier: 0.6 },
            { spaceUnitId: "126", multiplier: 1.5 },
            { spaceUnitId: "136", multiplier: 6 },
            { spaceUnitId: "128", multiplier: 1.5 },
            { spaceUnitId: "137", multiplier: 0.35 },
            { spaceUnitId: "138", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "141", multiplier: 3.5 },
            { spaceUnitId: "142", multiplier: 6 },
            { spaceUnitId: "143", multiplier: 5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "145", multiplier: 0.8 },
            { spaceUnitId: "146", multiplier: 1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.25 },
            { spaceUnitId: "207", multiplier: 0.15 },
            { spaceUnitId: "209", multiplier: 0.55 },
            { spaceUnitId: "210", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.15 },
            { spaceUnitId: "212", multiplier: 0.25 },
            { spaceUnitId: "213", multiplier: 0.25 },
            { spaceUnitId: "214", multiplier: 0.2 },
            { spaceUnitId: "215", multiplier: 0.15 },
            { spaceUnitId: "216", multiplier: 0.04 },
            { spaceUnitId: "217", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.25 },
            { spaceUnitId: "239", multiplier: 0.15 },
            { spaceUnitId: "241", multiplier: 0.55 },
            { spaceUnitId: "242", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.15 },
            { spaceUnitId: "244", multiplier: 0.25 },
            { spaceUnitId: "245", multiplier: 0.25 },
            { spaceUnitId: "246", multiplier: 0.2 },
            { spaceUnitId: "247", multiplier: 0.15 },
            { spaceUnitId: "248", multiplier: 0.04 },
            { spaceUnitId: "249", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.25 },
            { spaceUnitId: "271", multiplier: 0.15 },
            { spaceUnitId: "273", multiplier: 0.55 },
            { spaceUnitId: "274", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.15 },
            { spaceUnitId: "276", multiplier: 0.25 },
            { spaceUnitId: "277", multiplier: 0.25 },
            { spaceUnitId: "278", multiplier: 0.2 },
            { spaceUnitId: "279", multiplier: 0.15 },
            { spaceUnitId: "280", multiplier: 0.04 },
            { spaceUnitId: "281", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.25 },
            { spaceUnitId: "335", multiplier: 0.15 },
            { spaceUnitId: "337", multiplier: 0.55 },
            { spaceUnitId: "338", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.15 },
            { spaceUnitId: "340", multiplier: 0.25 },
            { spaceUnitId: "341", multiplier: 0.25 },
            { spaceUnitId: "342", multiplier: 0.2 },
            { spaceUnitId: "343", multiplier: 0.15 },
            { spaceUnitId: "344", multiplier: 0.04 },
            { spaceUnitId: "345", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.25 },
            { spaceUnitId: "303", multiplier: 0.15 },
            { spaceUnitId: "305", multiplier: 0.55 },
            { spaceUnitId: "306", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.15 },
            { spaceUnitId: "308", multiplier: 0.25 },
            { spaceUnitId: "309", multiplier: 0.25 },
            { spaceUnitId: "310", multiplier: 0.2 },
            { spaceUnitId: "311", multiplier: 0.15 },
            { spaceUnitId: "312", multiplier: 0.04 },
            { spaceUnitId: "313", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        },
      },
    },
    OPEN_OFFICE: {
      // PRODUCER
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.07 },
            { spaceUnitId: "2", multiplier: 0.02 },
            { spaceUnitId: "3", multiplier: 0.86 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 20 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.15 },
            { spaceUnitId: "8", multiplier: 0.3 },
            { spaceUnitId: "9", multiplier: 0.28 },
            { spaceUnitId: "10", multiplier: 0.19 },
            { spaceUnitId: "11", multiplier: 0.08 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "23", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "27", multiplier: 4.5 },
            { spaceUnitId: "28", multiplier: 2 },
            { spaceUnitId: "36", multiplier: 0.78 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.07 },
            { spaceUnitId: "2", multiplier: 0.02 },
            { spaceUnitId: "3", multiplier: 0.86 },
            { spaceUnitId: "6", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.15 },
            { spaceUnitId: "8", multiplier: 0.3 },
            { spaceUnitId: "9", multiplier: 0.28 },
            { spaceUnitId: "10", multiplier: 0.19 },
            { spaceUnitId: "11", multiplier: 0.08 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.03 },
            { spaceUnitId: "14", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "23", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [{ spaceUnitId: "28", multiplier: 2 }],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.07 },
            { spaceUnitId: "39", multiplier: 0.02 },
            { spaceUnitId: "40", multiplier: 0.86 },
            { spaceUnitId: "42", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.15 },
            { spaceUnitId: "44", multiplier: 0.3 },
            { spaceUnitId: "45", multiplier: 0.28 },
            { spaceUnitId: "46", multiplier: 0.19 },
            { spaceUnitId: "47", multiplier: 0.08 },
            { spaceUnitId: "48", multiplier: 0.04 },
            { spaceUnitId: "49", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.07 },
            { spaceUnitId: "71", multiplier: 0.02 },
            { spaceUnitId: "72", multiplier: 0.86 },
            { spaceUnitId: "74", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.15 },
            { spaceUnitId: "76", multiplier: 0.3 },
            { spaceUnitId: "77", multiplier: 0.28 },
            { spaceUnitId: "78", multiplier: 0.19 },
            { spaceUnitId: "79", multiplier: 0.08 },
            { spaceUnitId: "80", multiplier: 0.04 },
            { spaceUnitId: "81", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.04 },
            { spaceUnitId: "149", multiplier: 0.01 },
            { spaceUnitId: "150", multiplier: 0.86 },
            { spaceUnitId: "152", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.15 },
            { spaceUnitId: "154", multiplier: 0.3 },
            { spaceUnitId: "155", multiplier: 0.28 },
            { spaceUnitId: "156", multiplier: 0.19 },
            { spaceUnitId: "157", multiplier: 0.08 },
            { spaceUnitId: "158", multiplier: 0.04 },
            { spaceUnitId: "159", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.07 },
            { spaceUnitId: "177", multiplier: 0.02 },
            { spaceUnitId: "178", multiplier: 0.86 },
            { spaceUnitId: "180", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.15 },
            { spaceUnitId: "182", multiplier: 0.3 },
            { spaceUnitId: "183", multiplier: 0.28 },
            { spaceUnitId: "184", multiplier: 0.19 },
            { spaceUnitId: "185", multiplier: 0.08 },
            { spaceUnitId: "186", multiplier: 0.04 },
            { spaceUnitId: "187", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.01 },
            { spaceUnitId: "103", multiplier: 0.99 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.08 },
            { spaceUnitId: "108", multiplier: 0.12 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "110", multiplier: 0.1 },
            { spaceUnitId: "111", multiplier: 0.12 },
            { spaceUnitId: "112", multiplier: 0.05 },
            { spaceUnitId: "113", multiplier: 0.18 },
            { spaceUnitId: "114", multiplier: 0.19 },
            { spaceUnitId: "115", multiplier: 0.04 },
            { spaceUnitId: "131", multiplier: 0.02 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 1 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "134", multiplier: 0.3 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.63 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.2 },
            { spaceUnitId: "136", multiplier: 1 },
            { spaceUnitId: "128", multiplier: 0.6 },
            { spaceUnitId: "137", multiplier: 0.35 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "140", multiplier: 1.7 },
            { spaceUnitId: "142", multiplier: 4.5 },
            { spaceUnitId: "143", multiplier: 1.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.07 },
            { spaceUnitId: "207", multiplier: 0.02 },
            { spaceUnitId: "208", multiplier: 0.86 },
            { spaceUnitId: "210", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.15 },
            { spaceUnitId: "212", multiplier: 0.3 },
            { spaceUnitId: "213", multiplier: 0.28 },
            { spaceUnitId: "214", multiplier: 0.19 },
            { spaceUnitId: "215", multiplier: 0.08 },
            { spaceUnitId: "216", multiplier: 0.04 },
            { spaceUnitId: "217", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.07 },
            { spaceUnitId: "239", multiplier: 0.02 },
            { spaceUnitId: "240", multiplier: 0.86 },
            { spaceUnitId: "242", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.15 },
            { spaceUnitId: "244", multiplier: 0.3 },
            { spaceUnitId: "245", multiplier: 0.28 },
            { spaceUnitId: "246", multiplier: 0.19 },
            { spaceUnitId: "247", multiplier: 0.08 },
            { spaceUnitId: "248", multiplier: 0.04 },
            { spaceUnitId: "249", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.07 },
            { spaceUnitId: "271", multiplier: 0.02 },
            { spaceUnitId: "272", multiplier: 0.86 },
            { spaceUnitId: "274", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.15 },
            { spaceUnitId: "276", multiplier: 0.3 },
            { spaceUnitId: "277", multiplier: 0.28 },
            { spaceUnitId: "278", multiplier: 0.19 },
            { spaceUnitId: "279", multiplier: 0.08 },
            { spaceUnitId: "280", multiplier: 0.04 },
            { spaceUnitId: "281", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.07 },
            { spaceUnitId: "335", multiplier: 0.02 },
            { spaceUnitId: "336", multiplier: 0.86 },
            { spaceUnitId: "338", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.15 },
            { spaceUnitId: "340", multiplier: 0.3 },
            { spaceUnitId: "341", multiplier: 0.28 },
            { spaceUnitId: "342", multiplier: 0.19 },
            { spaceUnitId: "343", multiplier: 0.08 },
            { spaceUnitId: "344", multiplier: 0.04 },
            { spaceUnitId: "345", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.07 },
            { spaceUnitId: "303", multiplier: 0.02 },
            { spaceUnitId: "305", multiplier: 0.86 },
            { spaceUnitId: "306", multiplier: 0.05 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.15 },
            { spaceUnitId: "308", multiplier: 0.3 },
            { spaceUnitId: "309", multiplier: 0.28 },
            { spaceUnitId: "310", multiplier: 0.19 },
            { spaceUnitId: "311", multiplier: 0.08 },
            { spaceUnitId: "312", multiplier: 0.04 },
            { spaceUnitId: "313", multiplier: 0.03 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        },
      },
    },
    WORKPLACE_360: {
      // CONNECTOR
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.08 },
            { spaceUnitId: "3", multiplier: 0.84 },
            { spaceUnitId: "6", multiplier: 0.08 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 12.5 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.25 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.23 },
            { spaceUnitId: "10", multiplier: 0.2 },
            { spaceUnitId: "11", multiplier: 0.07 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.04 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "26", multiplier: 2.5 },
            { spaceUnitId: "27", multiplier: 4.5 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "33", multiplier: 1 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.08 },
            { spaceUnitId: "3", multiplier: 0.84 },
            { spaceUnitId: "6", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.25 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.23 },
            { spaceUnitId: "10", multiplier: 0.2 },
            { spaceUnitId: "11", multiplier: 0.07 },
            { spaceUnitId: "12", multiplier: 0.04 },
            { spaceUnitId: "13", multiplier: 0.04 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "33", multiplier: 1 },
          ],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.08 },
            { spaceUnitId: "40", multiplier: 0.84 },
            { spaceUnitId: "42", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.25 },
            { spaceUnitId: "44", multiplier: 0.25 },
            { spaceUnitId: "45", multiplier: 0.23 },
            { spaceUnitId: "46", multiplier: 0.2 },
            { spaceUnitId: "47", multiplier: 0.07 },
            { spaceUnitId: "48", multiplier: 0.04 },
            { spaceUnitId: "49", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.08 },
            { spaceUnitId: "72", multiplier: 0.84 },
            { spaceUnitId: "74", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.25 },
            { spaceUnitId: "76", multiplier: 0.25 },
            { spaceUnitId: "77", multiplier: 0.23 },
            { spaceUnitId: "78", multiplier: 0.2 },
            { spaceUnitId: "79", multiplier: 0.07 },
            { spaceUnitId: "80", multiplier: 0.04 },
            { spaceUnitId: "81", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.04 },
            { spaceUnitId: "150", multiplier: 0.84 },
            { spaceUnitId: "152", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.25 },
            { spaceUnitId: "154", multiplier: 0.25 },
            { spaceUnitId: "155", multiplier: 0.23 },
            { spaceUnitId: "156", multiplier: 0.2 },
            { spaceUnitId: "157", multiplier: 0.07 },
            { spaceUnitId: "158", multiplier: 0.04 },
            { spaceUnitId: "159", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.08 },
            { spaceUnitId: "178", multiplier: 0.84 },
            { spaceUnitId: "180", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.25 },
            { spaceUnitId: "182", multiplier: 0.25 },
            { spaceUnitId: "183", multiplier: 0.23 },
            { spaceUnitId: "184", multiplier: 0.2 },
            { spaceUnitId: "185", multiplier: 0.07 },
            { spaceUnitId: "186", multiplier: 0.04 },
            { spaceUnitId: "187", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [{ spaceUnitId: "103", multiplier: 1 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.12 },
            { spaceUnitId: "108", multiplier: 0.13 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "110", multiplier: 0.08 },
            { spaceUnitId: "111", multiplier: 0.09 },
            { spaceUnitId: "112", multiplier: 0.12 },
            { spaceUnitId: "113", multiplier: 0.15 },
            { spaceUnitId: "114", multiplier: 0.1 },
            { spaceUnitId: "115", multiplier: 0.03 },
            { spaceUnitId: "131", multiplier: 0.08 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 1 },
            { spaceUnitId: "133", multiplier: 0.25 },
            { spaceUnitId: "134", multiplier: 0.5 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.63 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.5 },
            { spaceUnitId: "136", multiplier: 2.5 },
            { spaceUnitId: "128", multiplier: 1 },
            { spaceUnitId: "137", multiplier: 0.35 },
            { spaceUnitId: "138", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "141", multiplier: 2.2 },
            { spaceUnitId: "142", multiplier: 5.5 },
            { spaceUnitId: "143", multiplier: 2.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "146", multiplier: 1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.08 },
            { spaceUnitId: "208", multiplier: 0.84 },
            { spaceUnitId: "210", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.25 },
            { spaceUnitId: "212", multiplier: 0.25 },
            { spaceUnitId: "213", multiplier: 0.23 },
            { spaceUnitId: "214", multiplier: 0.2 },
            { spaceUnitId: "215", multiplier: 0.07 },
            { spaceUnitId: "216", multiplier: 0.04 },
            { spaceUnitId: "217", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.08 },
            { spaceUnitId: "240", multiplier: 0.84 },
            { spaceUnitId: "242", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.25 },
            { spaceUnitId: "244", multiplier: 0.25 },
            { spaceUnitId: "245", multiplier: 0.23 },
            { spaceUnitId: "246", multiplier: 0.2 },
            { spaceUnitId: "247", multiplier: 0.07 },
            { spaceUnitId: "248", multiplier: 0.04 },
            { spaceUnitId: "249", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.08 },
            { spaceUnitId: "272", multiplier: 0.84 },
            { spaceUnitId: "274", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.25 },
            { spaceUnitId: "276", multiplier: 0.25 },
            { spaceUnitId: "277", multiplier: 0.23 },
            { spaceUnitId: "278", multiplier: 0.2 },
            { spaceUnitId: "279", multiplier: 0.07 },
            { spaceUnitId: "280", multiplier: 0.04 },
            { spaceUnitId: "281", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.08 },
            { spaceUnitId: "336", multiplier: 0.84 },
            { spaceUnitId: "338", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.25 },
            { spaceUnitId: "340", multiplier: 0.25 },
            { spaceUnitId: "341", multiplier: 0.23 },
            { spaceUnitId: "342", multiplier: 0.2 },
            { spaceUnitId: "343", multiplier: 0.07 },
            { spaceUnitId: "344", multiplier: 0.04 },
            { spaceUnitId: "345", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.08 },
            { spaceUnitId: "304", multiplier: 0.84 },
            { spaceUnitId: "306", multiplier: 0.08 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.25 },
            { spaceUnitId: "308", multiplier: 0.25 },
            { spaceUnitId: "309", multiplier: 0.23 },
            { spaceUnitId: "310", multiplier: 0.2 },
            { spaceUnitId: "311", multiplier: 0.07 },
            { spaceUnitId: "312", multiplier: 0.04 },
            { spaceUnitId: "313", multiplier: 0.04 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        },
      },
    },
    CAMPUS: {
      // DIFFERENTIATOR
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "3", multiplier: 0.8 },
            { spaceUnitId: "6", multiplier: 0.1 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 10 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.2 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.23 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "11", multiplier: 0.07 },
            { spaceUnitId: "12", multiplier: 0.02 },
            { spaceUnitId: "13", multiplier: 0.06 },
            { spaceUnitId: "14", multiplier: 0.07 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "26", multiplier: 2.5 },
            { spaceUnitId: "27", multiplier: 4.5 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "33", multiplier: 1 },
            { spaceUnitId: "69", multiplier: 12.18 },
            { spaceUnitId: "36", multiplier: 0.78 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "3", multiplier: 0.8 },
            { spaceUnitId: "6", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.2 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.3 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "12", multiplier: 0.02 },
            { spaceUnitId: "13", multiplier: 0.06 },
            { spaceUnitId: "14", multiplier: 0.07 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "20", multiplier: 1.5 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "28", multiplier: 2.5 },
            { spaceUnitId: "33", multiplier: 1 },
          ],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.1 },
            { spaceUnitId: "40", multiplier: 0.8 },
            { spaceUnitId: "42", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.2 },
            { spaceUnitId: "44", multiplier: 0.25 },
            { spaceUnitId: "45", multiplier: 0.23 },
            { spaceUnitId: "46", multiplier: 0.25 },
            { spaceUnitId: "47", multiplier: 0.07 },
            { spaceUnitId: "48", multiplier: 0.02 },
            { spaceUnitId: "49", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.1 },
            { spaceUnitId: "72", multiplier: 0.8 },
            { spaceUnitId: "74", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.2 },
            { spaceUnitId: "76", multiplier: 0.25 },
            { spaceUnitId: "77", multiplier: 0.23 },
            { spaceUnitId: "78", multiplier: 0.25 },
            { spaceUnitId: "79", multiplier: 0.07 },
            { spaceUnitId: "80", multiplier: 0.02 },
            { spaceUnitId: "81", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.06 },
            { spaceUnitId: "150", multiplier: 0.8 },
            { spaceUnitId: "152", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.2 },
            { spaceUnitId: "154", multiplier: 0.25 },
            { spaceUnitId: "155", multiplier: 0.23 },
            { spaceUnitId: "156", multiplier: 0.25 },
            { spaceUnitId: "157", multiplier: 0.07 },
            { spaceUnitId: "158", multiplier: 0.02 },
            { spaceUnitId: "159", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.1 },
            { spaceUnitId: "178", multiplier: 0.8 },
            { spaceUnitId: "180", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.2 },
            { spaceUnitId: "182", multiplier: 0.25 },
            { spaceUnitId: "183", multiplier: 0.23 },
            { spaceUnitId: "184", multiplier: 0.25 },
            { spaceUnitId: "185", multiplier: 0.07 },
            { spaceUnitId: "186", multiplier: 0.02 },
            { spaceUnitId: "187", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.03 },
            { spaceUnitId: "103", multiplier: 0.97 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.12 },
            { spaceUnitId: "108", multiplier: 0.09 },
            { spaceUnitId: "109", multiplier: 0.09 },
            { spaceUnitId: "110", multiplier: 0.17 },
            { spaceUnitId: "111", multiplier: 0.08 },
            { spaceUnitId: "112", multiplier: 0.1 },
            { spaceUnitId: "113", multiplier: 0.13 },
            { spaceUnitId: "114", multiplier: 0.11 },
            { spaceUnitId: "115", multiplier: 0.04 },
            { spaceUnitId: "131", multiplier: 0.08 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 0.7 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.4 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.2 },
            { spaceUnitId: "136", multiplier: 1.3 },
            { spaceUnitId: "128", multiplier: 1 },
            { spaceUnitId: "137", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.15 },
            { spaceUnitId: "141", multiplier: 3 },
            { spaceUnitId: "142", multiplier: 6.5 },
            { spaceUnitId: "143", multiplier: 2.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "146", multiplier: 1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.1 },
            { spaceUnitId: "208", multiplier: 0.8 },
            { spaceUnitId: "210", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.2 },
            { spaceUnitId: "212", multiplier: 0.25 },
            { spaceUnitId: "213", multiplier: 0.23 },
            { spaceUnitId: "214", multiplier: 0.25 },
            { spaceUnitId: "215", multiplier: 0.07 },
            { spaceUnitId: "216", multiplier: 0.02 },
            { spaceUnitId: "217", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.1 },
            { spaceUnitId: "240", multiplier: 0.8 },
            { spaceUnitId: "242", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.2 },
            { spaceUnitId: "244", multiplier: 0.25 },
            { spaceUnitId: "245", multiplier: 0.23 },
            { spaceUnitId: "246", multiplier: 0.25 },
            { spaceUnitId: "247", multiplier: 0.07 },
            { spaceUnitId: "248", multiplier: 0.02 },
            { spaceUnitId: "249", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.1 },
            { spaceUnitId: "272", multiplier: 0.8 },
            { spaceUnitId: "274", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.2 },
            { spaceUnitId: "276", multiplier: 0.25 },
            { spaceUnitId: "277", multiplier: 0.23 },
            { spaceUnitId: "278", multiplier: 0.25 },
            { spaceUnitId: "279", multiplier: 0.07 },
            { spaceUnitId: "280", multiplier: 0.02 },
            { spaceUnitId: "281", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.1 },
            { spaceUnitId: "336", multiplier: 0.8 },
            { spaceUnitId: "338", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.2 },
            { spaceUnitId: "340", multiplier: 0.25 },
            { spaceUnitId: "341", multiplier: 0.23 },
            { spaceUnitId: "342", multiplier: 0.25 },
            { spaceUnitId: "343", multiplier: 0.07 },
            { spaceUnitId: "344", multiplier: 0.02 },
            { spaceUnitId: "345", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.1 },
            { spaceUnitId: "304", multiplier: 0.8 },
            { spaceUnitId: "306", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.2 },
            { spaceUnitId: "308", multiplier: 0.25 },
            { spaceUnitId: "309", multiplier: 0.23 },
            { spaceUnitId: "310", multiplier: 0.25 },
            { spaceUnitId: "311", multiplier: 0.07 },
            { spaceUnitId: "312", multiplier: 0.02 },
            { spaceUnitId: "313", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        },
      },
    },
    STARTUP: {
      // INCUBATOR
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.03 },
            { spaceUnitId: "3", multiplier: 0.85 },
            { spaceUnitId: "6", multiplier: 0.12 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 8.3 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.2 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.24 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "11", multiplier: 0.06 },
            { spaceUnitId: "13", multiplier: 0.05 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "27", multiplier: 5.85 },
            { spaceUnitId: "28", multiplier: 2 },
            { spaceUnitId: "33", multiplier: 1.3 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.05 },
            { spaceUnitId: "3", multiplier: 0.85 },
            { spaceUnitId: "6", multiplier: 0.1 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.2 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.3 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "13", multiplier: 0.05 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "28", multiplier: 2 },
            { spaceUnitId: "33", multiplier: 1.3 },
          ],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.03 },
            { spaceUnitId: "40", multiplier: 0.85 },
            { spaceUnitId: "42", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.2 },
            { spaceUnitId: "44", multiplier: 0.25 },
            { spaceUnitId: "45", multiplier: 0.24 },
            { spaceUnitId: "46", multiplier: 0.25 },
            { spaceUnitId: "47", multiplier: 0.06 },
            { spaceUnitId: "49", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.03 },
            { spaceUnitId: "72", multiplier: 0.85 },
            { spaceUnitId: "74", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.2 },
            { spaceUnitId: "76", multiplier: 0.25 },
            { spaceUnitId: "77", multiplier: 0.24 },
            { spaceUnitId: "78", multiplier: 0.25 },
            { spaceUnitId: "79", multiplier: 0.06 },
            { spaceUnitId: "81", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.02 },
            { spaceUnitId: "150", multiplier: 0.85 },
            { spaceUnitId: "152", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.2 },
            { spaceUnitId: "154", multiplier: 0.25 },
            { spaceUnitId: "155", multiplier: 0.24 },
            { spaceUnitId: "156", multiplier: 0.25 },
            { spaceUnitId: "157", multiplier: 0.06 },
            { spaceUnitId: "159", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.03 },
            { spaceUnitId: "178", multiplier: 0.85 },
            { spaceUnitId: "180", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.2 },
            { spaceUnitId: "182", multiplier: 0.25 },
            { spaceUnitId: "183", multiplier: 0.24 },
            { spaceUnitId: "184", multiplier: 0.25 },
            { spaceUnitId: "185", multiplier: 0.06 },
            { spaceUnitId: "187", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.02 },
            { spaceUnitId: "103", multiplier: 0.98 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.07 },
            { spaceUnitId: "108", multiplier: 0.07 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "110", multiplier: 0.06 },
            { spaceUnitId: "111", multiplier: 0.1 },
            { spaceUnitId: "112", multiplier: 0.18 },
            { spaceUnitId: "113", multiplier: 0.17 },
            { spaceUnitId: "114", multiplier: 0.15 },
            { spaceUnitId: "115", multiplier: 0.04 },
            { spaceUnitId: "131", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 0.7 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.4 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.2 },
            { spaceUnitId: "136", multiplier: 1.3 },
            { spaceUnitId: "128", multiplier: 1 },
            { spaceUnitId: "137", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "140", multiplier: 1.7 },
            { spaceUnitId: "142", multiplier: 3 },
            { spaceUnitId: "143", multiplier: 2.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.03 },
            { spaceUnitId: "208", multiplier: 0.85 },
            { spaceUnitId: "210", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.2 },
            { spaceUnitId: "212", multiplier: 0.25 },
            { spaceUnitId: "213", multiplier: 0.24 },
            { spaceUnitId: "214", multiplier: 0.25 },
            { spaceUnitId: "215", multiplier: 0.06 },
            { spaceUnitId: "217", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.03 },
            { spaceUnitId: "240", multiplier: 0.85 },
            { spaceUnitId: "242", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.2 },
            { spaceUnitId: "244", multiplier: 0.25 },
            { spaceUnitId: "245", multiplier: 0.24 },
            { spaceUnitId: "246", multiplier: 0.25 },
            { spaceUnitId: "247", multiplier: 0.06 },
            { spaceUnitId: "249", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.03 },
            { spaceUnitId: "272", multiplier: 0.85 },
            { spaceUnitId: "274", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.2 },
            { spaceUnitId: "276", multiplier: 0.25 },
            { spaceUnitId: "277", multiplier: 0.24 },
            { spaceUnitId: "278", multiplier: 0.25 },
            { spaceUnitId: "279", multiplier: 0.06 },
            { spaceUnitId: "281", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.03 },
            { spaceUnitId: "336", multiplier: 0.85 },
            { spaceUnitId: "338", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.2 },
            { spaceUnitId: "340", multiplier: 0.25 },
            { spaceUnitId: "341", multiplier: 0.24 },
            { spaceUnitId: "342", multiplier: 0.25 },
            { spaceUnitId: "343", multiplier: 0.06 },
            { spaceUnitId: "345", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.03 },
            { spaceUnitId: "304", multiplier: 0.85 },
            { spaceUnitId: "306", multiplier: 0.12 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.2 },
            { spaceUnitId: "308", multiplier: 0.25 },
            { spaceUnitId: "309", multiplier: 0.24 },
            { spaceUnitId: "310", multiplier: 0.25 },
            { spaceUnitId: "311", multiplier: 0.06 },
            { spaceUnitId: "313", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        },
      },
    },
    ENG_CREATIVE: {
      // CREATOR
      US: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "3", multiplier: 0.74 },
            { spaceUnitId: "4", multiplier: 0.1 },
            { spaceUnitId: "6", multiplier: 0.06 },
          ],
          [CATEGORY.SHARED_FOCUS]: [{ spaceUnitId: "205", multiplier: 16.6 }],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.15 },
            { spaceUnitId: "8", multiplier: 0.2 },
            { spaceUnitId: "9", multiplier: 0.3 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "11", multiplier: 0.1 },
            { spaceUnitId: "13", multiplier: 0.05 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
            { spaceUnitId: "30", multiplier: 1 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "25", multiplier: 0.5 },
            { spaceUnitId: "27", multiplier: 5.85 },
            { spaceUnitId: "28", multiplier: 2 },
            { spaceUnitId: "29", multiplier: 0.8 },
            { spaceUnitId: "33", multiplier: 1.3 },
            { spaceUnitId: "37", multiplier: 1.4 },
          ],
        },
        SMALL: {
          [CATEGORY.ME]: [
            { spaceUnitId: "1", multiplier: 0.1 },
            { spaceUnitId: "3", multiplier: 0.74 },
            { spaceUnitId: "4", multiplier: 0.1 },
            { spaceUnitId: "6", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "7", multiplier: 0.15 },
            { spaceUnitId: "8", multiplier: 0.25 },
            { spaceUnitId: "9", multiplier: 0.35 },
            { spaceUnitId: "10", multiplier: 0.25 },
            { spaceUnitId: "13", multiplier: 0.05 },
            { spaceUnitId: "14", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "15", multiplier: 3 },
            { spaceUnitId: "16", multiplier: 4 },
            { spaceUnitId: "17", multiplier: 1.2 },
            { spaceUnitId: "18", multiplier: 0.5 },
            { spaceUnitId: "19", multiplier: 0.63 },
            { spaceUnitId: "21", multiplier: 80 },
            { spaceUnitId: "22", multiplier: 1 },
            { spaceUnitId: "24", multiplier: 0.45 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "28", multiplier: 2 },
            { spaceUnitId: "29", multiplier: 0.8 },
            { spaceUnitId: "33", multiplier: 1.3 },
          ],
        },
      },
      UK: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "38", multiplier: 0.1 },
            { spaceUnitId: "40", multiplier: 0.74 },
            { spaceUnitId: "41", multiplier: 0.1 },
            { spaceUnitId: "42", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "43", multiplier: 0.15 },
            { spaceUnitId: "44", multiplier: 0.2 },
            { spaceUnitId: "45", multiplier: 0.3 },
            { spaceUnitId: "46", multiplier: 0.25 },
            { spaceUnitId: "47", multiplier: 0.1 },
            { spaceUnitId: "49", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "57", multiplier: 1.991324 },
            { spaceUnitId: "58", multiplier: 0.430557 },
            { spaceUnitId: "59", multiplier: 2.152783 },
            { spaceUnitId: "60", multiplier: 0.807294 },
            { spaceUnitId: "61", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "50", multiplier: 0.968752 },
            { spaceUnitId: "51", multiplier: 1.614587 },
            { spaceUnitId: "52", multiplier: 5.381958 },
            { spaceUnitId: "53", multiplier: 3.229175 },
            { spaceUnitId: "54", multiplier: 4.921261 },
            { spaceUnitId: "55", multiplier: 0.656168 },
            { spaceUnitId: "56", multiplier: 1.614587 },
            { spaceUnitId: "62" },
            { spaceUnitId: "63" },
            { spaceUnitId: "64" },
            { spaceUnitId: "65" },
            { spaceUnitId: "66" },
            { spaceUnitId: "67" },
            { spaceUnitId: "68" },
          ],
        },
      },
      GERMANY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "70", multiplier: 0.1 },
            { spaceUnitId: "72", multiplier: 0.74 },
            { spaceUnitId: "73", multiplier: 0.1 },
            { spaceUnitId: "74", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "75", multiplier: 0.15 },
            { spaceUnitId: "76", multiplier: 0.2 },
            { spaceUnitId: "77", multiplier: 0.3 },
            { spaceUnitId: "78", multiplier: 0.25 },
            { spaceUnitId: "79", multiplier: 0.1 },
            { spaceUnitId: "81", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "82", multiplier: 3.23 },
            { spaceUnitId: "83", multiplier: 3.23 },
            { spaceUnitId: "84", multiplier: 0.89 },
            { spaceUnitId: "85", multiplier: 2.15 },
            { spaceUnitId: "86", multiplier: 0.5 },
            { spaceUnitId: "87", multiplier: 2.15 },
            { spaceUnitId: "88", multiplier: 0.5 },
            { spaceUnitId: "89", multiplier: 4.31 },
            { spaceUnitId: "90", multiplier: 3.23 },
            { spaceUnitId: "91", multiplier: 2.15 },
            { spaceUnitId: "92", multiplier: 1.61 },
            { spaceUnitId: "93", multiplier: 2.15 },
            { spaceUnitId: "94" },
            { spaceUnitId: "95" },
            { spaceUnitId: "96" },
            { spaceUnitId: "97" },
            { spaceUnitId: "98" },
            { spaceUnitId: "99" },
            { spaceUnitId: "100" },
          ],
        },
      },
      SPAIN: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "148", multiplier: 0.04 },
            { spaceUnitId: "150", multiplier: 0.74 },
            { spaceUnitId: "151", multiplier: 0.1 },
            { spaceUnitId: "152", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "153", multiplier: 0.15 },
            { spaceUnitId: "154", multiplier: 0.2 },
            { spaceUnitId: "155", multiplier: 0.3 },
            { spaceUnitId: "156", multiplier: 0.25 },
            { spaceUnitId: "157", multiplier: 0.1 },
            { spaceUnitId: "159", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "160", multiplier: 0.98 },
            { spaceUnitId: "161", multiplier: 0.49 },
            { spaceUnitId: "162", multiplier: 0.49 },
            { spaceUnitId: "163", multiplier: 0.65 },
            { spaceUnitId: "164", multiplier: 0.33 },
            { spaceUnitId: "165", multiplier: 0.33 },
            { spaceUnitId: "166", multiplier: 0.98 },
            { spaceUnitId: "167", multiplier: 1.64 },
            { spaceUnitId: "168", multiplier: 0.5 },
            { spaceUnitId: "169", multiplier: 0.55 },
            { spaceUnitId: "170", multiplier: 0.65 },
            { spaceUnitId: "171" },
            { spaceUnitId: "172" },
            { spaceUnitId: "173" },
            { spaceUnitId: "174" },
            { spaceUnitId: "175" },
          ],
        },
      },
      FRANCE: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "176", multiplier: 0.1 },
            { spaceUnitId: "178", multiplier: 0.74 },
            { spaceUnitId: "179", multiplier: 0.1 },
            { spaceUnitId: "180", multiplier: 0.6 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "181", multiplier: 0.15 },
            { spaceUnitId: "182", multiplier: 0.2 },
            { spaceUnitId: "183", multiplier: 0.3 },
            { spaceUnitId: "184", multiplier: 0.25 },
            { spaceUnitId: "185", multiplier: 0.1 },
            { spaceUnitId: "187", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "188", multiplier: 0.98 },
            { spaceUnitId: "189", multiplier: 0.49 },
            { spaceUnitId: "190", multiplier: 0.66 },
            { spaceUnitId: "191", multiplier: 0.33 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "192", multiplier: 0.66 },
            { spaceUnitId: "193", multiplier: 0.33 },
            { spaceUnitId: "194", multiplier: 0.98 },
            { spaceUnitId: "195", multiplier: 1.64 },
            { spaceUnitId: "196", multiplier: 1.31 },
            { spaceUnitId: "197", multiplier: 0.57 },
            { spaceUnitId: "198" },
            { spaceUnitId: "199" },
            { spaceUnitId: "200" },
            { spaceUnitId: "201" },
            { spaceUnitId: "202" },
            { spaceUnitId: "203" },
            { spaceUnitId: "204" },
          ],
        },
      },
      APAC: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "101", multiplier: 0.03 },
            { spaceUnitId: "104", multiplier: 0.98 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "107", multiplier: 0.1 },
            { spaceUnitId: "108", multiplier: 0.1 },
            { spaceUnitId: "109", multiplier: 0.1 },
            { spaceUnitId: "110", multiplier: 0.1 },
            { spaceUnitId: "111", multiplier: 0.08 },
            { spaceUnitId: "112", multiplier: 0.08 },
            { spaceUnitId: "113", multiplier: 0.17 },
            { spaceUnitId: "114", multiplier: 0.13 },
            { spaceUnitId: "115", multiplier: 0.08 },
            { spaceUnitId: "131", multiplier: 0.06 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "132", multiplier: 1 },
            { spaceUnitId: "133", multiplier: 0.2 },
            { spaceUnitId: "134", multiplier: 0.5 },
            { spaceUnitId: "135", multiplier: 0.4 },
            { spaceUnitId: "124", multiplier: 0.63 },
            { spaceUnitId: "125", multiplier: 0.4 },
            { spaceUnitId: "126", multiplier: 0.75 },
            { spaceUnitId: "136", multiplier: 2.5 },
            { spaceUnitId: "128", multiplier: 1.2 },
            { spaceUnitId: "137", multiplier: 0.35 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "139", multiplier: 0.1 },
            { spaceUnitId: "141", multiplier: 2.5 },
            { spaceUnitId: "142", multiplier: 4.5 },
            { spaceUnitId: "143", multiplier: 2.5 },
            { spaceUnitId: "144", multiplier: 0.1 },
            { spaceUnitId: "145", multiplier: 0.8 },
            { spaceUnitId: "146", multiplier: 1 },
            { spaceUnitId: "147", multiplier: 1.4 },
          ],
        },
      },
      SLOVAKIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "206", multiplier: 0.1 },
            { spaceUnitId: "208", multiplier: 0.74 },
            { spaceUnitId: "209", multiplier: 0.1 },
            { spaceUnitId: "210", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "211", multiplier: 0.15 },
            { spaceUnitId: "212", multiplier: 0.2 },
            { spaceUnitId: "213", multiplier: 0.3 },
            { spaceUnitId: "214", multiplier: 0.25 },
            { spaceUnitId: "215", multiplier: 0.1 },
            { spaceUnitId: "217", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "225", multiplier: 1.991324 },
            { spaceUnitId: "226", multiplier: 0.430557 },
            { spaceUnitId: "227", multiplier: 2.152783 },
            { spaceUnitId: "228", multiplier: 0.807294 },
            { spaceUnitId: "229", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "218", multiplier: 0.968752 },
            { spaceUnitId: "219", multiplier: 1.614587 },
            { spaceUnitId: "220", multiplier: 5.381958 },
            { spaceUnitId: "221", multiplier: 3.229175 },
            { spaceUnitId: "222", multiplier: 4.921261 },
            { spaceUnitId: "223", multiplier: 0.656168 },
            { spaceUnitId: "224", multiplier: 1.614587 },
            { spaceUnitId: "230" },
            { spaceUnitId: "231" },
            { spaceUnitId: "232" },
            { spaceUnitId: "233" },
            { spaceUnitId: "234" },
            { spaceUnitId: "235" },
            { spaceUnitId: "236" },
          ],
        },
      },
      POLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "238", multiplier: 0.1 },
            { spaceUnitId: "240", multiplier: 0.74 },
            { spaceUnitId: "241", multiplier: 0.1 },
            { spaceUnitId: "242", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "243", multiplier: 0.15 },
            { spaceUnitId: "244", multiplier: 0.2 },
            { spaceUnitId: "245", multiplier: 0.3 },
            { spaceUnitId: "246", multiplier: 0.25 },
            { spaceUnitId: "247", multiplier: 0.1 },
            { spaceUnitId: "249", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "257", multiplier: 1.991324 },
            { spaceUnitId: "258", multiplier: 0.430557 },
            { spaceUnitId: "259", multiplier: 2.152783 },
            { spaceUnitId: "260", multiplier: 0.807294 },
            { spaceUnitId: "261", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "250", multiplier: 0.968752 },
            { spaceUnitId: "251", multiplier: 1.614587 },
            { spaceUnitId: "252", multiplier: 5.381958 },
            { spaceUnitId: "253", multiplier: 3.229175 },
            { spaceUnitId: "254", multiplier: 4.921261 },
            { spaceUnitId: "255", multiplier: 0.656168 },
            { spaceUnitId: "256", multiplier: 1.614587 },
            { spaceUnitId: "262" },
            { spaceUnitId: "263" },
            { spaceUnitId: "264" },
            { spaceUnitId: "265" },
            { spaceUnitId: "266" },
            { spaceUnitId: "267" },
            { spaceUnitId: "268" },
          ],
        },
      },
      HUNGARY: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "270", multiplier: 0.1 },
            { spaceUnitId: "272", multiplier: 0.74 },
            { spaceUnitId: "273", multiplier: 0.1 },
            { spaceUnitId: "274", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "275", multiplier: 0.15 },
            { spaceUnitId: "276", multiplier: 0.2 },
            { spaceUnitId: "277", multiplier: 0.3 },
            { spaceUnitId: "278", multiplier: 0.25 },
            { spaceUnitId: "279", multiplier: 0.1 },
            { spaceUnitId: "281", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "289", multiplier: 1.991324 },
            { spaceUnitId: "290", multiplier: 0.430557 },
            { spaceUnitId: "291", multiplier: 2.152783 },
            { spaceUnitId: "292", multiplier: 0.807294 },
            { spaceUnitId: "293", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "282", multiplier: 0.968752 },
            { spaceUnitId: "283", multiplier: 1.614587 },
            { spaceUnitId: "284", multiplier: 5.381958 },
            { spaceUnitId: "285", multiplier: 3.229175 },
            { spaceUnitId: "286", multiplier: 4.921261 },
            { spaceUnitId: "287", multiplier: 0.656168 },
            { spaceUnitId: "288", multiplier: 1.614587 },
            { spaceUnitId: "294" },
            { spaceUnitId: "295" },
            { spaceUnitId: "296" },
            { spaceUnitId: "297" },
            { spaceUnitId: "298" },
            { spaceUnitId: "299" },
            { spaceUnitId: "300" },
          ],
        },
      },
      NETHERLAND: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "334", multiplier: 0.1 },
            { spaceUnitId: "336", multiplier: 0.74 },
            { spaceUnitId: "337", multiplier: 0.1 },
            { spaceUnitId: "338", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "339", multiplier: 0.15 },
            { spaceUnitId: "340", multiplier: 0.2 },
            { spaceUnitId: "341", multiplier: 0.3 },
            { spaceUnitId: "342", multiplier: 0.25 },
            { spaceUnitId: "343", multiplier: 0.1 },
            { spaceUnitId: "345", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "355", multiplier: 0.67 },
            { spaceUnitId: "356", multiplier: 2.15 },
            { spaceUnitId: "357", multiplier: 0.5 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "353", multiplier: 1.62 },
            { spaceUnitId: "354", multiplier: 3.23 },
            { spaceUnitId: "346", multiplier: 0.5 },
            { spaceUnitId: "347", multiplier: 0.5 },
            { spaceUnitId: "348", multiplier: 4.31 },
            { spaceUnitId: "349", multiplier: 14.267 },
            { spaceUnitId: "350", multiplier: 1.07 },
            { spaceUnitId: "351", multiplier: 0.67 },
            { spaceUnitId: "352", multiplier: 2.15 },
            { spaceUnitId: "358" },
            { spaceUnitId: "359" },
            { spaceUnitId: "360" },
            { spaceUnitId: "361" },
            { spaceUnitId: "362" },
            { spaceUnitId: "363" },
            { spaceUnitId: "364" },
          ],
        },
      },
      COLOMBIA: {
        REGULAR: {
          [CATEGORY.ME]: [
            { spaceUnitId: "302", multiplier: 0.1 },
            { spaceUnitId: "304", multiplier: 0.74 },
            { spaceUnitId: "305", multiplier: 0.1 },
            { spaceUnitId: "306", multiplier: 0.06 },
          ],
          [CATEGORY.WE]: [
            { spaceUnitId: "307", multiplier: 0.15 },
            { spaceUnitId: "308", multiplier: 0.2 },
            { spaceUnitId: "309", multiplier: 0.3 },
            { spaceUnitId: "310", multiplier: 0.25 },
            { spaceUnitId: "311", multiplier: 0.1 },
            { spaceUnitId: "313", multiplier: 0.05 },
          ],
          [CATEGORY.SUPPORT]: [
            { spaceUnitId: "321", multiplier: 1.991324 },
            { spaceUnitId: "322", multiplier: 0.430557 },
            { spaceUnitId: "323", multiplier: 2.152783 },
            { spaceUnitId: "324", multiplier: 0.807294 },
            { spaceUnitId: "325", multiplier: 1.614587 },
          ],
          [CATEGORY.AMENITY]: [
            { spaceUnitId: "314", multiplier: 0.968752 },
            { spaceUnitId: "315", multiplier: 1.614587 },
            { spaceUnitId: "316", multiplier: 5.381958 },
            { spaceUnitId: "317", multiplier: 3.229175 },
            { spaceUnitId: "318", multiplier: 4.921261 },
            { spaceUnitId: "319", multiplier: 0.656168 },
            { spaceUnitId: "320", multiplier: 1.614587 },
            { spaceUnitId: "326" },
            { spaceUnitId: "327" },
            { spaceUnitId: "328" },
            { spaceUnitId: "329" },
            { spaceUnitId: "330" },
            { spaceUnitId: "331" },
            { spaceUnitId: "332" },
            { spaceUnitId: "333", multiplier: 12.18 },
          ],
        }
      }
    },
  };