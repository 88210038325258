const constants = require('wp-constants').spacerFree;

function mapCollaborationValues(low, medium, high) {
  return {
    [constants.calculator.COLLABORATION_LEVEL.LOW]: low,
    [constants.calculator.COLLABORATION_LEVEL.MEDIUM]: medium,
    [constants.calculator.COLLABORATION_LEVEL.HIGH]: high
  };
}

module.exports = {

  EFFICIENT: {
    US: mapCollaborationValues(0.5, 0.65, 0.8),
    EUROPE: mapCollaborationValues(0.3, 0.39, 0.48),
    APAC: mapCollaborationValues(0.3, 0.55, 0.8)
  },

  WHITE_COLLAR: {
    US: mapCollaborationValues(0.55, 0.7, 0.85),
    EUROPE: mapCollaborationValues(0.39, 0.48, 0.6),
    APAC: mapCollaborationValues(0.3, 0.6, 0.9)
  },

  WHITE_GLOVE: {
    US: mapCollaborationValues(0.65, 0.8, 1),
    EUROPE: mapCollaborationValues(0.39, 0.48, 0.6),
    APAC: mapCollaborationValues(0.55, 0.85, 1.1)
  },

  OPEN_OFFICE: {
    US: mapCollaborationValues(0.5, 0.65, 0.8),
    EUROPE: mapCollaborationValues(0.3, 0.39, 0.48),
    APAC: mapCollaborationValues(0.3, 0.5, 0.7)
  },

  WORKPLACE_360: {
    US: mapCollaborationValues(0.65, 0.85, 1.1),
    EUROPE: mapCollaborationValues(0.39, 0.51, 0.66),
    APAC: mapCollaborationValues(0.7, 1, 1.25)
  },

  CAMPUS: {
    US: mapCollaborationValues(0.7, 0.9, 1.1),
    EUROPE: mapCollaborationValues(0.45, 0.54, 0.66),
    APAC: mapCollaborationValues(0.4, 0.7, 1)
  },

  STARTUP: {
    US: mapCollaborationValues(0.5, 0.65, 0.8),
    EUROPE: mapCollaborationValues(0.3, 0.39, 0.48),
    APAC: mapCollaborationValues(0.3, 0.65, 1.1)
  },

  ENG_CREATIVE: {
    US: mapCollaborationValues(0.65, 0.85, 1),
    EUROPE: mapCollaborationValues(0.39, 0.51, 0.6),
    APAC: mapCollaborationValues(0.3, 0.65, 1)
  }

};
