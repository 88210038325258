const _ = require('lodash');

const industryCodeTokensAndValues = {

  112: { token: 'animalProd', value: 'Animal production and aquaculture' },
  113: { token: 'forestry', value: 'Forestry and logging' },
  114: { token: 'fishingHunting', value: 'Fishing, hunting and trapping' },
  115: { token: 'agForestSupport', value: 'Agriculture and forestry support activities' },
  211: { token: 'oilGas', value: 'Oil and gas extraction' },
  2111: { token: 'energy', value: 'Energy (Gas, Oil, etc.)' },
  212: { token: 'mining', value: 'Mining, except oil and gas' },
  213: { token: 'supportMining', value: 'Support activities for mining' },
  221: { token: 'utilities', value: 'Utilities' },
  236: { token: 'buildingConstruct', value: 'Construction of buildings' },
  237: { token: 'heavyCivilConstruct', value: 'Heavy and civil engineering construction' },
  238: { token: 'specialtyContract', value: 'Specialty trade contractors' },
  3119: { token: 'foodManufac', value: 'Food Manufacturing' },
  312: { token: 'beverageTobaccoManufac', value: 'Beverage and tobacco product manufacturing' },
  313: { token: 'textile', value: 'Textile mills' },
  314: { token: 'textileProduct', value: 'Textile product mills' },
  315: { token: 'apparelManufac', value: 'Apparel manufacturing' },
  316: { token: 'leatherManufac', value: 'Leather and allied product manufacturing' },
  321: { token: 'woodManufac', value: 'Wood product manufacturing' },
  322: { token: 'paperManufac', value: 'Paper manufacturing' },
  323: { token: 'printingSupport', value: 'Printing and related support activities' },
  3231: { token: 'printingReproduc', value: 'Printing/Reproductions' },
  324: { token: 'petroleumCoal', value: 'Petroleum and coal products manufacturing' },
  325: { token: 'chemicalManufac', value: 'Chemical manufacturing' },
  3251: { token: 'chemicals', value: 'Chemicals' },
  3254: { token: 'pharma', value: 'Pharmaceuticals' },
  3256: { token: 'cleaningManufac', value: 'Cleaning Manufacturing' },
  326: { token: 'plasticsRubberManufac', value: 'Plastics and rubber products manufacturing' },
  327: { token: 'nonmetallicManufac', value: 'Nonmetallic mineral product manufacturing' },
  331: { token: 'primaryMetalManufac', value: 'Primary metal manufacturing' },
  332: { token: 'fabricatedMetalManufac', value: 'Fabricated metal product manufacturing' },
  333: { token: 'machineryManufac', value: 'Machinery manufacturing' },
  3331: { token: 'manufac', value: 'Manufacturing' },
  334: { token: 'computerElectronicManufac', value: 'Computer and electronic product manufacturing' },
  335: { token: 'electricalApplianceManufac', value: 'Electrical equipment and appliance mfg.' },
  336: { token: 'transportEquipManufac', value: 'Transportation equipment manufacturing' },
  3364: { token: 'aerospaceDefense', value: 'Aerospace/Defense' },
  337: { token: 'furnitureManufac', value: 'Furniture and related product manufacturing' },
  339: { token: 'miscManufac', value: 'Miscellaneous manufacturing' },
  423: { token: 'merchantWholesaleDurable', value: 'Merchant wholesalers, durable goods' },
  424: { token: 'merchantWholesaleNondurable', value: 'Merchant wholesalers, nondurable goods' },
  425: { token: 'electronicMarket', value: 'Electronic markets and agents and brokers' },
  441: { token: 'motorVehiclePartsDealers', value: 'Motor vehicle and parts dealers' },
  442: { token: 'furnitureFurnishingStores', value: 'Furniture and home furnishings stores' },
  443: { token: 'electronicApplianceStores', value: 'Electronics and appliance stores' },
  4431: { token: 'electronics', value: 'Electronics' },
  444: { token: 'buildingGardenSupplyStores', value: 'Building material and garden supply stores' },
  445: { token: 'foodBeverageStores', value: 'Food and beverage stores' },
  4451: { token: 'groceryStores', value: 'Grocery Stores' },
  4453: { token: 'beerWineLiquorStores', value: 'Beer, Wine, and Liquor Stores' },
  446: { token: 'healthCareStores', value: 'Health and personal care stores' },
  447: { token: 'gasStations', value: 'Gasoline stations' },
  448: { token: 'clothingAccessoriesStores', value: 'Clothing and clothing accessories stores' },
  4481: { token: 'clothingTextiles', value: 'Clothing/Textiles' },
  4483: { token: 'jewelryFurs', value: 'Jewelry & Furs' },
  451: { token: 'sportsHobbyMusicBookStores', value: 'Sports, hobby, music instrument, book stores' },
  452: { token: 'genMerchandiseStores', value: 'General merchandise stores' },
  453: { token: 'miscStoreRetailers', value: 'Miscellaneous store retailers' },
  4532: { token: 'officeProducts', value: 'Office Products' },
  4539: { token: 'retailSales', value: 'Retail Sales' },
  454: { token: 'nonstoreRetails', value: 'Nonstore retailers' },
  481: { token: 'airTransport', value: 'Air transportation' },
  4811: { token: 'travelRelated', value: 'Travel Related (Airlines, Agents, Mass Transit)' },
  482: { token: 'railTransport', value: 'Rail transportation' },
  483: { token: 'waterTransport', value: 'Water transportation' },
  484: { token: 'truckTransport', value: 'Truck transportation' },
  485: { token: 'transitTransport', value: 'Transit and ground passenger transportation' },
  486: { token: 'pipelineTransport', value: 'Pipeline transportation' },
  487: { token: 'scenicTransport', value: 'Scenic and sightseeing transportation' },
  488: { token: 'supportTransport', value: 'Support activities for transportation' },
  491: { token: 'postalService', value: 'Postal service' },
  492: { token: 'courierMessenger', value: 'Couriers and messengers' },
  4922: { token: 'shippingDelivery', value: 'Shipping/Delivery' },
  493: { token: 'warehousingStorage', value: 'Warehousing and storage' },
  4931: { token: 'warehouseDistrib', value: 'Warehouse/Distribution' },
  511: { token: 'publishingNoInternet', value: 'Publishing industries, except Internet' },
  5111: { token: 'publishing', value: 'Publishing' },
  512: { token: 'motionPicSoundRecordingIndustries', value: 'Motion picture and sound recording industries' },
  5121: { token: 'entertainment', value: 'Entertainment (Film, TV, Music, etc.)' },
  5141: { token: 'infoServicesMarketResearch', value: 'Information Services/Market Research' },
  515: { token: 'broadcastingNoInternet', value: 'Broadcasting, except Internet' },
  517: { token: 'telecom', value: 'Telecommunications' },
  5170: { token: 'tech', value: 'Technology' },
  518: { token: 'dataProcessingHostingServices', value: 'Data processing, hosting and related services' },
  519: { token: 'otherInfoServices', value: 'Other information services' },
  52: { token: 'financeInsurance', value: 'Finance and Insurance' },
  521: { token: 'monetaryAuthorities', value: 'Monetary authorities-central bank' },
  522: { token: 'creditIntermediation', value: 'Credit intermediation and related activities' },
  5221: { token: 'banking', value: 'Banking' },
  523: { token: 'securitiesContractsInvestments', value: 'Securities, commodity contracts, investments' },
  5239: { token: 'financialServicesNoBanking', value: 'Financial Services (Non-banking)' },
  524: { token: 'insuranceCarriersRelated', value: 'Insurance carriers and related activities' },
  5241: { token: 'insurance', value: 'Insurance' },
  525: { token: 'fundsTrustsFinancialVehicles', value: 'Funds, trusts, and other financial vehicles' },
  531: { token: 'realEstate', value: 'Real Estate' },
  532: { token: 'rentalLeasingServices', value: 'Rental and leasing services' },
  5321: { token: 'automotive', value: 'Automotive' },
  5322: { token: 'consumerGoods', value: 'Consumer Goods' },
  533: { token: 'lessorsNonfinancialIntangible', value: 'Lessors of nonfinancial intangible assets' },
  541: { token: 'professionalTechnicalServices', value: 'Professional and technical services' },
  5411: { token: 'legalServices', value: 'Legal Services' },
  5412: { token: 'accounting', value: 'Accounting' },
  5413: { token: 'architectureEngineering', value: 'Architecture/Engineering' },
  5414: { token: 'specializedDesignServices', value: 'Specialized Design Services' },
  5415: { token: 'compRelatedServices', value: 'Computer Related Services' },
  5416: { token: 'mgmtConsulting', value: 'Management Consulting' },
  5417: { token: 'scientificResearch', value: 'Scientific Research' },
  5418: { token: 'adsMarketingPR', value: 'Advertising/Marketing/Public Relations' },
  5419: { token: 'otherProfSciTechServices', value: 'Other Professional, Scientific, and Technical Services' },
  551: { token: 'companyEnterpriseMgmt', value: 'Management of companies and enterprises' },
  561: { token: 'adminSupportServices', value: 'Administrative and support services' },
  5613: { token: 'employmentAgencyRecruiters', value: 'Employment Agency/Recruiters' },
  5616: { token: 'securityPrivateInvestigation', value: 'Security/Private Investigation' },
  562: { token: 'wasteMgmtRemediationServices', value: 'Waste management and remediation services' },
  611: { token: 'educationServices', value: 'Educational services' },
  6113: { token: 'education', value: 'Education' },
  621: { token: 'ambulatoryHealthCareServices', value: 'Ambulatory health care services' },
  6213: { token: 'healthCareRelated', value: 'Health Care Related' },
  622: { token: 'hospitals', value: 'Hospitals' },
  623: { token: 'nursingResidentialCareFacilities', value: 'Nursing and residential care facilities' },
  624: { token: 'socialAssistance', value: 'Social assistance' },
  711: { token: 'performingArtsSpectatorSports', value: 'Performing arts and spectator sports' },
  712: { token: 'museumsHistoricalSitesZoosParks', value: 'Museums, historical sites, zoos, and parks' },
  7121: { token: 'fineArtsGalleriesMuseums', value: 'Fine Arts (Galleries, Museums)' },
  713: { token: 'amusementsGamblingRec', value: 'Amusements, gambling, and recreation' },
  721: { token: 'accommodation', value: 'Accommodation' },
  722: { token: 'foodDrinkingServices', value: 'Food services and drinking places' },
  7221: { token: 'restaurantHotel', value: 'Restaurant/Hotel' },
  811: { token: 'repairMaintenance', value: 'Repair and maintenance' },
  8113: { token: 'industrialProductsHeavy', value: 'Industrial Products (Heavy Industry)' },
  812: { token: 'personalLaundryServices', value: 'Personal and laundry services' },
  813: { token: 'membershipAssocOrgs', value: 'Membership associations and organizations' },
  8134: { token: 'nonprofit', value: 'Non-profit' },
  814: { token: 'privateHouseholds', value: 'Private households' },
  921: { token: 'execLegisGenGvt', value: 'Executive, legislative and general government' },
  9211: { token: 'gvt', value: 'Government' },
  922: { token: 'justicePublicOrderSafetyActivities', value: 'Justice, public order, and safety activities' },
  923: { token: 'hrAdminPrograms', value: 'Administration of human resource programs' },
  924: { token: 'environmentAdminPrograms', value: 'Administration of environmental programs' },
  9241: { token: 'environmental', value: 'Environmental' },
  925: { token: 'communityHousingProgramAdmin', value: 'Community and housing program administration' },
  926: { token: 'econProgramAdmin', value: 'Administration of economic programs' },
  927: { token: 'spaceResearchTech', value: 'Space research and technology' },
  928: { token: 'natlSecurityIntlAffairs', value: 'National security and international affairs' }
};

function mapCodeTo(element) {
  const codeElementAssoc = {};
  _.forEach(industryCodeTokensAndValues, (value, key) => {
    codeElementAssoc[key] = value[element];
  });
  return codeElementAssoc;
}

// returns { ..., 928: 'natlSecurityIntlAffairs' }
const industryCodeTokens = mapCodeTo('token');

// returns { ..., 928: 'National security and international affairs' }
const industryCodeValues = mapCodeTo('value');

module.exports = {
  industryCodeTokens,
  industryCodeValues
};
