const React          = require('react');
const PropTypes      = require('prop-types');
let segmentStyle = null;


function computeSubTitleStyles(percentage, isMobileScreen, subtitle, isGroupSpace) {
  if (isMobileScreen) {
    const percent = Number(percentage.slice(0, -1));
    if (percent < 20 && subtitle && isGroupSpace) {
      if (percent < 5) {
        return 'group-space-xs';
      }
      return 'group-space-small';
    }
    if (subtitle && !isGroupSpace) {
      if (percent < 21) {
        if (percent < 5) {
          return 'not-group-space-xs';
        }
        return 'not-group-space-small';
      }
      return 'not-group-space';
    }
  }
  return '';
}

function BarChartSegment(props) {
  if (props.isMobileScreen) {
    segmentStyle = {
      width: `calc(${props.percentage} + 10%)`
    };
  } else {
    segmentStyle = {
      width: props.percentage
    };
  };
  const barClasses = `program-bar-chart-bar is-${props.colorClass}`;
  let titleClasses = 'title is-half-spaced has-text-weight-bold program-bar-chart-segment-percentage';
  const subtitleClasses = `subtitle is-6 program-bar-chart-segment-subtitle has-text-${props.colorClass} has-margin-bottom-6 ${computeSubTitleStyles(props.percentage, props.isMobileScreen, props.percentageSubtitle, props.isGroupSpace)}`;
  if (props.isMobileScreen) {
    titleClasses += ` has-text-${props.colorClass}`;
  } else {
    titleClasses += ' has-text-white';
  }
  let subtitle = props.percentageSubtitle ? <div key="1" className={subtitleClasses} title={props.percentageSubtitle}> {props.percentageSubtitle}</div> : null;
  let percentage = (
    <div className={titleClasses}>{props.percentage.slice(0, -1)}%</div>
  );
  let bar = (
    <div key="2" className={barClasses}>
      {percentage}
    </div>
  );
  let segmentMarkup = [
    subtitle,
    bar
  ];

  if (props.isMobileScreen) {
    subtitle = props.percentageSubtitle ? <div key="1" className={subtitleClasses}> {props.percentageSubtitle.split(' ')[0]}</div> : null;
    bar = (
      <div key="2" className={barClasses} />
    );
    percentage = (
      <div key="3" className={titleClasses}>{props.percentage.slice(0, -1)}%</div>
    );
    segmentMarkup = [
      percentage,
      bar,
      subtitle
    ];
  }

  const segmentClasses = 'has-text-centered program-bar-chart-segment';

  return (
    <div className={segmentClasses} style={segmentStyle}>
      {segmentMarkup}
    </div>
  );
}

BarChartSegment.propTypes = {
  colorClass: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  percentageSubtitle: PropTypes.string,
  isMobileScreen: PropTypes.bool,
  isGroupSpace: PropTypes.bool
};

BarChartSegment.defaultProps = {
  isMobileScreen: false,
  isGroupSpace: false,
  percentageSubtitle: ''
};

module.exports = BarChartSegment;
