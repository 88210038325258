const {
  CIRCULATION_TYPE,
  CATEGORY
}                   = require('wp-constants').shared.spaceUnit;

module.exports = {
  SHARED_FOCUS_AUTOFILL_OPTIONS: [
    {
      name: 'Focus Room',
      category: CATEGORY.SHARED_FOCUS,
      ratio: 20,
      sf: 120,
      seats: 1,
      circulationType: CIRCULATION_TYPE.ENCLOSED
    },
    {
      name: 'Library Seating Area',
      category: CATEGORY.SHARED_FOCUS,
      ratio: 150,
      sf: 500,
      seats: 10,
      circulationType: CIRCULATION_TYPE.OPEN
    }
  ]
};
