const clientConstants = require('../../shared/constants/clientConstants');

function getSpaceBelow(domElement) {
  return window.innerHeight - domElement.getBoundingClientRect().bottom;
}

function isScreenMobileSize() {
  return window.innerWidth < 768;
}

function isScreenTabletSize() {
  return window.innerWidth < 1023 && window.innerWidth >= 768;
}

function scrollY(y) {
  if (clientConstants.IS_IE_OR_EDGE) {
    window.scrollTo(0, y);
  } else {
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }
}

function scrollToWithNavbar(domElement) {
  const navbar = document.getElementsByClassName('navbar')[0];
  const elementDistFromTopOfViewPort = domElement.getBoundingClientRect().top - navbar.offsetHeight;
  const alreadyScrolledDistance = window.pageYOffset;
  scrollY(elementDistFromTopOfViewPort + alreadyScrolledDistance);
}

module.exports = {
  scrollY,
  scrollToWithNavbar,
  getSpaceBelow,
  isScreenMobileSize,
  isScreenTabletSize
};
