const spaceUnitMap                     = require('./spaceUnitMap');
const { profileNameIds }               = require('./profileNames');
const _                                = require('lodash');
const { CATEGORY }                     = require('wp-constants').shared.spaceUnit;
const originalProfileMap               = require('./profileMap');
const { US_REGULAR }                   = require('wp-constants').spacerFree.calculator.PROFILE_TYPE;
const { MEDIUM }                       = require('wp-constants').spacerFree.calculator.COLLABORATION_LEVEL;
const { COLLABORATION_BLANK_TEMPLATE } = require('wp-constants').spacerPro.blankTemplatePopulationLocation;

const profileMap = _.cloneDeep(originalProfileMap);

function getWeKitOfPartsWithMultiplier(weKitOfParts, program) {
  _.forEach(weKitOfParts, (spaceUnitWithMultiplier) => {
    const spaceUnit = spaceUnitMap[spaceUnitWithMultiplier.spaceUnitId];
    const weUnit = {
      name: spaceUnit.displayName,
      category: spaceUnit.category,
      sf: spaceUnit.sf,
      circulationType: spaceUnit.circulationType,
      seats: spaceUnit.seats,
      multiplier: spaceUnitWithMultiplier.multiplier
    };
    program.we.push(weUnit);
  });
}

function getKitOfPartsWithMultiplierAndAssumptions(profileKitOfParts, collaborationRatio) {
  let program = {
    me: [],
    we: [],
    sharedFocus: [],
    support: [],
    amenity: [],
    assumptions: {}
  };

  getWeKitOfPartsWithMultiplier(profileKitOfParts[CATEGORY.WE], program);
  program = {
    ...program,
    assumptions: {
      sharingPopulation: 1,
      collaborationRatio,
      assignedSeating: true
    }
  };

  return program;
}

function getTemplateWithoutOrder(profileMapId) {
  const profileInfo = profileMap[profileMapId];
  return {
    // the substring(4) is to chop of `The ` from the beginning of the name, ex: `The Optimizer` -> `Optimizer`
    name: profileInfo.displayName.substring(4),
    id: profileInfo.id,
    templateType: 'collaboration',
    program: getKitOfPartsWithMultiplierAndAssumptions(
      profileInfo.kitOfPartsWithMultipliers[US_REGULAR],
      profileInfo.collaborationLevels[US_REGULAR][MEDIUM]
    )
  };
}

// All profiles based off Spacer Free US_Regular profiles, blank template only has basic support rows
module.exports = [

  COLLABORATION_BLANK_TEMPLATE,

  getTemplateWithoutOrder(profileNameIds.EFFICIENT),

  getTemplateWithoutOrder(profileNameIds.WHITE_COLLAR),

  getTemplateWithoutOrder(profileNameIds.WHITE_GLOVE),

  getTemplateWithoutOrder(profileNameIds.OPEN_OFFICE),

  getTemplateWithoutOrder(profileNameIds.WORKPLACE_360),

  getTemplateWithoutOrder(profileNameIds.CAMPUS),

  getTemplateWithoutOrder(profileNameIds.STARTUP),

  getTemplateWithoutOrder(profileNameIds.ENG_CREATIVE)
];
