const constants = require('wp-constants').spacerFree;

const brokerEmailTypeToSubject = {
  [constants.contact.SPACER_SUPPORT_CONTACT_TYPES.SAVE_OTH]: 'OTH - Spacer Referral: New Saved Program',
  [constants.contact.SPACER_SUPPORT_CONTACT_TYPES.SAVE_EMEA]: 'EMEA - Spacer Referral: New Saved Program',
  [constants.contact.SPACER_SUPPORT_CONTACT_TYPES.SAVE_US]: 'US - Spacer Referral: New Saved Program',
  [constants.contact.SPACER_SUPPORT_CONTACT_TYPES.SAVE_APAC]: 'APAC - Spacer Referral: New Saved Program'
};

module.exports = {
  brokerEmailTypeToSubject
};
