/* eslint-disable react-hooks/rules-of-hooks */
const {
  CIRCULATION_TYPE,
  STANDARD_CIRCULATION_MAP,
  HIGH_CIRCULATION_MAP,
  APAC_CIRCULATION_MAP,
  COUNTRIES_CIRCULATION_MAP
} = require('wp-constants').shared.spaceUnit;
const locationUtil = require('../util/location');

/*
    The Shared Program Calculator is a module that returns a set of functions for program calculations.

    Initially, the module only returns the "init" function.

    Once "init" has been called:
      - all other calculation functions become publicly available
      - the module has local access to metrics including: work seats, group seats, headcount, and blended circulation percentage
*/

// *************************************
//  PRIVATE
// *************************************

// Program-Specific Variables
const programMetrics = {
  headcount: 0,
  workSeats: 0,
  groupSeats: 0,
  officeDensity: null,
  blendedCircPercentage: null,

};

let calculatorIsInited = false;
let country_var;
const programFunctions = {
  // gets defined during init, custom functions can be passed in
  getCirculationPercentageFromCircType: () => null,
  getCirculationTypeFromProgramPart: () => null
};

// *************************************
//  PUBLIC
// *************************************
const calculateUSF = (nsf, unitCircType) => {
  const circulationPercentage = programFunctions.getCirculationPercentageFromCircType(unitCircType);
  return Math.round(nsf / (1 - circulationPercentage));
};

const calculateMeSharedFocusWeSupportProgramPartNSFAndUSF = ({ quantity, unitSF, unitCircType }) => {
  const nsf = quantity * unitSF;
  const usf = calculateUSF(nsf, unitCircType);
  return { nsf, usf };
};

const calculateMeProgramPart = ({ multiplier, unitSF, unitCircType }) => {
  const quantity = Math.round(multiplier * programMetrics.workSeats);
  const { nsf, usf } = calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({ quantity, unitSF, unitCircType });
  return { quantity, nsf, usf };
};

const calculateSharedFocusProgramPart = ({ unitSF, unitCircType, ratio, totalOpenMeSeats }) => {
  const quantity = ratio ? Math.round(totalOpenMeSeats / ratio) : 0;
  const { nsf, usf } = calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({ quantity, unitSF, unitCircType });
  return { quantity, nsf, usf };
};

const calculateWeProgramPart = ({ multiplier, unitSeats, unitSF, unitCircType, ratio, totalMeSeats }) => {
  let quantity;
  if (ratio === 0) {
    quantity = 0;
  } else if (ratio > 0) { // this should be used in spacer pro we calculations
    quantity = Math.round(totalMeSeats / ratio);
  } else { // this should be used in spacer free we calculations
    quantity = Math.round((multiplier * programMetrics.groupSeats) / unitSeats);
  }
  const { nsf, usf } = calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({ quantity, unitSF, unitCircType });
  return { quantity, nsf, usf };
};

const calculateSupportProgramPart = ({ multiplier, unitSF, unitCircType, isFloorDependent, floors }) => {
  let quantity;
  if (isFloorDependent) {
    quantity = Math.round((floors || 0) * multiplier); // we round here after multiplying not before - this causes display discrepancies
  } else {
    const calculatedQuantity = Math.round((programMetrics.headcount * multiplier) / unitSF);
    quantity = Math.max(calculatedQuantity, 1);
  }
  const { nsf, usf } = calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({ quantity, unitSF, unitCircType });
  return { quantity, nsf, usf };
};

const calculateAmenityProgramPart = ({ multiplier, unitSF, unitCircType, unitMinSF, unitMaxSF }) => {
  const quantity = 1;
  let nsf = multiplier ? Math.round(programMetrics.headcount * multiplier) : Math.round(unitSF);
  if (unitMinSF && nsf < unitMinSF) {
    nsf = unitMinSF;
  } else if (unitMaxSF && nsf > unitMaxSF) {
    nsf = unitMaxSF;
  }
  const usf = calculateUSF(nsf, unitCircType);
  return { quantity, usf, nsf };
};

const updateProgramMetrics = ({ headcount, workSeats, groupSeats, blendedCircPercentage }) => {
  // check if the value was passed with typeof instead of !! (in case the value is being set to zero)
  if (typeof headcount !== 'undefined') programMetrics.headcount = headcount;
  if (typeof workSeats !== 'undefined') programMetrics.workSeats = workSeats;
  if (typeof groupSeats !== 'undefined') programMetrics.groupSeats = groupSeats;
  if (typeof blendedCircPercentage !== 'undefined') programMetrics.blendedCircPercentage = blendedCircPercentage;
};

const getProgramMetrics = () => programMetrics;

const calculateCategoryUSFTotals = ({ meSpaces, weSpaces, amenitySpaces }) => {
  // Suggested or calculated
  let totalMeSF = 0;
  let totalWeSF = 0;
  let totalAmenitySF = 0;

  // User adjusted
  let totalCustomMeSF = 0;
  let totalCustomWeSF = 0;
  let totalCustomAmenitySF = 0;

  meSpaces.forEach((space) => {
    totalMeSF += space.usf;
    totalCustomMeSF += space.customUSF || space.usf;
  });

  weSpaces.forEach((space) => {
    totalWeSF += space.usf;
    totalCustomWeSF += space.customUSF || space.usf;
  });

  amenitySpaces.forEach((space) => {
    totalAmenitySF += space.usf;
    totalCustomAmenitySF += space.customUSF || space.usf;
  });

  const totalSF = totalMeSF + totalWeSF + totalAmenitySF;
  const totalCustomSF = totalCustomMeSF + totalCustomWeSF + totalCustomAmenitySF;

  const areaTotals = {
    totalMeSF,
    totalWeSF,
    totalAmenitySF,
    totalSF
  };

  if (totalMeSF !== totalCustomMeSF) {
    areaTotals.totalCustomMeSF = totalCustomMeSF;
  }

  if (totalWeSF !== totalCustomWeSF) {
    areaTotals.totalCustomWeSF = totalCustomWeSF;
  }

  if (totalAmenitySF !== totalCustomAmenitySF) {
    areaTotals.totalCustomAmenitySF = totalCustomAmenitySF;
  }

  if (totalSF !== totalCustomSF) {
    areaTotals.totalCustomSF = totalCustomSF;
  }

  return areaTotals;
};

const getCirculationPercentageFromCircTypeWithStandardCirculationMap = (circType) => {
  let standard_circulation_map;
  const circulation_map = COUNTRIES_CIRCULATION_MAP[country_var];
  if (circulation_map) {
    standard_circulation_map = circulation_map['STANDARD_CIRCULATION_MAP'];
  } else {
    standard_circulation_map = STANDARD_CIRCULATION_MAP;
  }
  if (circType === CIRCULATION_TYPE.BLENDED) return programMetrics.blendedCircPercentage;
  return standard_circulation_map[circType];
};

const getCirculationPercentageFromCircTypeWithHighCirculationMap = (circType) => {
  let high_circulation_map;
  const circulation_map = COUNTRIES_CIRCULATION_MAP[country_var];
  if (circulation_map) {
    high_circulation_map = circulation_map['HIGH_CIRCULATION_MAP'];
  } else {
    high_circulation_map = HIGH_CIRCULATION_MAP;
  }
  if (circType === CIRCULATION_TYPE.BLENDED) return programMetrics.blendedCircPercentage;
  return high_circulation_map[circType];
};

// with custom circulation maps, treat blended circulation spaces as if they had amenity circulation
const useCustomCirculationMap = (customCirculationMap) => {
  programFunctions.getCirculationPercentageFromCircType = (circType) => {
    if (circType === CIRCULATION_TYPE.BLENDED) return customCirculationMap[CIRCULATION_TYPE.AMENITY];
    return customCirculationMap[circType];
  };
};

const isCalculatorInited = () => calculatorIsInited;

const postInitPublicCalcFunctions = {
  // Program Part
  calculateMeProgramPart,
  calculateSharedFocusProgramPart,
  calculateWeProgramPart,
  calculateAmenityProgramPart,
  calculateSupportProgramPart,

  // Program Part NSF and USF
  calculateUSF,
  calculateMeSharedFocusWeSupportProgramPartNSFAndUSF,

  // Util
  updateProgramMetrics,
  useCustomCirculationMap,
  getProgramMetrics,
  calculateCategoryUSFTotals
};

// customCirculationMap, if it exists, is expected to follow layout of STANDARD_CIRCULATION_MAP and define values for open, enclosed and amenity circulation
const publicCalcFunctions = {};
publicCalcFunctions.init = ({ workSeats = 0, groupSeats = 0, headcount = 0, officeDensity, officeLocation = 'USA', getCirculationTypeFromProgramPart, customCirculationMap }) => {
  calculatorIsInited = true;
  // Define program-specific variables and functions
  programMetrics.headcount = headcount;
  programMetrics.workSeats = workSeats;
  programMetrics.groupSeats = groupSeats;
  programMetrics.officeDensity = officeDensity;


  programFunctions.getCirculationTypeFromProgramPart = getCirculationTypeFromProgramPart || (programPart => programPart.circulationType);

  // APAC spaces have special APAC_CIRCULATION (unless customCirculationMap is specifically passed in)
  const { country } = locationUtil.getCityStateCountry(officeLocation);
  country_var=country;
  const isAPAC = locationUtil.isAPAC(country);
  if (isAPAC) customCirculationMap = customCirculationMap || APAC_CIRCULATION_MAP;
  
  if (customCirculationMap) {
    useCustomCirculationMap(customCirculationMap);
  } else {
    programFunctions.getCirculationPercentageFromCircType = programMetrics.officeDensity === 2 ? getCirculationPercentageFromCircTypeWithHighCirculationMap : getCirculationPercentageFromCircTypeWithStandardCirculationMap;

  }

  // Add all other functions to public calc object
  Object.assign(publicCalcFunctions, postInitPublicCalcFunctions);
};
publicCalcFunctions.isCalculatorInited = isCalculatorInited;

module.exports = publicCalcFunctions;
