const _ = require('lodash');

const profileNameTokensAndValues = {
  EFFICIENT: { token: 'efficientName', value: 'The Optimizer', id: 'efficient' },
  WHITE_COLLAR: { token: 'whiteCollarName', value: 'The Accommodator', id: 'whiteCollar' },
  WHITE_GLOVE: { token: 'whiteGloveName', value: 'The Enhancer', id: 'whiteGlove' },
  OPEN_OFFICE: { token: 'openOfficeName', value: 'The Producer', id: 'openOffice' },
  WORKPLACE_360: { token: 'workplace360Name', value: 'The Connector', id: 'workplace360' },
  CAMPUS: { token: 'campusName', value: 'The Differentiator', id: 'campus' },
  STARTUP: { token: 'startupName', value: 'The Incubator', id: 'startup' },
  ENG_CREATIVE: { token: 'engCreativeName', value: 'The Creator', id: 'engCreative' }
};

// generic helper function
function mapNameTo(element) {
  const nameElementAssoc = {};

  _.forEach(profileNameTokensAndValues, (profileNameObj, profileName) => {
    nameElementAssoc[profileName] = profileNameObj[element];
  });

  return nameElementAssoc;
}

// returns { EFFICIENT: { 'efficientName' }, ... }
const profileNameTokens = mapNameTo('token');

// returns { EFFICIENT: { 'The Optimizer' }, ... }
const profileNameValues = mapNameTo('value');

// returns { EFFICIENT: { 'efficient' }, ... }
const profileNameIds = mapNameTo('id');

module.exports = {
  profileNameTokens,
  profileNameValues,
  profileNameIds
};
